import React, { useEffect, useRef, useState } from 'react';
let session = window.sessionStorage;








function dateChanger(e) {
	e.preventDefault();
	let $startDate = document.getElementById('startDate');
	let $endDate = document.getElementById('endDate');
	const dates = document.getElementById('dates_left')
	const year_left = document.getElementById('year_left')
	const month_left = document.getElementById('month_left')



	/*달력 바꿔도 시작일,종료일 유지하기*/
	if (session.getItem('startDate')) {
		if (session.getItem('startDate').split('-')[0] === year_left.innerHTML && session.getItem('startDate').split('-')[1] === month_left.innerHTML) {
			for (let i = 0; i < dates.children.length; i++) {
				if (dates.children[i].children[0].children[0] && dates.children[i].children[0].children[0].innerHTML === session.getItem('startDate').split('-')[2]) {
					dates.children[i].children[0].classList.add('selected')
					dates.children[i].children[0].classList.add('thisday_start')
				}


			}
		}
	}
	if (session.getItem('endDate')) {
		if (session.getItem('endDate').split('-')[0] === year_left.innerHTML && session.getItem('endDate').split('-')[1] === month_left.innerHTML) {
			for (let i = 0; i < dates.children.length; i++) {
				if (dates.children[i].children[0].children[0] && dates.children[i].children[0].children[0].innerHTML === session.getItem('endDate').split('-')[2]) {
					dates.children[i].children[0].classList.add('selected')
					dates.children[i].children[0].classList.add('thisday_end')
				}
			}
		}
	}

}



const nothing = (e) => {
    console.log(e)
}



const Calendar = ({thisCalendar,setThisCalendar,openCalendar,setOpenCalendar,month,year,ii,setii,todayClassAdd}) => {
	const dateChanger = (e) => {
		e.preventDefault();
		if(e.target.id === 'goLeft'){
			if(ii === 0){
				alert('더이상 과거로 갈 수 없습니다')
			} else {
				setii(ii-1)
			}
		} else if(e.target.id === 'goRight'){
			if(ii === 11){
				alert('현재로부터 1년 뒤까지만 예약이 가능합니다')
			}else {
				setii(ii+1)
			}
		}

	}
	const yearRef = useRef()
	const monthRef = useRef()
	const dates_left = useRef();
	

	const openClose = () => [
		setOpenCalendar(!openCalendar)
	]



	const datePicker = (e) => {
		e.preventDefault();
		let today_origin = new Date();
		const monthNames = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
		let $day = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
		/*todayKor = 한국 표준시*/
		let today_hour_origin = today_origin.getTime() + (today_origin.getTimezoneOffset() * 60 * 1000) + (9 * 60 * 60 * 1000);
		let todayKor_origin = new Date(today_hour_origin);
		console.log(todayKor_origin);
		let today_year_origin = Number(todayKor_origin.toString().split(' ')[3]); /*연*/
		let today_month_origin = monthNames.indexOf(todayKor_origin.toString().split(' ')[1]); /*월*/
		let today_date_origin = Number(todayKor_origin.toString().split(' ')[2]); /*일*/
	
		let $startDate = document.getElementById('startDate');
		let $endDate = document.getElementById('endDate');
		const dates = document.getElementById('dates_left')
		const year_left = document.getElementById('year_left')
		const month_left = document.getElementById('month_left')
		let selected_day = year_left.innerHTML + '-' + month_left.innerHTML + '-' + e.target.innerHTML;
		console.log(selected_day)
		if (new Date(year_left.innerHTML + '-' + month_left.innerHTML + '-' + e.target.innerHTML) < new Date(today_year_origin + '-' + today_month_origin+ '-' + today_date_origin)) {
			return alert('오늘 이전의 날짜를 선택할 수 없습니다');
		}
	
		if (!session.getItem('startDate') && !session.getItem('endDate')) {
			/*시작일, 종료일 아무것도 정하지 않은 경우*/
			session.setItem('startDate', year_left.innerHTML + '-' + month_left.innerHTML + '-' + e.target.innerHTML);
			if (e.target.tagName === 'SPAN') {
				e.target.parentElement.classList.add('selected');
				e.target.parentElement.classList.add('thisday_start');
			} else {
				e.target.classList.add('selected');
				e.target.classList.add('thisday_start');
			}

			for (let i = 0; i < dates.children.length; i++) {
				if (dates.children[i].children[0].id === 'thisday_start') dates.children[i].children[0].id = '';
			}
			for (let i = 0; i < dates.children.length; i++) {
				if (dates.children[i].children[0].id === 'thisday_end') dates.children[i].children[0].id = '';
			}

			$startDate.children[0].innerHTML = session.getItem('startDate').split('-')[0] + '년';
			$startDate.children[2].innerHTML = session.getItem('startDate').split('-')[1] + '월' + session.getItem('startDate').split('-')[2] + '일';
			document.reserve2.start_date_input.value = session.getItem('startDate');
			console.log(document.reserve2.start_date_input.value)
			if (document.reserve2.start_date_input.value.split('-')[1].length === 1) {
				document.reserve2.start_date_input.value = document.reserve2.start_date_input.value.replace('-'+document.reserve2.start_date_input.value.split('-')[1]+'-', '-0' + document.reserve2.start_date_input.value.split('-')[1]+'-')
			}
			if (document.reserve2.start_date_input.value.split('-')[2].length === 1) {
				document.reserve2.start_date_input.value = document.reserve2.start_date_input.value.replace('-'+document.reserve2.start_date_input.value.split('-')[2], '-0' + document.reserve2.start_date_input.value.split('-')[2])
			}
		} else if (session.getItem('startDate') && !session.getItem('endDate')) {
			/*시작일은 있고 종료일은 없는 경우*/
			let s1 = new Date(session.getItem('startDate').split('-')[0], Number(session.getItem('startDate').split('-')[1]) - 1, session.getItem('startDate').split('-')[2])
			let s2 = new Date(year_left.innerHTML, Number(month_left.innerHTML) - 1, e.target.innerHTML)
	
			if (s1 < s2) {
				/*종료일을 눌렀는데, 정상적으로 종료일이 시작일 이후일 때*/
				session.setItem('endDate', year_left.innerHTML + '-' + month_left.innerHTML + '-' + e.target.innerHTML);
				if (e.target.tagName === 'SPAN') {
					e.target.parentElement.classList.add('selected')
					e.target.parentElement.classList.add('thisday_end');
				} else {
					e.target.classList.add('selected');
					e.target.classList.add('thisday_end');
				}
	
				$endDate.children[0].innerHTML = session.getItem('endDate').split('-')[0] + '년';
				$endDate.children[2].innerHTML = session.getItem('endDate').split('-')[1] + '월' + session.getItem('endDate').split('-')[2] + '일';
				document.reserve2.end_date_input.value = session.getItem('endDate');
				if (document.reserve2.end_date_input.value.split('-')[1].length === 1) {
					console.log('fucking2')
					document.reserve2.end_date_input.value = document.reserve2.end_date_input.value.replace('-'+document.reserve2.end_date_input.value.split('-')[1]+'-', '-0' + document.reserve2.end_date_input.value.split('-')[1]+'-');
				}
				if (document.reserve2.end_date_input.value.split('-')[2].length === 1) {
					document.reserve2.end_date_input.value = document.reserve2.end_date_input.value.replace('-'+document.reserve2.end_date_input.value.split('-')[2], '-0' + document.reserve2.end_date_input.value.split('-')[2])
					console.log(document.reserve2.end_date_input.value)
				}
				setOpenCalendar(!openCalendar)
	
	
			} else {
	
				/*종료일을 눌렀는데, 시작일보다 전이거나 시작일과 겹칠 때*/
				/* selected 클래스 모두 지우기*/
				for (let i = 0; i < dates.children.length; i++) {
					if (Array.from(dates.children[i].children[0].classList).indexOf('selected') !== -1) dates.children[i].children[0].classList.remove('selected')
				}
				/* thisday_start 클래스 모두 지우기*/
				for (let i = 0; i < dates.children.length; i++) {
					if (Array.from(dates.children[i].children[0].classList).indexOf('thisday_start') !== -1) dates.children[i].children[0].classList.remove('thisday_start')
				}
				document.reserve2.start_date_input.value = '';
				session.setItem('startDate', year_left.innerHTML + '-' + month_left.innerHTML + '-' + e.target.innerHTML);
				if (e.target.tagName === 'SPAN') {
					e.target.parentElement.classList.add('selected');
					e.target.parentElement.classList.add('thisday_start');
				} else {
					e.target.classList.add('selected');
					e.target.classList.add('thisday_start');
				}
				$startDate.children[0].innerHTML = session.getItem('startDate').split('-')[0] + '년';
				$startDate.children[2].innerHTML = session.getItem('startDate').split('-')[1] + '월' + session.getItem('startDate').split('-')[2] + '일';
				document.reserve2.start_date_input.value = session.getItem('startDate');
				if (document.reserve2.start_date_input.value.split('-')[1].length === 1) {
					document.reserve2.start_date_input.value = document.reserve2.start_date_input.value.replace('-'+document.reserve2.start_date_input.value.split('-')[1]+'-', '-0' + document.reserve2.start_date_input.value.split('-')[1]+'-')
				}
				if (document.reserve2.start_date_input.value.split('-')[2].length === 1) {
					document.reserve2.start_date_input.value = document.reserve2.start_date_input.value.replace('-'+document.reserve2.start_date_input.value.split('-')[2], '-0' + document.reserve2.start_date_input.value.split('-')[2])
				}
				session.removeItem('endDate');
				$endDate.children[0].innerHTML = '';
				$endDate.children[2].innerHTML = '';
				alert('체크아웃 날짜는 체크인 날짜 이후로 선택해주세요');
				if (document.getElementById('startDate').children[2].children[0]) document.getElementById('startDate').children[2].children[0].remove();
			}
		} else if (session.getItem('startDate') && session.getItem('endDate')) {
			/*시작일과 종료일 둘 다 선택한 상황*/
	
			/* selected 클래스 모두 지우기*/
			for (let i = 0; i < dates.children.length; i++) {
				if (Array.from(dates.children[i].children[0].classList).indexOf('selected') !== -1) dates.children[i].children[0].classList.remove('selected')
			}
			/* thisday_start 클래스 모두 지우기*/
			for (let i = 0; i < dates.children.length; i++) {
				if (Array.from(dates.children[i].children[0].classList).indexOf('thisday_start') !== -1) dates.children[i].children[0].classList.remove('thisday_start')
			}
			for (let i = 0; i < dates.children.length; i++) {
				if (Array.from(dates.children[i].children[0].classList).indexOf('thisday_end') !== -1) dates.children[i].children[0].classList.remove('thisday_end')
			}
			for (let i = 0; i < dates.children.length; i++) {
				if (dates.children[i].children[0].id === 'thisday_start') dates.children[i].children[0].id = '';
			}
			for (let i = 0; i < dates.children.length; i++) {
				if (dates.children[i].children[0].id === 'thisday_end') dates.children[i].children[0].id = '';
			}
			session.removeItem('startDate')
			session.removeItem('endDate')
			document.reserve2.start_date_input.value = '';
			document.reserve2.end_date_input.value = '';
			session.setItem('startDate', year_left.innerHTML + '-' + month_left.innerHTML + '-' + e.target.innerHTML);
			if (e.target.tagName === 'SPAN') {
				e.target.parentElement.classList.add('selected');
				e.target.parentElement.classList.add('thisday_start');
			} else {
				e.target.classList.add('selected');
				e.target.classList.add('thisday_start');
			}
			document.reserve2.start_date_input.value = session.getItem('startDate');
			if (document.reserve2.start_date_input.value.split('-')[1].length === 1) {
				document.reserve2.start_date_input.value = document.reserve2.start_date_input.value.replace('-'+document.reserve2.start_date_input.value.split('-')[1]+'-', '-0' + document.reserve2.start_date_input.value.split('-')[1]+'-')
			}
			if (document.reserve2.start_date_input.value.split('-')[2].length === 1) {
				document.reserve2.start_date_input.value = document.reserve2.start_date_input.value.replace('-'+document.reserve2.start_date_input.value.split('-')[2], '-0' + document.reserve2.start_date_input.value.split('-')[2])
			}
			$startDate.children[0].innerHTML = session.getItem('startDate').split('-')[0] + '년';
			$startDate.children[2].innerHTML = session.getItem('startDate').split('-')[1] + '월' + session.getItem('startDate').split('-')[2] + '일';
			if (document.getElementById('startDate').children[2].children[0]) document.getElementById('startDate').children[2].children[0].remove();
			$endDate.children[0].innerHTML = '';
			$endDate.children[2].innerHTML = '';
		}
		console.log(session.getItem('startDate'))
		console.log(session.getItem('endDate'))
	
	
	}

	useEffect(()=>
		{
			// if(dates_left.current.children.length > 20){
			// 	for (let i = 0; i < dates_left.current.children.length; i++) {
			// 		if (dates_left.current.children[i].children[0].id === 'thisday_start') dates_left.current.children[i].children[0].id = '';
			// 	}
			// 	for (let i = 0; i < dates_left.current.children.length; i++) {
			// 		if (dates_left.current.children[i].children[0].id === 'thisday_end') dates_left.current.children[i].children[0].id = '';
			// 	}
			// }
		}
	,[])

    return (
        <div className="calender" id="calender">

			<div className="calender_text">
				<h3>날짜를 선택하세요</h3>
			</div>
			<div>
				<div className="date_before date">
					<div className="date_select">
						<div className="date_left"><a href="#none" onClick={dateChanger} id="goLeft">&#8249;</a></div>
						<div className="date_wrap">
							<div className="date_year">
								<h4><a href="#none" onClick={(e) => nothing(e)} id="year_left" ref={yearRef} className="year_left">{year}</a></h4>
							</div>
							<div className="date_month">
								<h3><a href="#none" onClick={(e) => nothing(e)} id="month_left" ref={monthRef} className="month_left">{month}</a></h3>
							</div>
						</div>
						<div className="date_right"><a href="#none" onClick={dateChanger} id="goRight">&#8250;</a></div>
					</div>
					<div className="date_calender">
						<ul>
							<li style={{color:'red'}}>Sun</li>
							<li>Mon</li>
							<li>Tue</li>
							<li>Wed</li>
							<li>Thu</li>
							<li>Fri</li>
							<li style={{color:'blue'}}>Sat</li>
						</ul>
						<ul className="dates" id="dates_left" ref={dates_left}>
							{
							thisCalendar.map((x,i) =>
								<li key={i}><a href="#none" id={window.sessionStorage.getItem('startDate') === year+'-'+month+'-'+x ? 'thisday_start' :( window.sessionStorage.getItem('endDate') === year+'-'+month+'-'+x ? 'thisday_end' : null)} className={ii === 0 && x === Number(window.sessionStorage.getItem('today_date_origin')) ? 'today' : null } onClick={ x === 0 ? nothing : datePicker}><span>{ x === 0 ? false : x}</span>{ x === 0 ? false : x}</a></li>
								)
							}
						</ul>

					</div>

				</div>
			</div>
			<div className="xBtn" onClick={openClose}>X</div>
		</div>
    );
};
export default Calendar;