import React, { useEffect, useRef, useState } from 'react';
import {onConfirmReserve,myReserveConfirm} from '../../assets/php/reserve'
import {onLogin} from '../../assets/php/login'
import { useSelector } from 'react-redux';
import axios from 'axios';
import { home,reserve,room1,room2,room3,onsen,kaiseki,intro,event,inquire,business1,business2,login,join,confirm,mypage,search, restaurant,login2 } from '../../store/module/nav';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link
  } from "react-router-dom";

const Login2 = ({page,setPage,dispatch}) => {
	const [reserveLoadOn,setReserveLoadOn] = useState(false);
	useEffect(() => {
		console.log(page)

		/**내 예약 load */
		
		axios
		  .post("/assets/php/my_reservation_loader.php")
		  .then((res) => {
			console.log(res.data);
			window.sessionStorage.setItem('myReserve',JSON.stringify(res.data))
			if (typeof res.data === "object") {
				setReserveLoadOn(true)
			} else if (
			  typeof res.data === "string" &&
			  res.data.split("|||")[0] !== "code1"
			) {
			  return;
			} else {
			  return;
			}
		  })
		  .catch((error) => {
			alert("에러발생, 관리자에게 문의해주세요");
			console.log(error);
			throw new Error(error);
		  });


	  return () => {
		return
	  };
	}, [page]);

	const OnPageMove = (e) => {
		setPage(e)
	}
	const onActive = (e) => {
		e.target.parentElement.classList.add('active')
	}
	const offActive = (e) => {
		e.target.parentElement.classList.remove('active')
	}
	
	return (
		<div id="login" className="roomContent login nav_eraser">
		<header className="header2 nav_eraser">
			<div>
			</div>
		</header>
		<div className="content_area">
			<aside className="roomMenu">
				<div>
					<div>
						<h2>고객서비스</h2>
					</div>
					<div>
						<h3 onMouseOver={onActive} onMouseLeave={offActive}><Link to="/login" className="onLogin depth2" onClick={(e) => {dispatch(login());OnPageMove(e.target.classList[0])}}>로그인</Link></h3>
						<h3 onMouseOver={onActive} onMouseLeave={offActive}><Link to="/join" className="onJoin depth2" onClick={(e) => {dispatch(join());OnPageMove(e.target.classList[0])}}>회원가입</Link></h3>
						<h3 onMouseOver={onActive} onMouseLeave={offActive}><Link to="/login:login2" className="onLogin2 depth2" onClick={(e) => {dispatch(login2());OnPageMove(e.target.classList[0])}}>예약확인</Link></h3>
					</div>
				</div>
			</aside>
			<div>

				<h1 className="title1">예약 확인</h1>
				
				<div className="height40"></div>

				<div className="sec1text title">
					<h2 className="title2" style={{fontFamily:'바탕',fontWeight:100}}>예약번호로 예약을 확인하세요</h2>
					<p>로그인 하시면 예약번호가 보입니다.</p>
					<ul id="reservations" >
						{

							reserveLoadOn ?

							JSON.parse(window.sessionStorage.getItem('myReserve')).map((x,i) =>
							<li key={i}><a href="#none" onClick={(e) => myReserveConfirm(x.reserve_code,x.reserve_mail,e)}>
								  <span id={'my_r_'+x.reserve_code}>예약번호: {x.reserve_code}</span>
								  <span id={'my_r_checkin_'+x.reserve_start}>체크인 날짜: {x.reserve_start}</span>
								  <span id={'my_r_checkout_'+x.reserve_end}>체크아웃 날짜: {x.reserve_end}</span>
								  <span id={'my_r_total_'+x.reserve_howmany}>총인원: {x.reserve_howmany}</span></a>
							  </li>
							)

							:
						<li><a href="#none">
							<span id="">예약번호: &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>
							<span id="my_r_checkin">체크인 날짜: &emsp;&emsp;&emsp;</span>
							<span id="my_r_checkout">체크아웃 날짜: &emsp;&emsp;&emsp;</span>
							<span id="my_r_total">총인원: &emsp;</span></a>
						</li>
						}
						
					</ul>
				</div>

				
				
				
				<form className="login_form" name="login_form" onSubmit={onConfirmReserve} method="post">
					<div>

						{/* <div className="member">
							<label htmlFor="member1"><input type="radio" name="form_member" id="member1" onClick={() => {window.location.href = '/login'}} />로그인</label>
							<label htmlFor="member2" style={{marginLeft:10}}><input type="radio" name="form_member" id="member2" defaultChecked />예약확인</label>
						</div> */}

						<div className="id_pass ">
							<input type="text" name="form_id2" placeholder="예약번호 입력" />
							<input type="password" name="form_pass2" placeholder="이메일 입력" />
						</div>
						<div className="join_find">
							<button type="button" onClick={()=> {window.location.href = '/join'}}>회원가입</button>
							<button type="button" style={{marginLeft:10}} onClick={()=> {window.location.href = '/find'}}>아이디/비밀번호 찾기</button>
						</div>
					</div>
					<div>

					<button type="submit" className="" onClick={onConfirmReserve}>예약확인</button>

					</div>
				</form>
				<div className="alert">
					<p>이메일 연락처 등의 정보가 변경되면 웹사이트에서 회원정보를 수정해주시기 바랍니다.</p>
				</div>
			</div>
		</div>
	</div>
	);
};

export default Login2;