import React from 'react';
import { home,reserve,room1,room2,room3,onsen,kaiseki,intro,event,inquire,business1,business2,login,join,confirm,mypage,search, restaurant } from '../../store/module/nav';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link
  } from "react-router-dom";

const Intro = ({setPage,dispatch}) => {

	const OnPageMove = (e) => {
		setPage(e)
	}
	const onActive = (e) => {
		e.target.parentElement.classList.add('active')
	}
	const offActive = (e) => {
		e.target.parentElement.classList.remove('active')
	}
	return (
		<div id="introduce" className="roomContent food">
		<header className="header2 nav_eraser">
			<div>
			</div>
		</header>
		<div className="content_area">
			<aside className="roomMenu">
				<div>
					<div>
						<h2>고객서비스</h2>
					</div>
					<div>
						<h3 onMouseOver={onActive} onMouseLeave={offActive}><Link to="/intro" className="onIntro depth2" onClick={(e) => {dispatch(intro());OnPageMove(e.target.classList[0])}}>료칸소개</Link></h3>
						<h3 onMouseOver={onActive} onMouseLeave={offActive}><Link to="/event" className="onEvent depth2" onClick={(e) => {dispatch(event());OnPageMove(e.target.classList[0])}}>이벤트</Link></h3>
						<h3 onMouseOver={onActive} onMouseLeave={offActive}><Link to="/inquire" className="onInquiry depth2" onClick={(e) => {dispatch(inquire());OnPageMove(e.target.classList[0])}}>문의하기</Link></h3>
					</div>
				</div>
			</aside>
			<div>
				<h1><img src={require("../../assets/img/cs/intro.png")} alt="제목이미지" className="subject_image" />
				</h1>
				<section className="section1">
					<div>
						<div className="sec1pic">
							<img src={require("../../assets/img/cs/ho2.jpg")} />
						</div>
						<div className="sec1text">
							<h2> 하코네 유모토의 보물</h2>
							<p>호에이소는 하코네의 온천지대에 위치한 자연과 함께하는 료칸입니다.<br />우리 호에이소는, 고객들에게 자연의 은혜와 진심 어린 미소, 지극히 일본스러운 환대를 제공하여 마음에 스며 들도록 행복감을 나누고 있습니다 .</p>
						</div>
					</div>
				</section>

				<section className="section1">
					<div>
						<div className="sec1pic">
							<img src={require("../../assets/img/cs/onsen.jpg")} />
						</div>
						<div className="sec1text">
							<h2> 미네랄이 풍부한 하코네유모토의 온천수</h2>
							<p>호에이소는 하코네의 온천지대에 위치한 자연과 함께하는 료칸입니다.<br />우리 호에이소는, 고객들에게 자연의 은혜와 진심 어린 미소, 지극히 일본스러운 환대를 제공하여 마음에 스며 들도록 행복감을 나누고 있습니다.</p>
						</div>
					</div>
				</section>
				<section className="section_info">
					<div>
						<div className="section_info_tit">
							<img src={require("../../assets/img/cs/intro.png")} />
						</div>
						<div className="wrapper">
							<div className="info_pic onlySP">
								<img src={require("../../assets/img/cs/int2.jpg")} />
							</div>
							<div className="info">
								<div>
									<h2>Information </h2>
								</div>
								<div>
									<div className="info_h3">
										<h3>Check In</h3>
										<h3>Check Out</h3>
										<h3>Room style</h3>
										<h3><br />Dining</h3>
										<h3><br /> How many
											<br />Rooms
										</h3>
										<h3>Ouside Onsen</h3>
										<h3><br />Lang support</h3>
									</div>
									<div className="info_p">
										<h3>15:00</h3>
										<h3>13:00</h3>
										<h3>Japanese /
											<br />Western
										</h3>
										<h3>Restraunt /
											<br />Kaiseki
										</h3>
										<h3>82 rooms</h3>
										<h3><br />Available
											<br />on 15:00~02:00
										</h3>
										<h3>english / 日本語 / 한국어</h3>
									</div>
								</div>
							</div>
							<div className="info_pic onlyPC">
								<img src={require("../../assets/img/cs/int2.jpg")} />
							</div>
						</div>
					</div>
				</section>

				<section className="section_photo_wrap">
					<div>
						<div className="section_photo_img">
							<div>
								<div>
									<img src={require("../../assets/img/cs/staff.jpg")} />
								</div>
								<div>
									<img src={require("../../assets/img/cs/dine3_3.jpg")} />
								</div>
							</div>
							<div>
								<div>
									<img src={require("../../assets/img/cs/dine2.jpg")} />
								</div>
								<div>
									<img src={require("../../assets/img/cs/ho2.jpg")} />
								</div>
								<div>
									<img src={require("../../assets/img/cs/dine9.jpg")} />
								</div>
							</div>
						</div>
						<div>
							<h2> 최고의 서비스로 보답하겠습니다</h2>
							<p> 호에이소는 하코네의 온천지대에 위치한 자연과 함께하는 료칸입니다.<br />우리 호에이소는, 고객들에게 자연의 은혜와 진심 어린 미소, 지극히 일본스러운 환대를 제공하여 마음에 스며 들도록 행복감을 나누고 있습니다 </p>
						</div>
					</div>
				</section>
				<section className="section_address">
					<div>
						<div>
							<h4><span>주소</span>: 神奈川県足柄下郡箱根町湯本茶屋227 <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(227 Yumotochaya
								Hakone, Ashigarashimo-gun, Kanagawa 250-0312)</h4>
						</div>
						<div>
							<h4><br /><span>전화번호</span>: 0460-85-5763</h4>
						</div>
					</div>
				</section>
			</div>
		</div>
	</div>
	);
};

export default Intro;