import React, { useCallback, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {home,reserve,room1,room2,room3,onsen,intro,event,inquire,business1,business2,login,join,confirm,mypage,search,login2} from "./store/module/nav";

/*Pages*/
import Main from "./components/Main";
import Nav from "./components/include/Nav";
import Footer from "./components/include/Footer";
import Reserve from "./components/pages/Reserve";
import Restaurant from "./components/pages/Restaurant";
import Kaiseki from "./components/pages/Kaiseki";
import Spa from "./components/pages/Spa";
import Room1 from "./components/pages/Room1";
import Room2 from "./components/pages/Room2";
import Room3 from "./components/pages/Room3";
import Business1 from "./components/pages/Business1";
import Business2 from "./components/pages/Business2";

import Event from "./components/pages/Event";
import EventForm from "./components/pages/EventForm";
import Intro from './components/pages/Intro';
import Inquire from './components/pages/Inquire';

import Login from './components/pages/Login';
import Login2 from "./components/pages/Login2";
import Join from './components/pages/Join';
import Confirm from './components/pages/Confirm';
import Payment from './components/pages/Payment';
import Find from './components/pages/Find';
import Search from "./components/pages/Search";
import Mypage from "./components/pages/Mypage";
import ChangePw from "./components/pages/ChangePw";


import NotFiles from "./components/pages/NotFiles";

/*css*/
import "./assets/css/reset.min.css";
// import "./assets/css/main.scss";
import "./assets/css/main.css";




/*js*/
// import "./assets/js/nav";

// import "./assets/js/calender";
// import "./assets/js/room";
// import "./assets/js/event";

/*php*/
// import "./assets/php/login";
// import "./assets/php/join";
// import "./assets/php/contact";
// import "./assets/php/search_room";

// import "./assets/php/edit_info";



function App() {
const dispatch = useDispatch();
const [page,setPage] = useState(useSelector(state => state.nav.link));
const [setLogin, onSetLogin] = useState(true)
const [myReserve, setMyReserve] = useState([]);


  return (
    <>
      <Router>
        <Nav dispatch={dispatch} page={page} setPage={setPage} onSetLogin={onSetLogin} />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/reserve" element={<Reserve />} />
          <Route path="/search" element={<Search />} />
          <Route path="/onsen" element={<Spa page={page} setPage={setPage} dispatch={dispatch} />} /> 
          <Route path="/room1" element={<Room1 page={page} setPage={setPage} dispatch={dispatch} />} /> 
          <Route path="/room2" element={<Room2 page={page} setPage={setPage} dispatch={dispatch} />} /> 
          <Route path="/room3" element={<Room3 page={page} setPage={setPage} dispatch={dispatch} />} /> 
          <Route path="/restaurant" element={<Restaurant setPage={setPage} dispatch={dispatch} />} /> 
          <Route path="/kaiseki" element={<Kaiseki setPage={setPage} dispatch={dispatch} />} /> 
          <Route path="/business1" element={<Business1 setPage={setPage} dispatch={dispatch} />} /> 
          <Route path="/business2" element={<Business2 setPage={setPage} dispatch={dispatch} />} /> 

          <Route path="/intro" element={<Intro setPage={setPage} dispatch={dispatch} />} /> 
          <Route path="/event" element={<Event setPage={setPage} dispatch={dispatch} />} /> 
          <Route path="/eventform" element={<EventForm setPage={setPage} dispatch={dispatch} />} /> 
          <Route path="/inquire" element={<Inquire setPage={setPage} dispatch={dispatch} />} /> 

          <Route path="/login" element={<Login page={page} setPage={setPage} dispatch={dispatch} /> } />
          <Route path="/login:login2" element={<Login2 page={page} setPage={setPage} onSetLogin={onSetLogin} dispatch={dispatch} />} />
          <Route path="/join" element={<Join setPage={setPage} onSetLogin={onSetLogin} dispatch={dispatch} />} />
          <Route path="/confirm" element={<Confirm page={page} setPage={setPage} dispatch={dispatch} /> } />
          <Route path="/payment" element={<Payment page={page} setPage={setPage} dispatch={dispatch} /> } />
          <Route path="/mypage" element={<Mypage page={page} setPage={setPage} dispatch={dispatch} /> } />
          <Route path="/find" element={<Find page={page} setPage={setPage} dispatch={dispatch} /> } />
          <Route path="/changepw" element={<ChangePw page={page} setPage={setPage} dispatch={dispatch} /> } />

          <Route path="*" element={<NotFiles />} />
        </Routes>
      </Router>
      <Footer />
    </>
  );
}

export default App;
