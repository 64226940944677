import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { telEngOnly,telOnly,dupMailVal } from '../../assets/php/join';

const Confirm = ({page}) => {

	const [load,onLoad] = useState(true);
	
	useEffect( () => {


		const useeffect = async() => {

		let formData = new FormData();
		formData.append("reserve_num", window.sessionStorage.getItem('reserve_num'));
		console.log(window.sessionStorage.getItem('reserve_num'))
		await axios
		  .post("/assets/php/search_my_reservation.php", formData, {
			headers: {
			  "Content-Type": "multipart/form-data",
			},
		  })
		  .then((res) => {
			if(load){
			console.log(res.data);
	  
			let grade = "";
			if (res.data.reserve_grade === "1") {
			  grade = "STANDARD";
			} else if (res.data.reserve_grade === "2") {
			  grade = "GREAT";
			} else if (res.data.reserve_grade === "3") {
			  grade = "SPECIAL";
			}
		   
			document.join_form3.complement_young_mem_name_en.value =
			  res.data.reserve_name;
			document.join_form3.complement_young_mem_birth.value =
			  res.data.reserve_birth;
			document.join_form3.complement_young_mem_email.value =
			  res.data.reserve_mail;
			document.join_form3.complement_young_mem_phone.value =
			  res.data.reserve_num;
	  
			document.join_form3.complement_young_mem_nation.value =
			  res.data.reserve_nation;
			document.join_form3.complement_breakfast.value =
			  res.data.reserve_breakfast;
			document.join_form3.complement_dinner.value = res.data.reserve_dinner;
			document.join_form3.complement_message.value = res.data.reserve_massage;
	  
			document.getElementById("complement_checkin").innerHTML =
			  res.data.reserve_start;
			document.getElementById("complement_checkout").innerHTML =
			  res.data.reserve_end;
			document.getElementById("complement_totalman").innerHTML =
			  res.data.reserve_howmany;
			document.getElementById("complement_grade").innerHTML = grade;
			document.getElementById("complement_money").innerHTML =
			  res.data.reserve_price;
			document.getElementById("complete_date").innerHTML =
			  res.data.reserve_start;
	  
			window.scrollTo(0, 0);
			onLoad(false)
			window.sessionStorage.removeItem('reserve_num')
		}
		  })
		  .catch((error) => {
			alert("에러발생, 관리자에게 문의해주세요");
			console.log(error);
			window.sessionStorage.removeItem('reserve_num')
			onLoad(false)
			throw new Error(error);
		  });
		}
		useeffect();
	  return () => {
		onLoad(false)
		window.sessionStorage.removeItem('reserve_num')
	  };
	}, [page]);

	return (
		<div id="complete" className="roomContent nav_eraser">
		<header className="header2 nav_eraser">
			<div>
			</div>
		</header>
		<div className="content_area">
			<div>
				<h1 className="title1">예약 확인</h1>

				<div className="height40"></div>
				<div className="sec1text title">
					<h2 className="title2" style={{fontFamily:'바탕',fontWeight:100}}><span id="complete_date"></span>날짜에 뵙겠습니다.</h2>
					<p>예약정보는 입력하신 이메일로 전송되었습니다.</p>
				</div>
				<form className="join_form3 disable" name="join_form3" id="join_form3" method="post" onSubmit={() => void(0)}>
					<div className="title_sub">
						<h3>기본 정보</h3>
						<div className="form_wrap">
							<div className="tr">
								<div className="form_wrap_title">
									<h4>* 성명(영문)</h4>
								</div>
								<div className="form_wrap_input">
									<input type="text" className="input2" maxLength="20" name="complement_young_mem_name_en" id="complement_young_mem_name_en" onKeyUp={telEngOnly} required disabled />
								</div>
							</div>
							<div className="tr">
								<div className="form_wrap_title">
									<h4>* 생년월일</h4>
								</div>
								<div className="form_wrap_input">
									<input type="text" onKeyUp={telOnly} className="input2" maxLength="8" name="complement_young_mem_birth" id="complement_young_mem_birth" placeholder="8자리를 입력해주세요" required disabled />
								</div>
							</div>
							<div className="tr">
								<div className="form_wrap_title">
									<h4>* 이메일</h4>
								</div>
								<div className="form_wrap_input">
									<input type="email" className="input2" name="complement_young_mem_email" id="complement_young_mem_email" onKeyUp={dupMailVal} required disabled />
								</div>
							</div>
							<div className="tr">
								<div className="form_wrap_title">
									<h4>* 휴대전화</h4>
								</div>
								<div className="form_wrap_input">
									<input type="tel" className="input2" maxLength="20" onKeyUp={telOnly} name="complement_young_mem_phone" id="complement_young_mem_phone" required disabled />
								</div>
							</div>
							<div className="tr">
								<div className="form_wrap_title">
									<h4>* 국적</h4>
								</div>
								<div className="form_wrap_input">
									<input type="text" className="input2" name="complement_young_mem_nation" id="complement_young_mem_nation" placeholder="국적을 입력하세요" required disabled />
								</div>
							</div>
							<div className="tr">
								<div className="form_wrap_title">
									<h4>* 조식 횟수 <span id="breakfast"></span></h4>
								</div>
								<div className="form_wrap_input">
									<input type="tel" className="input2" maxLength="4" name="complement_breakfast" id="complement_breakfast" required disabled />
									<p className="small">※1회당 모든 투숙객 제공</p>
								</div>
							</div>
							<div className="tr">
								<div className="form_wrap_title">
									<h4>* 석식 횟수 <span id="dinner"></span></h4>
								</div>
								<div className="form_wrap_input">
									<input type="tel" className="input2" maxLength="4" name="complement_dinner" id="complement_dinner" required disabled />
									<p className="small">※1회당 모든 투숙객 제공</p>
								</div>
							</div>
							<div className="tr">
								<div className="form_wrap_title">
									<h4>* 전달사항</h4>
								</div>
								<div className="form_wrap_input">
									<textarea placeholder="기타 전달사항" name="complement_message" id="complement_message" cols="15" rows="10" disabled></textarea>
								</div>
							</div>
						</div>
					</div>
					<div className="title_sub">
						<h3>예약 정보</h3>
						<div className="form_wrap">
							<div className="tr">
								<div className="form_wrap_title">
									<h4>* 체크인 날짜</h4>
								</div>
								<div className="form_wrap_input">
									<p className="inform_p" id="complement_checkin"></p>
								</div>
							</div>
							<div className="tr">
								<div className="form_wrap_title">
									<h4>* 체크아웃 날짜</h4>
								</div>
								<div className="form_wrap_input">
									<p className="inform_p" id="complement_checkout"></p>
								</div>
							</div>
							<div className="tr">
								<div className="form_wrap_title">
									<h4>* 총 인원</h4>
								</div>
								<div className="form_wrap_input">
									<p className="inform_p" id="complement_totalman"></p>
								</div>
							</div>
							<div className="tr">
								<div className="form_wrap_title">
									<h4>* 방 등급</h4>
								</div>
								<div className="form_wrap_input">
									<p className="inform_p" id="complement_grade"></p>
								</div>
							</div>
							<div className="tr">
								<div className="form_wrap_title">
									<h4>* 총 결제 금액</h4>
								</div>
								<div className="form_wrap_input">
									<p className="inform_p" id="complement_money"></p>
								</div>
							</div>
						</div>
					</div>

				</form>
			</div>
		</div>
	</div>
	);
};

export default Confirm;