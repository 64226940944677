import axios from 'axios';
export const onLogin = (e) => {
	e.preventDefault();
	let form_id = document.login_form.form_id.value;
	let form_pass = document.login_form.form_pass.value;
	
	let formData = new FormData()
	formData.append('form_id',form_id)
	formData.append('form_pass',form_pass)
	axios.post(process.env.REACT_APP_URL + "/assets/php/login.php", formData, {
		headers: {
		  "Content-Type": "multipart/form-data",
		},
	  })
	  
	  .then((res) => {
		// console.log(res);
		if (res.data.split('|||')[0] === 'code1') {
			alert('아이디 혹은 비밀번호가 틀립니다');
		} else if (res.data.split('|||')[0] === 'code2') {
			alert('아이디 혹은 비밀번호가 틀립니다');
		} else if (res.data.split('|||')[0] === 'code0') {
			alert('로그인을 성공했습니다.');
			window.sessionStorage.setItem('session_hash', res.data.split('|||')[1])
			window.sessionStorage.setItem('session_id', form_id);
			window.location.href = '/';
		} else {
			return alert(res.data)
		}
	}).catch(error => {
		alert('에러발생, 관리자에게 문의해주세요');
		console.log(error);
		throw new Error(error);
	});
}


async function onLogout() {
	window.sessionStorage.removeItem('session_id');
	window.sessionStorage.removeItem('session_hash');

	await axios({
		method: "POST",
		url: '/assets/php/logout.php',
	}).then((res) => {
		console.log(res);
		if (res.data.split('|||')[0] === 'code0') {
			alert('로그아웃 성공');
			window.location.href = '/';
			return
		} else {
			alert('에러발생, 관리자에게 문의해주세요');
			window.location.reload()
		}
	}).catch(error => {
		alert('에러발생, 관리자에게 문의해주세요');
		console.log(error);
		throw new Error(error);
	});
	return;
}


window.addEventListener('load', function () {
	if (window.sessionStorage.getItem('session_id')) {
		let loginTime = 1800;
		setInterval(function () {
			
			if(loginTime > 0){
				loginTime = loginTime - 1
			} else {
				onLogout();
			}
		
		}, 1000)
	}

});
export {onLogout};