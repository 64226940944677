import React, { useEffect } from 'react';
import {toIndex} from '../../assets/js/event'
import { home,reserve,room1,room2,room3,onsen,kaiseki,intro,event,inquire,business1,business2,login,join,confirm,mypage,search, restaurant } from '../../store/module/nav';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link
  } from "react-router-dom";
const EventForm = ({setPage,dispatch}) => {

const onError = (e) => {
	e.target.src=require('../../assets/img/mainimg.jpg')
}

	useEffect(()=>{
		let href = "file_" + window.sessionStorage.getItem('event_form');
		if (document.getElementById(href).getAttribute('href') === 'https://hoeiso.gloomy-store.comnull' || document.getElementById(href).getAttribute('href') === 'null'  || document.getElementById(href).getAttribute('href') === 'https://hoeiso.gloomy-store.com') document.getElementById(href).style.display = 'none';
	},[])

	const OnPageMove = (e) => {
		setPage(e)
	}
	const onActive = (e) => {
		e.target.parentElement.classList.add('active')
	}
	const offActive = (e) => {
		e.target.parentElement.classList.remove('active')
	}


	return (
		<div id="event_form" className="roomContent event_form">
		<header className="header2 nav_eraser" id={window.sessionStorage.getItem('event_form')}>
			<div>
			</div>
		</header>
		<div className="content_area">
			<aside className="roomMenu">
				<div>
					<div>
						<h2>고객서비스</h2>
					</div>
					<div>
						<h3 onMouseOver={onActive} onMouseLeave={offActive}><Link to="/intro" className="onIntro depth2" onClick={(e) => {dispatch(intro());OnPageMove(e.target.classList[0])}}>료칸소개</Link></h3>
						<h3 onMouseOver={onActive} onMouseLeave={offActive}><Link to="/event" className="onEvent depth2" onClick={(e) => {dispatch(event());OnPageMove(e.target.classList[0])}}>이벤트</Link></h3>
						<h3 onMouseOver={onActive} onMouseLeave={offActive}><Link to="/inquire" className="onInquiry depth2" onClick={(e) => {dispatch(inquire());OnPageMove(e.target.classList[0])}}>문의하기</Link></h3>
					</div>
				</div>
			</aside>
			<div>
				<h1><img src={require("../../assets/img/event.png")} alt="제목이미지" className="subject_image" />
				</h1>
				<div className="event_box">
					<h2 id="event_title">{window.sessionStorage.getItem('event_title')}</h2>
				</div>
				<div className="event_box type2">
					<p>작성자: <br className="onlySP" /><span id="event_writer">{window.sessionStorage.getItem('event_writer')}</span>&nbsp;<b className="onlyPC inlineBlock">| </b></p>
					<p>등록일: <br className="onlySP" /><span id="event_reg">{window.sessionStorage.getItem('event_reg').slice(0,10)}</span>&nbsp;<b className="onlyPC inlineBlock">| </b></p>
					<p>조회수: <span id="event_hit">{window.sessionStorage.getItem('event_hit')}</span></p>
				</div>
				<div className="attached" id="attached">
					<a href={"https://hoeiso.gloomy-store.com"+window.sessionStorage.getItem('attached')} id={"file_"+window.sessionStorage.getItem('event_form')}>첨부파일</a>
				</div>
				<div className="height40"></div>

				<div className="board_content">
					<div className="board_content_img" id="event_main_img">
						<img src={process.env.REACT_APP_URL+window.sessionStorage.getItem('event_main_img')} alt="제목이미지" className="board_content_img" onError={onError} />
					</div>
					<div className="board_content_p">
						<p><span id="event_date_from">{window.sessionStorage.getItem('event_date_from')}</span> ~ <span id="event_date_to">{window.sessionStorage.getItem('event_date_to')}</span></p>
						<p id="event_main_txt">{window.sessionStorage.getItem('event_main_txt').replace(/(<br>|<br\/>|<br \/>)/g, '\r\n')}</p>
					</div>
				</div>
				<div className="board_btns">
					<button type="button" onClick={toIndex}>목록</button>
				</div>
			</div>
		</div>
	</div>
	);
};

export default EventForm;