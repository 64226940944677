import 'react-app-polyfill/ie11'; 
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createStore } from 'redux';
import { Provider } from 'react-redux'; 
import rootReducer from './store' /* 이 라인으로 app에 리듀서를 내려줌*/
import {composeWithDevTools} from 'redux-devtools-extension'
const store = createStore(rootReducer, composeWithDevTools()); 
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
