import React, { useEffect, useRef, useState } from 'react';
import {onConfirmReserve} from '../../assets/php/reserve'
import {onLogin} from '../../assets/php/login'
import { home,reserve,room1,room2,room3,onsen,kaiseki,intro,event,inquire,business1,business2,login,join,confirm,mypage,search, restaurant,login2 } from '../../store/module/nav';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link
  } from "react-router-dom";

const data = new FormData();

const Login = ({setLogin,setPage,dispatch}) => {

	const reservations = useRef(null);
	const zeroCheck = useRef();
	const data = new FormData();
	const [formData,setFormData] = useState(data);
	const onSetFormData = (e) => {
		if(e.target.name === 'form_id') data.append('form_id',e.target.value)
		else if(e.target.name === 'form_pass') data.append('form_pass',e.target.value)
	}


	const OnPageMove = (e) => {
		setPage(e)
	}
	const onActive = (e) => {
		e.target.parentElement.classList.add('active')
	}
	const offActive = (e) => {
		e.target.parentElement.classList.remove('active')
	}
	
	return (


		<div id="login" className="roomContent login nav_eraser">
		<header className="header2 nav_eraser">
			<div>
			</div>
		</header>
		<div className="content_area">
			<aside className="roomMenu">
				<div>
					<div>
						<h2>고객서비스</h2>
					</div>
					<div>
						<h3 onMouseOver={onActive} onMouseLeave={offActive}><Link to="/login" className="onLogin depth2" onClick={(e) => {dispatch(login());OnPageMove(e.target.classList[0])}}>로그인</Link></h3>
						<h3 onMouseOver={onActive} onMouseLeave={offActive}><Link to="/join" className="onJoin depth2" onClick={(e) => {dispatch(join());OnPageMove(e.target.classList[0])}}>회원가입</Link></h3>
						<h3 onMouseOver={onActive} onMouseLeave={offActive}><Link to="/login:login2" className="onLogin2 depth2" onClick={(e) => {dispatch(login2());OnPageMove(e.target.classList[0])}}>예약확인</Link></h3>
					</div>
				</div>
			</aside>
			<div>
				<h1 className="title1">로그인</h1>
				<div className="height40"></div>
				<div className="sec1text title">
					<h2 className="title2" style={{fontFamily:'바탕',fontWeight:100}}>호에이소에 오신 것을 환영합니다.</h2>
					<p>호에이소 아이디와 비밀번호를 입력해주시기 바랍니다.</p>
					<p style={{fontWeight:100}}>※ 호에이소 회원이 되시면 회원만을 위한 다양한 서비스와 혜택을 받으실 수 있습니다.</p>
				</div>
				<form className="login_form" name="login_form" onSubmit={onLogin} method="post">
					<div>

						{/* <div className="member">
							<label htmlFor="member1"><input type="radio" name="form_member" id="member1" defaultChecked />로그인</label>
							<label htmlFor="member2" style={{marginLeft:10}}><input type="radio" name="form_member" id="member2" onClick={() => {window.location.href = '/login:login2'}}  />예약확인</label>
						</div> */}

						<div className="id_pass ">
							<input type="text" name="form_id" placeholder="아이디를 입력해주세요" required onChange={onSetFormData} />
							<input type="password" name="form_pass" placeholder="비밀번호를 입력해주세요" required onChange={onSetFormData} />
							<label htmlFor="save_id"><input type="checkbox" id="save_id" />아이디 저장</label>
						</div>

						<div className="join_find">
							<button type="button" onClick={()=> {window.location.href = '/join'}}>회원가입</button>
							<button type="button" style={{marginLeft:10}} onClick={()=> {window.location.href = '/find'}}>아이디/비밀번호 찾기</button>
						</div>
					</div>
					<div>
					<button type="submit" className="">로그인</button>
					</div>
				</form>
				<div className="alert">
					<p>이메일 연락처 등의 정보가 변경되면 웹사이트에서 회원정보를 수정해주시기 바랍니다.</p>
				</div>
			</div>
		</div>
	</div>

	);
};

export default Login;