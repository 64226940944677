import axios from 'axios';
import React, { useEffect } from 'react';
import {onPayment,moneyChange} from '../../assets/php/reserve'
import { onLogout } from '../../assets/php/login';
import { telEngOnly,telOnly,dupMailVal } from '../../assets/php/join';

const Payment = () => {

	useEffect(()=> {
		const x = window.sessionStorage.getItem('x')
		let $startDate = window.sessionStorage.getItem("startDate");
		let $endDate = window.sessionStorage.getItem("endDate");
		let $totalDate = window.sessionStorage.getItem("total_date");
		let $numTotal = window.sessionStorage.getItem("numTotal");
		let $numKids = window.sessionStorage.getItem("numKids");
		window.sessionStorage.setItem("breakfast1p", Number($numTotal) * 60000);
		window.sessionStorage.setItem("dinner1p", Number($numTotal) * 60000);
		let $weekday = window.sessionStorage.getItem("weekday");
		let $weekend = window.sessionStorage.getItem("weekend");
		window.sessionStorage.setItem("roomGrade", null);

		for (let i = 1; i < document.body.children.length - 1; i++) {
			document.body.children[i].style.display = "none";
		}
		document.getElementById("payment").style.display = "block";

		console.log(x);
		console.log($totalDate);

		if (x === "room_standard") {
			document.getElementById("pay_grade").innerHTML = "STANDARD";
			window.sessionStorage.setItem("roomGrade", "1");
			document.getElementById("pay_money").innerHTML =
			$weekday * 60000 + $weekend * 90000;
			window.sessionStorage.setItem(
			"noFoodMoney",
			$weekday * 60000 + $weekend * 90000
			);
		} else if (x === "room_great") {
			document.getElementById("pay_grade").innerHTML = "GREAT";
			window.sessionStorage.setItem("roomGrade", "2");
			document.getElementById("pay_money").innerHTML =
			$weekday * 100000 + $weekend * 150000;
			window.sessionStorage.setItem(
			"noFoodMoney",
			$weekday * 100000 + $weekend * 150000
			);
		} else if (x === "room_special") {
			document.getElementById("pay_grade").innerHTML = "SPECIAL";
			window.sessionStorage.setItem("roomGrade", "3");
			document.getElementById("pay_money").innerHTML =
			$weekday * 150000 + $weekend * 225000;
			window.sessionStorage.setItem(
			"noFoodMoney",
			$weekday * 150000 + $weekend * 225000
			);
		}

		document.getElementById("pay_checkin").innerHTML = $startDate;
		document.getElementById("pay_checkout").innerHTML = $endDate;
		document.getElementById("pay_totalman").innerHTML = $numTotal;

		axios
			.post("/assets/php/get_member.php")
			.then((res) => {
			if (
				typeof res.data === "string" &&
				res.data.split("|||")[0] === "code1"
			) {
				if (window.sessionStorage.getItem("session_id")) onLogout();
				alert("로그인 해주세요");
			} else {
				console.log(res);
				document.join_form2.con_young_mem_name_local_first.value =
				res.data.con_young_mem_name_local_first;
				document.join_form2.con_young_mem_name_local_last.value =
				res.data.con_young_mem_name_local_last;
				document.join_form2.con_young_mem_name_en_first.value =
				res.data.con_young_mem_name_en_first;
				document.join_form2.con_young_mem_name_en_last.value =
				res.data.con_young_mem_name_en_last;
				document.join_form2.con_young_mem_email.value =
				res.data.con_young_mem_email;
				document.join_form2.con_young_mem_phone.value =
				res.data.con_young_mem_phone;
				document.join_form2.con_young_mem_birth.value =
				res.data.con_young_mem_birth;
				document.join_form2.con_young_mem_nation.value =
				res.data.con_young_mem_nation;
			}
			})
			.catch((error) => {
			alert("에러발생, 관리자에게 문의해주세요");
			console.log(error);
			throw new Error(error);
			});


	},[])
    return (
        <div id="payment" className="roomContent nav_eraser">
		<header className="header2 nav_eraser">
			<div>
			</div>
		</header>
		<div className="content_area">
			<div>
				<h1 className="title1">예약 진행</h1>

				<div className="height40"></div>
				<div className="sec1text title">
					<h2 className="title2" style={{fontFamily:'바탕',fontWeight:100}}>곧, 예약이 완료됩니다.</h2>
					<p>정보를 확인하고 결제를 진행해주세요</p>
				</div>
				<form className="join_form2" name="join_form2" id="join_form2" method="post" onSubmit={onPayment}>
				<div className="title_sub">
					<h3>기본 정보</h3>
					<div className="form_wrap">
					<div className="tr">
						<div className="form_wrap_title">
						<h4>* 성명(한글)</h4>
						</div>
						<div className="form_wrap_input">
						<h5>First name(이름)</h5>
						<input
							type="text"
							className="input2"
							maxLength="20"
							name="con_young_mem_name_local_first"
							id="con_young_mem_name_local_first"
							required
						/>
						<br className="onlySP" />
						<h5>Last name(성)</h5>
						<input
							type="text"
							className="input2"
							maxLength="20"
							name="con_young_mem_name_local_last"
							id="con_young_mem_name_local_last"
							required
						/>
						</div>
					</div>
					<div className="tr">
						<div className="form_wrap_title">
						<h4>* 성명(영문)</h4>
						</div>
						<div className="form_wrap_input">
						<h5>First name(이름)</h5>
						<input
							type="text"
							className="input2"
							maxLength="20"
							name="con_young_mem_name_en_first"
							id="con_young_mem_name_en_first"
							onKeyUp={telEngOnly}
							required
						/>
						<br className="onlySP" />
						<h5>Last name(성)</h5>
						<input
							type="text"
							className="input2"
							maxLength="20"
							name="con_young_mem_name_en_last"
							id="con_young_mem_name_en_last"
							onKeyUp={telEngOnly}
							required
						/>
						</div>
					</div>
					<div className="tr">
						<div className="form_wrap_title">
						<h4>* 생년월일</h4>
						</div>
						<div className="form_wrap_input">
						<input
							type="text"
							onKeyUp={telOnly}
							className="input2"
							maxLength="8"
							name="con_young_mem_birth"
							id="con_young_mem_birth"
							placeholder="8자리를 입력해주세요"
							required
						/>
						</div>
					</div>
					<div className="tr">
						<div className="form_wrap_title">
						<h4>* 국적</h4>
						</div>
						<div className="form_wrap_input">
						<input
							type="text"
							className="input2"
							name="con_young_mem_nation"
							id="con_young_mem_nation"
							placeholder="국적을 입력하세요"
							required
						/>
						</div>
					</div>
					<div className="tr">
						<div className="form_wrap_title">
						<h4>* 이메일</h4>
						</div>
						<div className="form_wrap_input">
						<input
							type="email"
							className="input3"
							name="con_young_mem_email"
							id="con_young_mem_email"
							onKeyUp={dupMailVal}
							required
						/>
						</div>
					</div>
					<div className="tr">
						<div className="form_wrap_title">
						<h4>* 휴대전화</h4>
						</div>
						<div className="form_wrap_input">
						<input
							type="tel"
							className="input2"
							maxLength="20"
							onKeyUp={telOnly}
							name="con_young_mem_phone"
							id="con_young_mem_phone"
							onChange={moneyChange}
							required
						/>
						</div>
					</div>
					<div className="tr">
						<div className="form_wrap_title">
						<h4>
							* 조식 횟수 <span id="breakfast"></span>
						</h4>
						</div>
						<div className="form_wrap_input">
						<input
							type="tel"
							className="input2"
							maxLength="4"
							onKeyUp={telOnly}
							name="con_breakfast"
							id="con_breakfast"
							onChange={moneyChange}
							required
						/>
						<p className="small">※1회당 모든 투숙객 제공</p>
						</div>
					</div>
					<div className="tr">
						<div className="form_wrap_title">
						<h4>
							* 석식 횟수 <span id="dinner"></span>
						</h4>
						</div>
						<div className="form_wrap_input">
						<input
							type="tel"
							className="input2"
							maxLength="4"
							onKeyUp={telOnly}
							name="con_dinner"
							id="con_dinner"
							onChange={moneyChange}
							required
						/>
						<p className="small">※1회당 모든 투숙객 제공</p>
						</div>
					</div>
					<div className="tr">
						<div className="form_wrap_title">
						<h4>* 전달사항</h4>
						</div>
						<div className="form_wrap_input">
						<textarea
							placeholder="기타 전달사항"
							name="con_message"
							id="con_message"
							cols="15"
							rows="10"
						></textarea>
						</div>
					</div>
					</div>
				</div>
				<div className="title_sub">
					<h3>예약 정보</h3>
					<div className="form_wrap">
					<div className="tr">
						<div className="form_wrap_title">
						<h4>* 체크인 날짜</h4>
						</div>
						<div className="form_wrap_input">
						<p className="inform_p" id="pay_checkin">
							2022-01-01
						</p>
						</div>
					</div>
					<div className="tr">
						<div className="form_wrap_title">
						<h4>* 체크아웃 날짜</h4>
						</div>
						<div className="form_wrap_input">
						<p className="inform_p" id="pay_checkout">
							2022-01-01
						</p>
						</div>
					</div>
					<div className="tr">
						<div className="form_wrap_title">
						<h4>* 총 인원</h4>
						</div>
						<div className="form_wrap_input">
						<p className="inform_p" id="pay_totalman">
							2
						</p>
						</div>
					</div>
					<div className="tr">
						<div className="form_wrap_title">
						<h4>* 방 등급</h4>
						</div>
						<div className="form_wrap_input">
						<p className="inform_p" id="pay_grade">
							STANDARD
						</p>
						</div>
					</div>
					<div className="tr">
						<div className="form_wrap_title">
						<h4>* 총 결제 금액</h4>
						</div>
						<div className="form_wrap_input">
						<p className="inform_p" id="pay_money">
							240000원
						</p>
						</div>
					</div>
					</div>
				</div>
				<div id="btn_submit">
					{" "}
					<button type="submit">
					<img src={require('../../assets/img/payment.png')} />
					</button>
				</div>
				</form>
			</div>
		</div>
	</div>
    );
};

export default Payment;