import axios from 'axios';
import {onLogout} from '../php/login';



/* 모든 방정보 불러오기*/
function onSearch(e) {
	e.preventDefault();
	window.sessionStorage.setItem('startDate', document.reserve2.start_date_input.value);
	window.sessionStorage.setItem('endDate', document.reserve2.end_date_input.value);
	window.sessionStorage.setItem('numTotal', Number(document.reserve2.num_adult.value) + Number(document.reserve2.num_kids.value));
	window.sessionStorage.setItem('numKids', document.reserve2.num_kids.value);
	console.log(window.sessionStorage.getItem('numTotal'))
	axios.post(process.env.REACT_APP_URL + '/assets/php/search_room.php').then((res) => {
		console.log(res);
		if (typeof(res.data) === 'string' && res.data.split('|||')[0] === 'code1') {
			if(window.sessionStorage.getItem('session_id')) onLogout();
			alert('로그인 해주세요');
		} else if(res.data === 'code1|||') {
			onLogout();
		} else {
			onSearch2(res.data);
		}
	}).catch(error => {
		alert('에러발생, 관리자에게 문의해주세요');
		console.log(error);
		throw new Error(error);
	})
}

/**/
function onSearch2(data) {
	console.log(data);
	window.sessionStorage.setItem('data',JSON.stringify(data))

	let city = document.reserve2.city.value;
	let start_date_input = document.reserve2.start_date_input.value;
	let end_date_input = document.reserve2.end_date_input.value;
	let list = [];
	let total_date = getDateRange(start_date_input, end_date_input, list);
	let weekend = 0;
	let weekday = 0;
	for (let i = 0; i < total_date.length; i++) {
		if (new Date(total_date[i]).getDay() === 0 || new Date(total_date[i]).getDay() === 6) {
			weekend = weekend + 1;
		}
	}
	weekday = total_date.length - weekend;

	window.sessionStorage.setItem('total_date', total_date);
	window.sessionStorage.setItem('weekday', weekday);
	window.sessionStorage.setItem('weekend', weekend);

	let num_rooms = document.reserve2.num_rooms.value;
	let num_adult = document.reserve2.num_adult.value;
	let num_kids = document.reserve2.num_kids.value;

	window.sessionStorage.setItem('num_rooms', num_rooms);
	window.sessionStorage.setItem('num_adult', num_adult);
	window.sessionStorage.setItem('num_kids', num_kids);

	window.location.href = '/search';
}




/*사이날짜 구하는 함수*/
function getDateRange(startDate, endDate, listDate) {

	let dateMove = new Date(startDate);
	let strDate = startDate;

	while (strDate < endDate) {
		strDate = dateMove.toISOString().slice(0, 10);
		listDate.push(strDate);
		dateMove.setDate(dateMove.getDate() + 1);
		if (strDate === endDate) {
			listDate = listDate.slice(0, -1);
		}
	}

	return listDate;
};
export {onSearch}