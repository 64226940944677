import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import {home,reserve,room1,room2,room3,onsen,intro,event,inquire,business1,business2,login,join,confirm,mypage,search,login2} from "../../store/module/nav";
import { fadeOut } from '../../assets/js/main';

const Restaurant = ({page,setPage,dispatch}) => {
	const section5 = useRef(null)
	const slider = useRef(null)
	const sliderImg = useRef(null)
	let sec5Height = 0;
	const timeout = useRef(null);
useEffect(() => {
	// 식당, 가이세키 제일 윗부분 div에 높이 부여하기 (안하면 0임)
	slider.current.style.height = sliderImg.current.offsetHeight + 'px';

	

}, [page]);

/*슬라이드쇼 이미지 페이드*/
useEffect(() => {
	setInterval(
	  () => {
		if(slider.current){
		  fadeOut(slider.current.children[0])
		  setTimeout(function () {
			if(slider.current){
			slider.current.appendChild(slider.current.children[0]);
			slider.current.children[1].style.opacity = 1;
		  }
		}, 800)
		}
	  },
	  2500);
	timeout.current = setTimeout(() => {
	  clearInterval(slider);
	}, 2500);
	return () => {
	  clearInterval(slider);
	  clearTimeout(timeout.current);
	}
  }, []);



const sliderHeight = () => {
	slider.current.style.height = sliderImg.current.offsetHeight + 'px';
}

const OnPageMove = (e) => {
	setPage(e)
}
const onActive = (e) => {
	e.target.parentElement.classList.add('active')
}
const offActive = (e) => {
	e.target.parentElement.classList.remove('active')
}
    return (
        <div id="restaurant" className="roomContent food">
		<header className="header1 nav_eraser">
			<div className="slider" id="slider" ref={slider}>
				<img src={require("../../assets/img/rest/sik1.jpg")} alt="sik1" ref={sliderImg} />
				<img src={require("../../assets/img/rest/sik2.jpg")} alt="sik2" />
				<img src={require("../../assets/img/rest/sik3.jpg")} alt="sik3" />
			</div>
		</header>
		<div className="content_area">
			<aside className="roomMenu">
				<div>
					<div>
						<h2>다이닝</h2>
					</div>
					<div>
						<h3 onMouseOver={onActive} onMouseLeave={offActive}><Link to="/kaiseki" className="onRoom1 depth2" onClick={(e) => {dispatch(room1());OnPageMove(e.target.classList[0])}}>가이세키</Link></h3>
						<h3 onMouseOver={onActive} onMouseLeave={offActive}><Link to="/restaurant" className="onRoom2 depth2" onClick={(e) => {dispatch(room2());OnPageMove(e.target.classList[0])}}>레스토랑</Link></h3>
					</div>
				</div>
			</aside>
			<div>
				<h1><img src={require("../../assets/img/rest/rest.png")} alt="제목이미지" className="subject_image" />
				</h1>
				<section className="section1">
					<div>
						<div className="sec1pic">
							<img src={require("../../assets/img/rest/sik6.jpg")} alt="sik6" />
						</div>
						<div className="sec1text">
							<h2> 정통 일본식 고급 레스토랑 </h2>
							<p>호에이소는 프라이빗 룸을 완비, 숙박객 모두가 편안한 시간을 가질 수 있도록 본관 1층에 자리를 마련 했습니다. </p>
							<p>예로부터 건강에 꿩을 중심으로 한 고급 가이세키 요리를 제공합니다
								일본의 정식요리인 가이세키는, 코스요리와 그 뜻이 비슷합니다.<br /> 식전요리나 디저트 등 일본식의 정수를 느끼게 해줄 고급 요리들을, 호에이소는 신선한 재료로 준비합니다.</p>
						</div>
					</div>
				</section>
				<section className="section2">
					<div className="sec2pic1 onlySP">
						<img src={require("../../assets/img/rest/dine1_2.jpg")} alt="dine1_2" />
					</div>
					<div className="txtbox_withimg">
						<h2> 훌륭한 요리를 숙소에서 즐기는 </h2>
						<p>석식 플랜을 신청하면 키지테이 별관(숙소)에서 가족들과 함께 가이세키를 즐길 수 있습니다.</p>
						<p></p>
					</div>
					<div className="sec2pic1 onlyPC">
					<img src={require("../../assets/img/rest/dine1_2.jpg")} alt="dine1_2" />
					</div>
				</section>
				<section>
					<dl className="dl1">
						<dt>
							<div className="dt_h3">
								<h3> 이용시간 </h3>
							</div>
						</dt>
						<dd className="dt_p">
							<p> 점심: 11:30 ~ 15:30</p>
							<p> 저녁: 16:30 ~ 21:30 </p>
						</dd>
					</dl>
				</section>
				<section className="section4">
					<div className="sec4pic">
						<img src={require("../../assets/img/rest/cha1.jpg")} alt="cha1" />
					</div>
					<div className="txtbox_withimg">
						<h2> 소산원의 녹차가 준비되어 있습니다</h2>
						<p>

						</p>
						<p>호에이소는 궁중에서도 귀하게 여겼던 꿩요리를 개업 당시부터 제공하고 있습니다. <br /> 개업 당시에는 이곳에서 직정 꿩을 사육했으나, 현재는 시코쿠 지방에서 꿩을 제공받고 있습니다. <br /> 현재의 대표가 당시의 맛을 재현하려고 전국을 찾아 헤매서 찾은 것이 시코쿠의 기호쿠 꿩입니다</p>
					</div>
				</section>
				<section className="subsec5">
					<div>
						<img src={require("../../assets/img/rest/gallery.png")} className="subject_image" alt="제목이미지" />
					</div>
				</section>
			</div>
		</div>
		<section className="section5" ref={section5}>
			<div>
				<div className="fadeInOn web1">
					<div><a href="#none"><img src={require("../../assets/img/rest/sik1.jpg")} alt="sik1" /></a> </div>
					<div className="dim"><a href="#none">KAISEKI<br /><span className="span1">Summer</span></a></div>
				</div>

				<div className="fadeInOn web2">
					<div><a href="#none"><img src={require("../../assets/img/rest/sik2.jpg")} alt="sik2" /></a> </div>
					<div className="dim"><a href="#none">KAISEKI<br /><span className="span1">Pheasant Soup</span></a></div>
				</div>

				<div className="fadeInOn web3" style={{marginBottom:300}}>
					<div><a href="#none"><img src={require("../../assets/img/rest/sik3.jpg")} alt="sik3" onLoad={sliderHeight} /></a> </div>
					<div className="dim"><a href="#none">SAKE<br /><span className="span1"></span></a>
					</div>
				</div>
			</div>
		</section>
	</div>
    );
};

export default Restaurant;