import React, { useEffect, useRef, useState } from 'react';
import { boardView } from '../../assets/js/event';
import { home,reserve,room1,room2,room3,onsen,kaiseki,intro,event,inquire,business1,business2,login,join,confirm,mypage,search, restaurant } from '../../store/module/nav';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link
  } from "react-router-dom";
import axios from 'axios';



const Event = ({setPage,dispatch}) => {
	const [boardData,setBoardData] = useState([]);
	const [thisPage, setThisPage] = useState(0);
	const startButton = useRef();

	useEffect((e) => {
		eventLoader(0)
		
	  return () => {
		console.log(boardData)
	  };
	}, []);

	const [pages,setPages] = useState([])
	const eventLoader = async(v) => {
		window.scrollTo(0, 0);
		await axios.post(process.env.REACT_APP_URL + '/assets/php/SELECT_young_bor.php', {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}).then(res => { //응답성공
				console.log(res.data)
				let group = [];
				document.getElementById('total_event').innerHTML = res.data.length;
	
				let pageper = 5; // 웹페이지에 몇 페이지만 나눌지
				let howManyPage = Math.floor(res.data.length / pageper); // 총 몇 페이지인지 - 10페이지
				window.sessionStorage.setItem('howManyPage',howManyPage)
				window.sessionStorage.setItem('thisPage',v)
				
				for(let i=0;i<howManyPage;i++){
					group[i] = res.data.slice(i*pageper,(i*pageper)+pageper)
				}
				// 0~9페이지, 총 10페이지가 추출됨.
				console.log(group)
				setBoardData(group[v])
				console.log(boardData)
				console.log(v)
				setThisPage(v+1);
				let page = [];
				if( howManyPage > 5){
					if(v ===0 || v === 1 || v === 2){
						setPages([1,2,3,4,5])
						console.log('1')
					} else if (v === howManyPage || v === howManyPage -1 || v === howManyPage -2){
						setPages([howManyPage-4,howManyPage-3,howManyPage-2,howManyPage-1,howManyPage])
						console.log('2')
					} else {
						setPages([v-1,v,v+1,v+2,v+3])
						console.log('3')
					}
				} else {
					for(let i=0;i<howManyPage;i++){
						page[i] = i+1;
					}
					setThisPage(page)
				}

	
			})
			.catch(error => { //응답실패
				console.log(error)
			})
	
	}


	function pageChanger(e) {
		let whatPage = window.sessionStorage.getItem('thisPage');
		
		console.log(whatPage);
		let target = e.target.id;
		if(target === 'page_start'){
			eventLoader(0);
		} else if ( target === 'page_end'){
			eventLoader(Number(window.sessionStorage.getItem('howManyPage'))-1);
		} else if (target === 'page_left' && Number(whatPage)+1 !== 1){
			eventLoader(Number(whatPage)-2);
		} else if (target === 'page_right' && Number(whatPage)+1 !== Number(window.sessionStorage.getItem('howManyPage'))){
			eventLoader(Number(whatPage)+1);
		}
	}


	const OnError = (e) => {
		e.src = require('../../assets/img/mainimg.jpg')
	}
	const OnPageMove = (e) => {
		setPage(e)
	}
	const onActive = (e) => {
		e.target.parentElement.classList.add('active')
	}
	const offActive = (e) => {
		e.target.parentElement.classList.remove('active')
	}
	return (
		<div id="event" className="roomContent event">
		<header className="header2 nav_eraser">
			<div>
			</div>
		</header>
		<div className="content_area">
			<aside className="roomMenu">
				<div>
					<div>
						<h2>고객서비스</h2>
					</div>
					<div>
						<h3 onMouseOver={onActive} onMouseLeave={offActive}><Link to="/intro" className="onIntro depth2" onClick={(e) => {dispatch(intro());OnPageMove(e.target.classList[0])}}>료칸소개</Link></h3>
						<h3 onMouseOver={onActive} onMouseLeave={offActive}><Link to="/event" className="onEvent depth2" onClick={(e) => {dispatch(event());OnPageMove(e.target.classList[0])}}>이벤트</Link></h3>
						<h3 onMouseOver={onActive} onMouseLeave={offActive}><Link to="/inquire" className="onInquiry depth2" onClick={(e) => {dispatch(inquire());OnPageMove(e.target.classList[0])}}>문의하기</Link></h3>
					</div>
				</div>
			</aside>
			<div>
				<h1><img src={require("../../assets/img/event.png")} alt="제목이미지" className="subject_image" />
				</h1>
				<div className="event_box">
					<p>total: <span id="total_event">0</span></p>
				</div>
				<div className="height40"></div>

				<div className="board_wrap" id="board_wrap">
					{
						boardData.map((x) =>
						<a href="#none" onClick={(e) => boardView(x.YOUNG_bor_idx, e)} className="board_section" key={x.YOUNG_bor_idx} id={x.YOUNG_bor_idx}>
						<div className="thumb">
							<img src={process.env.REACT_APP_URL + x.YOUNG_bor_thumb} alt={x.YOUNG_bor_tit} onError={OnError} />
						</div>
						<div className="desc">
							<div className="h2">
								<h2>{x.YOUNG_bor_tit}</h2>
							</div>
							<div className="date">
								<p>{x.YOUNG_bor_from} ~ {x.YOUNG_bor_to}</p>
							</div>
							<div className="descript">
								<p>{x.YOUNG_bor_con.length < 88 ? x.YOUNG_bor_con.replace(/(<br>|<br\/>|<br \/>)/g, '\r\n') :
								x.YOUNG_bor_con.replace(/(<br>|<br\/>|<br \/>)/g, '\r\n').slice(0,88) + "..."
								}</p>
							</div>
						</div>
					</a>
						 )
					}
				</div>
				<div className="paging">
					<div className="paging_left">
						<button type="button" id="page_start" onClick={(e)=>pageChanger(e)} ref={startButton}>&lt;&lt;</button>
						<button type="button" id="page_left" onClick={(e)=>pageChanger(e)}>&lt;</button>
					</div>
					<div className="paging_num" id="paging_num">
						{/* <button type="button">1</button> */}
						{
							pages.map((x,i) => 
							<button type="button" onClick={() => eventLoader(x-1)} className={x === thisPage ? "thispage" : null} key={i}>{x}</button>
							)
						}
					</div>
					<div className="paging_right">
						<button type="button" id="page_right" onClick={(e)=>pageChanger(e)}>&gt;</button>
						<button type="button" id="page_end" onClick={(e)=>pageChanger(e)}>&gt;&gt;</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	);
};

export default Event;