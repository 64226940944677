import React from 'react';
import { onChangePw } from '../../assets/php/find';

const ChangePw = () => {
    return (
        <div id="change" className="roomContent login nav_eraser">
		<header className="header2 nav_eraser">
			<div>
			</div>
		</header>
		<div className="content_area">
			<aside className="roomMenu">
				<div>
					<div>
						<h2>고객서비스</h2>
					</div>
					<div>
						<h3><a href="#none" className="onIntro">
								로그인
							</a></h3>
						<h3><a href="#none" className="onEvent">
								회원가입
							</a></h3>
						<h3><a href="#none" className="onInquiry">
								예약확인
							</a></h3>
					</div>
				</div>
			</aside>
			<div>
				<h1 className="title1">새 비밀번호</h1>
				<div className="height40"></div>
				<div className="sec1text title">
				</div>
				<form className="find_form" name="change_form" onSubmit={onChangePw} method="post">
					<div>
						<div className="id_pass ">
							<input type="password" name="pass1" placeholder="비밀번호 입력" required />
							<input type="password" name="pass2" placeholder="비밀번호 확인" required />
						</div>

					</div>
					<div>
					<button type="submit" className="">아이디 찾기</button>
					</div>
				</form>
				<div className="alert">
					<p>이메일 연락처 등의 정보가 변경되면 웹사이트에서 회원정보를 수정해주시기 바랍니다.</p>
				</div>
			</div>
		</div>
	</div>
    );
};

export default ChangePw;