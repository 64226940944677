import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import {home,reserve,room1,room2,room3,onsen,intro,event,inquire,business1,business2,login,join,confirm,mypage,search,login2} from "../../store/module/nav";

const Kaiseki = ({page,setPage,dispatch}) => {

    const section5 = useRef(null)
	let sec5Height = 0;
	
useEffect(() => {

}, [page]);

	const OnPageMove = (e) => {
		setPage(e)
	}
	const onActive = (e) => {
		e.target.parentElement.classList.add('active')
	}
	const offActive = (e) => {
		e.target.parentElement.classList.remove('active')
	}

    return (
        <div id="kaiseki" className="roomContent food">
		<header className="header1">
			<div className="nav_eraser">
				<img src={require("../../assets/img/kaiseki/dine2_2.jpg")} className="nav_eraser" />
				<h2 className="tagline" data-letterfx="custom[0]">雉子亭 <span> 豊栄荘</span> </h2>
				<div> </div>
			</div>
		</header>
		<div className="content_area">
			<aside className="roomMenu">
				<div>
					<div>
						<h2>다이닝</h2>
					</div>
					<div>
						<h3 onMouseOver={onActive} onMouseLeave={offActive}><Link to="/kaiseki" className="onRoom1 depth2" onClick={(e) => {dispatch(room1());OnPageMove(e.target.classList[0])}}>가이세키</Link></h3>
						<h3 onMouseOver={onActive} onMouseLeave={offActive}><Link to="/restaurant" className="onRoom2 depth2" onClick={(e) => {dispatch(room2());OnPageMove(e.target.classList[0])}}>레스토랑</Link></h3>
					</div>
				</div>
			</aside>
			<div>
				<h1><img src={require("../../assets/img/kaiseki/kaiseki.png")} alt="제목이미지" className="subject_image" />
				</h1>
				<section className="section1">
					<div>
						<div className="sec1pic">
							<img src={require("../../assets/img/kaiseki/dine6.jpg")} />
						</div>
						<div className="sec1text">
							<h2> 일본의 전통식, 가이세키 </h2>
							<p>호에이소는 꿩을 중심으로 한 고급 가이세키 요리를 제공합니다</p>
							<p>일본의 정식요리인 가이세키는, 코스요리와 그 뜻이 비슷합니다.<br /> 식전요리나 디저트 등 일본식의 정수를 느끼게 해줄 고급 요리들을, 호에이소는 신선한 재료로 준비합니다.</p>
						</div>
					</div>
				</section>
				<section className="section6">
					<div className="section6_1">
						<div className="section6_1_1">
							<img src={require("../../assets/img/kaiseki/dine1_2.jpg")} />
						</div>
						<div className="section6_1_2 txtbox_withimg">
							<h2> 훌륭한 요리를 숙소에서 즐기는 </h2>
							<p>석식 플랜을 신청하면 키지테이 별관(숙소)에서 가족들과 함께 가이세키를 즐길 수 있습니다.</p>
						</div>
					</div>
					<div className="height40 onlySP"></div>
					<div className="section6_2">
						<div className="section6_2_2 onlySP">
							<img src={require("../../assets/img/kaiseki/dine3_2.jpg")} />
						</div>
						<div className="section6_2_1 txtbox_withimg">
							<h2> 일본의 전통식, 가이세키 </h2>
							<p>호에이소는 꿩을 중심으로 한 고급 가이세키 요리를 제공합니다</p>
							<p>일본의 정식요리인 가이세키는, 코스요리와 그 뜻이 비슷합니다.<br /> 식전요리나 디저트 등 일본식의 정수를 느끼게 해줄 고급 요리들을, 호에이소는 신선한 재료로 준비합니다.</p>
						</div>
						<div className="section6_2_2 onlyPC">
							<img src={require("../../assets/img/kaiseki/dine3_2.jpg")} />
						</div>
					</div>
				</section>

				<section className="section3">
					<div className="section3_2 onlySP">
						<img src={require("../../assets/img/kaiseki/dine4.jpg")} />
					</div>
					<div className="section3_1 txtbox_withimg">
						<h2> 일본 최고의 셰프가 선보이는 정통 꿩 요리 </h2>
						<p> 키지테이 · 호에이소에서는 방에 앉아서 기다리는 것 만으로 맛있는 꿩 요리를 즐길 수 있습니다. </p>
						<p> 담백하면서도 맛이 풍부한 꿩 요리는 남녀노소 부담없이 즐길 수 있습니다. </p>
					</div>
					<div className="section3_2 onlyPC">
						<img src={require("../../assets/img/kaiseki/dine4.jpg")} />
					</div>
				</section>
				<section>
					<dl className="dl1">
						<dt>
							<div className="dt_h3">
								<h3> 이용시간 </h3>
							</div>
						</dt>
						<dd className="dt_p">
							<p> 점심: 11:30 ~ 15:30</p>
							<p> 저녁: 16:30 ~ 21:30 </p>
						</dd>
					</dl>
				</section>
				<section className="section3">
					<div className="section3_2 onlySP">
						<img src={require("../../assets/img/kaiseki/dine5.jpg")} />
					</div>
					<div className="section3_1 txtbox_withimg">
						<h2> 기호쿠의 꿩을 최상의 맛으로 </h2>
						<p>하코네 지역의 유일한 꿩고기 취급소</p>
						<p>호에이소는 궁중에서도 귀하게 여겼던 꿩요리를 개업 당시부터 제공하고 있습니다. <br /> 개업 당시에는 이곳에서 직정 꿩을 사육했으나, 현재는 시코쿠 지방에서 꿩을 제공받고 있습니다. <br /> 현재의 대표가 당시의 맛을 재현하려고 전국을 찾아 헤매서 찾은 것이 시코쿠의 기호쿠 꿩입니다</p>
					</div>
					<div className="section3_2 onlyPC">
						<img src={require("../../assets/img/kaiseki/dine5.jpg")} />
					</div>
				</section>
				<section className="subsec5">
					<div>
						<img src={require("../../assets/img/kaiseki/gallery.png")} className="subject_image" alt="제목이미지" />
					</div>
				</section>
			</div>
		</div>
		<section className="section5" ref={section5}>
			<div>
				<div className="fadeInOn web1">
					<div><a href="#none"><img src={require("../../assets/img/kaiseki/dine6.jpg")} /></a> </div>
					<div className="dim"><a href="#none">KAISEKI<br /><span className="span1">Summer</span></a></div>
				</div>

				<div className="fadeInOn web2">
					<div><a href="#none"><img src={require("../../assets/img/kaiseki/dine7.jpg")} /></a> </div>
					<div className="dim"><a href="#none">KAISEKI<br /><span className="span1">Pheasant Soup</span></a></div>
				</div>

				<div className="fadeInOn web3">
					<div><a href="#none"><img src={require("../../assets/img/kaiseki/dine8.jpg")} /></a> </div>
					<div className="dim"><a href="#none">SAKE<br /><span className="span1"></span></a>
					</div>
				</div>

				<div className="fadeInOn web4">
					<div><a href="#none"><img src={require("../../assets/img/kaiseki/dine9.jpg")} /></a> </div>
					<div className="dim"><a href="#none">SAKE<br /><span className="span1"></span></a></div>
				</div>
				<div className="fadeInOn web5">
					<div><a href="#none"><img src={require("../../assets/img/kaiseki/dine2_2.jpg")} /></a> </div>
					<div className="dim"><a href="#none">KAISEKI<br /><span className="span1">Pheasant meat</span></a></div>
				</div>
				<div className="fadeInOn web6">
					<div style={{marginBottom:450}}><a href="#none"><img src={require("../../assets/img/kaiseki/dine1_2.jpg")} /></a> </div>
					<div className="dim"><a href="#none">KAISEKI<br /><span className="span1">Desert</span></a></div>
				</div>
			</div>
		</section>
	</div>
    );
};

export default Kaiseki;