import React from "react";
import axios from "axios";
import { onLogout } from "../php/login";

/*예약 메뉴에 현재 한국날짜를 1초마다 업데이트*/



export const onConfirm = (x, e) => {
  e.preventDefault();
  window.sessionStorage.setItem('x',x)
  window.location.href = '/payment'
  
}

export const onPayment = async(e) => {
  e.preventDefault();
  if (!window.confirm("정말 결제 하시겠습니까?")) {
    alert("결제 취소");
  } else {
    await axios
      .post("/assets/php/search_room.php")
      .then((res) => {
        console.log(res);
        if (
          typeof res.data === "string" &&
          res.data.split("|||")[0] === "code1"
        ) {
          if (window.sessionStorage.getItem("session_id")) onLogout();
          alert("로그인 해주세요");
        } else {
          onPayment2(res.data);
        }
      })
      .catch((error) => {
        alert("에러발생, 관리자에게 문의해주세요");
        console.log(error);
        throw new Error(error);
      });
  }
}

export const onPayment2 = async(data) => {
  let $startDate = window.sessionStorage.getItem("startDate");
  let $endDate = window.sessionStorage.getItem("endDate");
  let $totalDate = window.sessionStorage.getItem("total_date").split(",");
  let $numTotal = window.sessionStorage.getItem("numTotal");
  let $numKids = window.sessionStorage.getItem("numKids");

  console.log(data);

  let room_grade = window.sessionStorage.getItem("roomGrade");

  let $name =
    document.join_form2.con_young_mem_name_en_first.value +
    ", " +
    document.join_form2.con_young_mem_name_en_last.value;
  let $breakfast_order = document.join_form2.con_breakfast.value;
  let $dinner_order = document.join_form2.con_dinner.value;
  let $userbirth = document.join_form2.con_young_mem_birth.value;
  let $usernum = document.join_form2.con_young_mem_phone.value;
  let $usermail = document.join_form2.con_young_mem_email.value;
  let $nation = document.join_form2.con_young_mem_nation.value;

  let formData = new FormData();
  formData.append("totalDate", $totalDate);
  let formData2 = new FormData();

  for (let i = 0; i < data.length; i++) {
    if (
      data[i].rooms_grade === room_grade &&
      $totalDate.filter((x) => data[i].rooms_reservation.split(",").includes(x))
        .length === 0
    ) {
      console.log($totalDate);
      console.log(data[i].rooms_reservation.split(","));

      let newDate = [];
      if (data[i].rooms_reservation.split(",")[0] === "") {
        newDate = [...$totalDate];
      } else {
        newDate = [...data[i].rooms_reservation.split(","), ...$totalDate];
        newDate.sort(function (a, b) {
          a = new Date(a.dateModified);
          b = new Date(b.dateModified);
          return a > b ? -1 : a < b ? 1 : 0;
        });
      }
      console.log(newDate);
      let $room_number = data[i].rooms_room;
      formData.append("roomNumber", $room_number);
      formData2.append("roomNumber", $room_number);
      break;
    }
  }

  await axios
    .post("/assets/php/UPDATE_tblroom.php", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      console.log(res);
      if (
        /*typeof (res.data) === 'string' &&*/ res.data.split("|||")[0] ===
        "code1"
      ) {
        if (window.sessionStorage.getItem("session_id")) onLogout();
        alert("로그인 해주세요");
        return;
      } else if (res.data.split("|||")[0] === "code0") {
        console.log("룸 데이터 업데이트 성공");
      } else {
        alert("에러발생, 관리자에게 문의하세요");
        return;
      }
    })
    .catch((error) => {
      alert("에러발생, 관리자에게 문의해주세요");
      console.log(error);
      throw new Error(error);
      return;
    });

  await axios
    .post("/assets/php/lastidx_tblreservation.php")
    .then((res) => {
      console.log(res);
      if (res.data.split("|||")[0] === "code1") {
        if (window.sessionStorage.getItem("session_id")) onLogout();
        alert("로그인 해주세요");
        return;
      } else if (res.data.split("|||")[0] === "code0") {
        window.sessionStorage.setItem("lastidx", res.data.split("|||")[1]);
      } else {
        alert("에러발생, 관리자에게 문의하세요");
        return;
      }
    })
    .catch((error) => {
      alert("에러발생, 관리자에게 문의해주세요");
      console.log(error);
      throw new Error(error);
      return;
    });

  await axios
    .post("/assets/php/SELECT_roomgrade.php", formData2, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      console.log(res);
      if (res.data.split("|||")[0] === "code1") {
        if (window.sessionStorage.getItem("session_id")) onLogout();
        alert("로그인 해주세요");
        return;
      } else if (res.data.split("|||")[0] === "code0") {
        window.sessionStorage.setItem("roomGrade", res.data.split("|||")[1]);
        console.log(window.sessionStorage.getItem("roomGrade"));
      } else {
        alert("에러발생, 관리자에게 문의하세요");
        return;
      }
    })
    .catch((error) => {
      alert("에러발생, 관리자에게 문의해주세요");
      console.log(error);
      throw new Error(error);
      return;
    });

  formData2.append("totalDate", $totalDate);
  formData2.append("numKids", $numKids);
  formData2.append("numTotal", $numTotal);
  formData2.append("startDate", $startDate);
  formData2.append("endDate", $endDate);
  formData2.append("status", "Paid");
  formData2.append("conMessage", document.getElementById("con_message").value);
  formData2.append("price", document.getElementById("pay_money").innerHTML);
  formData2.append("breakfast", $breakfast_order);
  formData2.append("dinner", $dinner_order);
  formData2.append("username", $name);
  formData2.append("userbirth", $userbirth);
  formData2.append("usernum", $usernum);
  formData2.append("usermail", $usermail);
  formData2.append("roomgrade", window.sessionStorage.getItem("roomGrade"));
  formData2.append("nation", $nation);
  formData2.append("lastidx", window.sessionStorage.getItem("lastidx"));

  await axios
    .post("/assets/php/UPDATE_tblreservation.php", formData2, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      console.log(res);
      if (res.data.split("|||")[0] === "code1") {
        if (window.sessionStorage.getItem("session_id")) onLogout();
        alert("로그인 해주세요");
      } else if (res.data.split("|||")[0] === "code0") {
        alert("예약을 성공했습니다");
        onComplete(res.data.split("|||")[1]);
      } else if(res.data.toString().indexOf('mail()') !== -1){
          alert('예약은 성공했지만 메일서버 문제로 메일을 전송하지 못했습니다')
          onComplete(res.data.split("|||")[1]);
      } else {
        alert("에러발생, 관리자에게 문의하세요");
        return;
      }
    })
    .catch((error) => {
      alert("에러발생, 관리자에게 문의해주세요");
      console.log(error);
      throw new Error(error);
    });
}

const onComplete = async(x) => {

    window.location.href = "/";
    return;

}



export const moneyChange = (e) => {
  e.preventDefault();
  console.log(e.target.value);
  let noFoodMoney = window.sessionStorage.getItem("noFoodMoney");
  let breakfast1p = window.sessionStorage.getItem("breakfast1p");
  let dinner1p = window.sessionStorage.getItem("dinner1p");

  let breakfast_order = document.join_form2.con_breakfast.value;
  let dinner_order = document.join_form2.con_dinner.value;

  if (!breakfast_order) {
    let breakfast_order = 0;
  }
  if (!dinner_order) {
    let dinner_order = 0;
  }
  if (e.target.id === "con_breakfast") {
    let breakfast_order = Number(e.target.value);
    document.getElementById("pay_money").innerHTML =
      Number(noFoodMoney) +
      Number(breakfast1p * breakfast_order) +
      Number(dinner1p * dinner_order);
  } else if (e.target.id === "con_dinner") {
    let dinner_order = Number(e.target.value);
    document.getElementById("pay_money").innerHTML =
      Number(noFoodMoney) +
      Number(breakfast1p * breakfast_order) +
      Number(dinner1p * dinner_order);
  }
  console.log(document.getElementById("pay_money").innerHTML);
}

/*예약확인 페이지, 예약번호 로드*/
export const myRLoader = async() => {
}
export const onConfirmReserve = (e) => {
  e.preventDefault();
	let $reserve_num = document.login_form.form_id2.value;
	let $reserve_mail = document.login_form.form_pass2.value;
  
	let formData = new FormData();
	formData.append("reserve_num", $reserve_num);
	formData.append("reserve_mail", $reserve_mail);
	axios
	  .post("/assets/php/search_my_reservation2.php", formData, {
		headers: {
		  "Content-Type": "multipart/form-data",
		},
	  })
	  .then((res) => {
		console.log(res);
		if (res.data.split("|||")[0] === "code0") {
		  // onComplete($reserve_num);
      window.sessionStorage.setItem('reserve_num',$reserve_num);
      window.location.href = '/confirm'
		} else if (res.data.split("|||")[0] === "code1") {
		  alert("예약번호 혹은 메일이 다릅니다.");
		  return;
		} else {
		  alert("예약번호 혹은 메일이 다릅니다.");
		  return;
		}
	  })
	  .catch((error) => {
		alert("에러발생, 관리자에게 문의해주세요");
		console.log(error);
		throw new Error(error);
	  });
  }



export const myReserveConfirm = (code, mail, e) => {
  e.preventDefault();
  console.log(code);
  console.log(mail);
  document.login_form.form_id2.value = code;
  document.login_form.form_pass2.value = mail;
  onConfirmReserve(e);
}

window.addEventListener("load", function () {
  if (window.localStorage.getItem("location") === "onConfirm") myRLoader();
});
