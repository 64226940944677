import axios from 'axios';
import {onLogout} from '../php/login';
import {boardData,setBoardData} from '../../components/pages/Event'

export const boardView = async(x, e) => {
	e.preventDefault();
	await axios.post(process.env.REACT_APP_URL + '/assets/php/SELECT_young_bor.php', 
		{
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}).then(res => { //응답성공
			console.log(res.data)
			console.log(x);
			for (let i = 0; i < res.data.length; i++) {

				if (res.data[i].YOUNG_bor_idx === x.toString()) {

					window.sessionStorage.setItem('event_title',res.data[i].YOUNG_bor_tit)
					window.sessionStorage.setItem('event_main_img',res.data[i].YOUNG_bor_image)
					window.sessionStorage.setItem('event_writer',res.data[i].YOUNG_bor_name)
					window.sessionStorage.setItem('event_form', res.data[i].YOUNG_bor_idx)
					window.sessionStorage.setItem('event_reg',res.data[i].YOUNG_bor_reg)
					window.sessionStorage.setItem('event_hit',res.data[i].YOUNG_bor_hit)
					window.sessionStorage.setItem('attached',res.data[i].YOUNG_bor_file)
					window.sessionStorage.setItem('event_date_from',res.data[i].YOUNG_bor_from)
					window.sessionStorage.setItem('event_date_to',res.data[i].YOUNG_bor_to)
					window.sessionStorage.setItem('event_main_txt',res.data[i].YOUNG_bor_con)

				}
			}
			window.sessionStorage.setItem('thisEvent', x);
		})
		.catch(error => { //응답실패
			console.log(error)
		})
	window.scrollTo(0, 0);
	window.location.href = '/eventform'
}

export const toIndex = (e) => {
	e.preventDefault();
	window.location.href = '/event'
}



/*이벤트 목록 로드하기*/
let ii = 0;







// window.addEventListener('load', async function () {
// 	if(window.sessionStorage.getItem('session_id')){
// 		await axios.post('http://103.66.189.9:80/assets/js/session_check.php',
// 			 {
// 				headers: {
// 					'Content-Type': 'multipart/form-data'
// 				}
// 			}
// 		).then(res => { //응답성공
// 			console.log(res.data.split('|||')[0])
// 			if(res.data.split('|||')[0] === 'code0'){
// 				return
// 			}else if(res.data.split('|||')[0] === 'code00'){
// 				onLogout();
// 			}


// 		})
// 		.catch(error => { //응답실패
// 			console.log(error)
// 		})

// 	} else {
// 		await axios.post('http://103.66.189.9:80/assets/js/session_check.php',
// 			 {
// 				headers: {
// 					'Content-Type': 'multipart/form-data'
// 				}
// 			}
// 		).then(res => { //응답성공
// 			console.log(res.data.split('|||')[0])
// 			if(res.data.split('|||')[0] === 'code0'){
// 				onLogout();
// 			}else if(res.data.split('|||')[0] === 'code00'){
// 				return
// 			}


// 		})
// 		.catch(error => { //응답실패
// 			console.log(error)
// 		})
// 	}

// 	if (document.getElementById('event') && document.getElementById('event').style.display === 'block') {
// 		await eventLoader();
// 	}

// 	let iii = document.querySelectorAll('input[type=file]').length;
// 	for(let i=0;i<iii;i++){
// 		document.querySelectorAll('input[type=file]')[i].addEventListener('change',function(){
// 			this.parentElement.children[1].innerHTML = this.value;
// 		})
// 	}
	
// });