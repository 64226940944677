import React, {useEffect, useState} from 'react';
import { onLogout } from '../../assets/php/login';
import {onLang,Menu_top_type1,Menu_top_type2,navSPOff} from "../../assets/js/nav"
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link
  } from "react-router-dom";
  
import '../../assets/css/navfoot.css';
import { home,reserve,room1,room2,room3,onsen,kaiseki,intro,event,inquire,business1,business2,login,join,confirm,mypage,search, restaurant } from '../../store/module/nav';

const Nav = ({dispatch, page,setPage,onSetLogin}) => {
	const OnPageMove = (e) => {
		setPage(e);
		window.scrollTo(0,0)
	}

	const [pageReserve, setPageReserve] = useState(false);
	const [pageRoom, setPageRoom] = useState(false);
	const [pageService, setPageService] = useState(false);
	const [pageBusiness, setPageBusiness] = useState(false);
	const [pageRest, setPageRest] = useState(false);
	const onMouseOn = (e)=> {
		let Target = e.target.classList[0];
		if(Target === 'onRoom1' || Target === 'onRoom2' || Target === 'onRoom3') {setPageRoom(true)}
		else if (Target === 'onIntro' || Target === 'onEvent' || Target === 'onInquiry') setPageService(true)
		else if (Target === 'onBusiness' || Target === 'onBusiness2') setPageBusiness(true)
		else if (Target === 'onKaiseki' || Target === 'onRest') setPageRest(true)
		else if (Target === 'onReserve') setPageReserve(true)
	
	}
	const onMouseOff = (e)=> {
		let Target = e.target.classList[0];
		if(Target === 'onRoom1' || Target === 'onRoom2' || Target === 'onRoom3') setPageRoom(false)
		else if (Target === 'onIntro' || Target === 'onEvent' || Target === 'onInquiry') setPageService(false)
		else if (Target === 'onBusiness' || Target === 'onBusiness2') setPageBusiness(false)
		else if (Target === 'onKaiseki' || Target === 'onRest') setPageRest(false)
		else if (Target === 'onReserve') setPageReserve(false)
	}

    return (
        <nav id="nav-placeholder" className={page}>
		<div className="depth0_wrapper">
			<div className="depth0">
				<ul>
					<li>
						<Link to="/reserve" onClick={(e) => {navSPOff();dispatch(reserve());OnPageMove(e.target.classList[0])}} className="onReserve depth1">예약</Link>
					</li>
					<li>
						<Link to="/room1" onClick={(e) => {navSPOff();dispatch(room1());OnPageMove(e.target.classList[0])}} className="onRoom1 depth1">객 실</Link>
						<div className="sub_nav">
							<Link to="/room1" onClick={(e) => {navSPOff();dispatch(room1());OnPageMove(e.target.classList[0])}} className="onRoom1 depth2">스 탠 다 드 룸</Link>
							<Link to="/room2" onClick={(e) => {navSPOff();dispatch(room2());OnPageMove(e.target.classList[0])}} className="onRoom2 depth2">스 페 셜 룸</Link>
							<Link to="/room3" onClick={(e) => {navSPOff();dispatch(room3());OnPageMove(e.target.classList[0])}} className="onRoom3 depth2">패 밀 리 룸</Link>
						</div>
					</li>
					<li>
						<Link to="/onsen" onClick={(e) => {navSPOff();dispatch(onsen());OnPageMove(e.target.classList[0])}} className="onSpa depth1">온천</Link>
					</li>
				</ul>
				<ul>
					<li>
						<Link to="/kaiseki" onClick={(e) => {navSPOff();dispatch(kaiseki());OnPageMove(e.target.classList[0])}} className="onKaiseki depth1">다이닝</Link>
						<div className="sub_nav">
							<Link to="/kaiseki" onClick={(e) => {navSPOff();dispatch(kaiseki());OnPageMove(e.target.classList[0])}} className="onKaiseki depth2">가이세키</Link>
							<Link to="/restaurant" onClick={(e) => {navSPOff();dispatch(restaurant());OnPageMove(e.target.classList[0])}} className="onRest depth2">식당</Link>
						</div>
					</li>
					<li>
						<Link to="/intro" onClick={(e) => {navSPOff();dispatch(intro());OnPageMove(e.target.classList[0])}} className="onIntro depth1">고 객 서 비 스</Link>
						<div className="sub_nav">
							<Link to="/intro" onClick={(e) => {navSPOff();dispatch(intro());OnPageMove(e.target.classList[0])}} className="onIntro depth2">료 칸 소 개</Link>
							<Link to="/event" onClick={(e) => {navSPOff();dispatch(event());OnPageMove(e.target.classList[0])}} className="onEvent depth2">이 벤 트</Link>
							<Link to="/inquire" onClick={(e) => {navSPOff();dispatch(inquire());OnPageMove(e.target.classList[0])}} className="onInquiry depth2">문 의 하 기</Link>
						</div>
					</li>
					<li>
						<Link to="/business1" onClick={(e) => {navSPOff();dispatch(business1());OnPageMove(e.target.classList[0])}} className="onIntro depth1">부 대 시 설</Link>
						<div className="sub_nav">
							<Link to="/business1" onClick={(e) => {navSPOff();dispatch(business1());OnPageMove(e.target.classList[0])}} className="onBusiness depth2">비 지 니 스 센 터</Link>
							<Link to="/business2" onClick={(e) => {navSPOff();dispatch(business2());OnPageMove(e.target.classList[0])}} className="onBusiness2 depth2">미 팅 룸</Link>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div id="pc_nav">
			<button id="nav_button1">&#10005;</button>
			<button id="nav_button2" className="On">&#x2630;</button>

			{
			window.sessionStorage.getItem('session_id') ? <Menu_top_type2 onSetLogin={onSetLogin} setPage={setPage} dispatch={dispatch} /> : <Menu_top_type1 onSetLogin={onSetLogin} setPage={setPage} dispatch={dispatch} />

			}

			<ul id="nav">
				<li>
					<Link to="/reserve" onClick={(e) => {dispatch(reserve());OnPageMove(e.target.classList[0])}} className={pageReserve ? "onReserve depth1 on" : "onReserve depth1"} onMouseOver={onMouseOn} onMouseOut={onMouseOff}>예약</Link>
				</li>
				<li>
					<Link to="/room1" className={pageRoom ? "onRoom1 depth1 on" : "onRoom1 depth1"} onMouseOver={onMouseOn} onMouseOut={onMouseOff} onClick={(e) => {dispatch(room1());OnPageMove(e.target.classList[0])}}>객실</Link>
					<div className={pageRoom ? "sub_nav on" : "sub_nav"}  onMouseOver={onMouseOn} onMouseOut={onMouseOff} >
						<Link to="/room1" className="onRoom1 depth2" onClick={(e) => {dispatch(room1());OnPageMove(e.target.classList[0])}} onMouseOver={onMouseOn} onMouseOut={onMouseOff}>스 탠 다 드 룸</Link>
						<Link to="/room2" className="onRoom2 depth2" onClick={(e) => {dispatch(room2());OnPageMove(e.target.classList[0])}} onMouseOver={onMouseOn} onMouseOut={onMouseOff}>스 페 셜 룸</Link>
						<Link to="/room3" className="onRoom3 depth2" onClick={(e) => {dispatch(room3());OnPageMove(e.target.classList[0])}} onMouseOver={onMouseOn} onMouseOut={onMouseOff}>패 밀 리 룸</Link>
					</div>
				</li>
				<li>
					<Link to="/onsen" onClick={(e) => {dispatch(onsen());OnPageMove(e.target.classList[0])}} className="onSpa depth1">온천</Link>
				</li>
				<li>
					<Link to="/" className="onHome depth1" 
					onClick={(e) => {navSPOff();dispatch(home());OnPageMove(e.target.classList[0])}}
					>KIJITEI <br /> HOEISO</Link>
				</li>
				<li>
					<Link to="/kaiseki" className={pageRest ? "onKaiseki depth1 on" : "onKaiseki depth1"} onMouseOver={onMouseOn} onMouseOut={onMouseOff} onClick={(e) => {dispatch(kaiseki())}}>다이닝</Link>
					<div className="sub_nav" className={pageRest ? "sub_nav on" : "sub_nav"} onMouseOver={onMouseOn} onMouseOut={onMouseOff}>
						<Link to="/kaiseki" className="onKaiseki depth2" onClick={(e) => {dispatch(kaiseki())}} onMouseOver={onMouseOn} onMouseOut={onMouseOff}>가이세키</Link>
						<Link to="/restaurant" className="onRest depth2" onClick={(e) => {dispatch(restaurant())}} onMouseOver={onMouseOn} onMouseOut={onMouseOff}>식당</Link>
					</div>
				</li>
				<li>
					<Link to="/intro" className={pageService ? "onIntro depth1 on" : "onIntro depth1"} onMouseOver={onMouseOn} onMouseOut={onMouseOff} onClick={(e) => {dispatch(home());OnPageMove(e.target.classList[0])}}>
						고객서비스
					</Link>
					<div className="sub_nav" className={pageService ? "sub_nav on" : "sub_nav"} onMouseOver={onMouseOn} onMouseOut={onMouseOff}>
						<Link to="/intro" onClick={(e) => {dispatch(home());OnPageMove(e.target.classList[0])}} className="onIntro depth2"  onMouseOver={onMouseOn} onMouseOut={onMouseOff}>료칸소개</Link>
						<Link to="/event" onClick={(e) => {dispatch(home());OnPageMove(e.target.classList[0])}} className="onEvent depth2" onMouseOver={onMouseOn} onMouseOut={onMouseOff}>이벤트</Link>
						<Link to="/inquire" onClick={(e) => {dispatch(home());OnPageMove(e.target.classList[0])}} className="onInquiry depth2" onMouseOver={onMouseOn} onMouseOut={onMouseOff}>문의하기</Link>
					</div>
				</li>
				<li>
					<Link to="/business1" className={pageBusiness ? "onBusiness depth1 on" : "onBusiness depth1"} onMouseOver={onMouseOn} onMouseOut={onMouseOff} onClick={(e) => {dispatch(home());OnPageMove(e.target.classList[0])}}>
						부대시설
					</Link>
					<div className="sub_nav" className={pageBusiness ? "sub_nav on" : "sub_nav"} onMouseOver={onMouseOn} onMouseOut={onMouseOff}>
						<Link to="/business1" className="onBusiness depth2" onClick={(e) => {dispatch(home());OnPageMove(e.target.classList[0])}} onMouseOver={onMouseOn} onMouseOut={onMouseOff}>비지니스 센터</Link>
						<Link to="/business2" className="onBusiness2 depth2" onClick={(e) => {dispatch(home());OnPageMove(e.target.classList[0])}} onMouseOver={onMouseOn} onMouseOut={onMouseOff}>미팅룸</Link>
					</div>
				</li>
			</ul>
		</div>
	</nav>
    );
};
export default Nav;