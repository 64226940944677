import React from 'react';
import {onContact} from '../../assets/php/contact'
import { home,reserve,room1,room2,room3,onsen,kaiseki,intro,event,inquire,business1,business2,login,join,confirm,mypage,search, restaurant } from '../../store/module/nav';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link
  } from "react-router-dom";

const Inquire = ({setPage,dispatch}) => {

	const OnPageMove = (e) => {
		setPage(e)
	}
	const onActive = (e) => {
		e.target.parentElement.classList.add('active')
	}
	const offActive = (e) => {
		e.target.parentElement.classList.remove('active')
	}

	return (
		<div id="inquiry" className="roomContent inquiry">
		<header className="header2 nav_eraser">
			<div>
			</div>
		</header>
		<div className="content_area">
			<aside className="roomMenu">
				<div>
					<div>
						<h2>고객서비스</h2>
					</div>
					<div>
						<h3 onMouseOver={onActive} onMouseLeave={offActive}><Link to="/intro" className="onIntro depth2" onClick={(e) => {dispatch(intro());OnPageMove(e.target.classList[0])}}>료칸소개</Link></h3>
						<h3 onMouseOver={onActive} onMouseLeave={offActive}><Link to="/event" className="onEvent depth2" onClick={(e) => {dispatch(event());OnPageMove(e.target.classList[0])}}>이벤트</Link></h3>
						<h3 onMouseOver={onActive} onMouseLeave={offActive}><Link to="/inquire" className="onInquiry depth2" onClick={(e) => {dispatch(inquire());OnPageMove(e.target.classList[0])}}>문의하기</Link></h3>
					</div>
				</div>
			</aside>
			<div>
				<h1><img src={require("../../assets/img/cs/inquiry.png")} alt="제목이미지" className="subject_image" />
				</h1>
				<section className="section1">
					<div>
						<div className="sec1pic">
							<img src={require("../../assets/img/cs/inq.jpg")} />
						</div>
						<div className="sec1text">
						</div>
					</div>
				</section>
				<div className="height40"></div>

				<section className="section_form">
					<form onSubmit={onContact} name="contact" id="contact" method="post">
						<div className="wrapper">
							<div id="inquiry_type">
								<div>
									<h5 className="yaya"><label htmlFor="sources">문의유형</label></h5>
									<select name="sources" id="sources" className="custom-select sources" placeholder="문의">
										<option value="p1">객실 문의</option>
										<option value="p2">다이닝 문의 </option>
										<option value="p3">부대시설 문의</option>
										<option value="p4">온천 문의</option>
										<option value="p5">기타 문의</option>
									</select>
								</div>
							</div>
							<div id="jae">
								<div>
									<h5 className="mgtMo50"><label htmlFor="form_sub">제목</label></h5>
									<input name="form_sub" type="text" id="form_sub" placeholder="예) 예약 문의" required />
								</div>
							</div>
						</div>
						<div className="none_flex">
							<h5><label htmlFor="message">문의사항</label></h5>
							<textarea name="message" id="message" placeholder="자세한 문의 사항을 적어주시기 바랍니다." rows="5" required></textarea>
						</div>
						<div className="wrapper">
							<div>
								<h5><label htmlFor="form_name">성함</label></h5>
								<input name="form_name" type="text" id="form_name" required />
							</div>
							<div className="mgtMo50">
								<h5><label htmlFor="phone">전화번호</label></h5>
								<input name="phone" type="tel" id="phone" placeholder="예) 010-1234-5678" required />
							</div>
						</div>
						<div className="wrapper">
							<div>
								<h5><label htmlFor="form_email">이메일</label></h5>
								<input name="form_email" type="email" placeholder="예) help@gmail.com" id="form_email" required />
							</div>
							<div>

							</div>
						</div>



						<div className="none_flex">
							<h5 style={{width:140}}><label htmlFor="message">필수 동의사항</label></h5>
							<div className="checkbox" style={{marginTop:20}}>
								<h3><input type="checkbox" name="checker1" id="checker1" required="" style={{width:16,height:16}} /><label htmlFor="checker1">소비자 권익보호에 관한 사항</label></h3>
								<p>방문자님께서는 아래의 동의를 거부할 수 있으며, 이에 동의하지 않으실 경우 본 메일 송신 서비스 사용이 제한될 수 있습니다.</p>
								<h3 className="mgt10"><input type="checkbox" name="checker2" id="checker2" required="" style={{width:16,height:16}} /><label htmlFor="checker2">개인(신용)정보의 수집·이용에 관한 사항 </label></h3>
								<p>개인(신용)정보의 수집·이용 목적 : 본인확인 및 문의 사항에 대한 답변<br />
									수집하는 개인정보 : 이름, 전화번호, 이메일, 회사, 직책
								</p>
							</div>
						</div>
						<div id="btn_submit"> <button type="submit"><img src={require("../../assets/img/cs/inquiry2.png")} /></button></div>
					</form>
				</section>
			</div>
		</div>
	</div>
	);
};

export default Inquire;