import React, { useEffect, useState } from 'react';
import {onConfirm} from '../../assets/php/reserve'
import {detailRoomOpen} from '../../assets/js/main'


export const Room_standard = () => {
	return (
		<table>
			<caption></caption>
			<colgroup>
				<col style={{width: '35%'}} />
				<col style={{width: '15%'}} />
				<col style={{width: '5%'}} />
				<col style={{width: '15%'}} />
				<col style={{width: 'auto'}} />
			</colgroup>
			<thead>
				<tr>
					<th>사진</th>
					<th>등급</th>
					<th>정원</th>
					<th>가격</th>
					<th>설명</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td><img src="https://hoeiso.gloomy-store.com/ry_admin/room/101/@profile.jpg" alt="standard" /></td>
					<td>STANDARD</td>
					<td>3</td>
					<td>60,000<br />
					<span>(휴일 90,000)</span></td>
					<td>STANDARD ROOM for 2 PEOPLE, MAX 3</td>
				</tr>
			</tbody>
		</table>
	);
};


export const Room_great = () => {
	return (
		<table>
			<caption></caption>
			<colgroup>
				<col style={{width: '35%'}} />
				<col style={{width: '15%'}} />
				<col style={{width: '5%'}} />
				<col style={{width: '15%'}} />
				<col style={{width: 'auto'}} />
			</colgroup>
			<thead>
				<tr>
					<th>사진</th>
					<th>등급</th>
					<th>정원</th>
					<th>가격</th>
					<th>설명</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td><img src="https://hoeiso.gloomy-store.com/ry_admin/room/201/@profile.jpg" alt="great" /></td>
					<td>GREAT</td>
					<td>4</td>
					<td>100,000<br />
					<span>(휴일 150,000)</span></td>
					<td>GREAT ROOM for 3 PEOPLE, MAX 4</td>
				</tr>
			</tbody>
		</table>
	);
};



export const Room_special = () => {
	return (
		<table>
			<caption></caption>
			<colgroup>
				<col style={{width: '35%'}} />
				<col style={{width: '15%'}} />
				<col style={{width: '5%'}} />
				<col style={{width: '15%'}} />
				<col style={{width: 'auto'}} />
			</colgroup>
			<thead>
				<tr>
					<th>사진</th>
					<th>등급</th>
					<th>정원</th>
					<th>가격</th>
					<th>설명</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td><img src="https://hoeiso.gloomy-store.com/ry_admin/room/301/@profile.jpg" alt="standard" /></td>
					<td>SPECIAL</td>
					<td>6</td>
					<td>150,000<br />
					<span>(휴일 225,000)</span></td>
					<td>SPECIAL ROOM for 4 PEOPLE, MAX 6</td>
				</tr>
			</tbody>
		</table>
	);
};




const Search = () => {

	const [standard,setStandard] = useState(false)
	const [great,setGreat] = useState(false)
	const [family,setFamily] = useState(false)

	useEffect(()=> {
		const total_date = window.sessionStorage.getItem('total_date').split(',')
		const weekday = window.sessionStorage.getItem('weekday')
		const weekend = window.sessionStorage.getItem('weekend')
		const data = JSON.parse(window.sessionStorage.getItem('data'))
		console.log(data)
		console.log(window.sessionStorage.getItem('total_date'))

		document.getElementById('room_standard').innerHTML = '';
		document.getElementById('room1').style.display = 'none';
		document.getElementById('room_great').innerHTML = '';
		document.getElementById('room2').style.display = 'none';
		document.getElementById('room_special').innerHTML = '';
		document.getElementById('room3').style.display = 'none';

		let bin_room = [];
		for (let i = 0; i < data.length; i++) {
			total_date.filter(x => data[i].rooms_reservation.split(',').includes(x));
			if (total_date.filter(x => data[i].rooms_reservation.split(',').includes(x)).length === 0) {
				bin_room.push(data[i].rooms_room);
				if (data[i].rooms_grade === '1') {
					setStandard(true)
					document.getElementById('room1').style.display = 'block';
				} else if (data[i].rooms_grade === '2') {
					setGreat(true)
					document.getElementById('room2').style.display = 'block';
				} else if (data[i].rooms_grade === '3') {
					setFamily(true)
					document.getElementById('room3').style.display = 'block';
				}
			}
		}
		console.log(bin_room);
		window.sessionStorage.setItem('bin_room', bin_room);


	},[])
	return (
		<div id="roomSelect">
		<div className="background_img">
			<h1 style={{paddingTop:100}}><img src={require("../../assets/img/room/title1.png")} alt="제목이미지" className="subject_image" /></h1>
			<div className="rooms mgt100">
				<a id="room_standard" className="room_box" href="#none" onClick={(e) => onConfirm('room_standard',e)}>
					{ standard ? <Room_standard /> : null}
				</a>
				<a href="#none" className="details" id="room1" onClick={detailRoomOpen}>방 자세히 보기</a>
			</div>

			<div className="rooms mgt100">
				<a id="room_great" className="room_box" href="#none" onClick={(e) => onConfirm('room_great',e)}>
				{ great ? <Room_great /> : null}
				</a>
				<a href="#none" className="details" id="room2" onClick={detailRoomOpen}>방 자세히 보기</a>
			</div>

			<div className="rooms mgt100">
				<a id="room_special" className="room_box" href="#none" onClick={(e) => onConfirm('room_special',e)}>
				{ family ? <Room_special /> : null}
				</a>
				<a href="#none" className="details" id="room3" onClick={detailRoomOpen}>방 자세히 보기</a>
			</div>
		</div>

		<div className="table_wrapper" id="room_wrapper">

		</div>
	</div>
	);
};

export default Search;