import React from 'react';

const Footer = () => {
    return (
        <footer id="foot-placeholder">
		<div id="footer_top">
			<a href="https://explore-hakone.com/"><img src={require("../../assets/img/footerlogo1.png")} alt="풋터로고1" /></a>
			<a href="https://www.hoeiso.jp/akatiyan.html"><img src={require("../../assets/img/footerlogo2.png")} alt="풋터로고2" /></a>
			<a href="https://cake.jp/inn/cart/1/?r=1"><img src={require("../../assets/img/footerlogo3.png")} alt="풋터로고3" /></a>
		</div>
		<div id="footer">
			<div>
				<img src={require("../../assets/img/footert_logo.png")} alt="풋터로고" />
			</div>
			<div>
				<div>
					<a href="#none" className="onLogin">로그인</a>
					<a href="#none" className="onJoin">회원가입</a>
					<a href="#none">APP다운</a>
					<a href="#none" className="onIntro">호에이소호텔소개</a>
					<a href="https://www.youtube.com/results?search_query=hoeiso" target="_blank">영상</a>
					<a href="#none" className="onEvent">프로모션</a>
					<a href="#none" className="onInquiry">고객문의</a>
				</div>
				<div>
					<div>
						<p>ONSEN RYOKAN KIJITE HOEISO<br />227 YUMOTOCHAY, HAKONE-MACHI, ASHIGARASHIMO-GUN, KANAGAWA 250-0312, JAPAN<br />Phone + 81-460-85-5763 <br />Fax + 81-460-85-6760<br />[MAIL] kiji@hoeiso.jp</p>
					</div>
					<div>
						<a href="https://facebook.com" target="_blank"><img src={require("../../assets/img/f1.png")} alt="페이스북" /></a>
						<a href="https://www.tripadvisor.co.kr/Hotel_Review-g298171-d965986-Reviews-Kijitei_Hoeiso-Hakone_machi_Ashigarashimo_gun_Kanagawa_Prefecture_Kanto.html" target="_blank"><img src={require("../../assets/img/f2.png")} alt="트립" /></a>
						<a href="https://facebook.com" target="_blank"><img src={require("../../assets/img/f3.png")} alt="인스타" /></a>
						<a href="https://www.youtube.com/?gl=KR" target="_blank"><img src={require("../../assets/img/f4.png")} alt="유튜브" /></a>
					</div>
				</div>
				<div>
					<a href="#none">호에이소 약관</a>
					<a href="#none">개인정보처리방침</a>
					<a href="#none">영상정보처리기운영 관리방침</a>
					<a href="#none">호에이소 회원약관</a>
				</div>
			</div>
		</div>
	</footer>
    );
};

export default Footer;