import React, { useEffect, useRef } from 'react';
import { popupImg,fadeOut } from '../../assets/js/room';

const Spa = ({page}) => {
	const cnt4 = useRef();
	const imgRef = useRef([]);
	useEffect(() => {
	  /*갤러리 작은 이미지 클릭시 큰 이미지가 떠오름*/
		for (let ii = 0; ii < cnt4.current.children.length; ii++) {
			let imgClassParent = cnt4.current.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;

			let imgClassNum = cnt4.current.children[ii].className.split('cnt4_')[1];
			cnt4.current.children[ii].addEventListener('click', function () {
				popupImg(imgClassNum, imgClassParent);
			})
		}
		/*큰 이미지를 누르면 사라짐*/
		for (let i = 0; i < imgRef.current.length; i++) {
			imgRef.current[i].addEventListener('click', function () {
				let $this = this;
				fadeOut($this)
				setTimeout(function () {
					$this.style.display = 'none';
				}, 800)
			})
		}
	
	  return () => {

	  };
	}, [page]);
	
    return (
        <div id="spaContent" className="roomContent">
		<div className="roomTop nav_eraser">
			<img src={require("../../assets/img/room/spa_top.jpg")} alt="메뉴아래 이미지" />
		</div>
		<div className="content_area">
			<aside className="roomMenu">
				<div>
					<div>
						<h2>온천</h2>
					</div>
					<div>
						<h3><a href="#none" className="onSpa">
								온천 소개
							</a></h3>
					</div>
				</div>
			</aside>
			<div>
				<h1><img src={require("../../assets/img/room/title4.png")} alt="제목이미지" className="subject_image" /></h1>
				<div className="spaArea">
					<div>
						<div>
							<img src={require("../../assets/img/spa/spa1.jpg")} alt="온천이미지" />
						</div>
						<p>노천탕은 자연에 둘러싸여 있습니다.<br /> 맑은 하천 옆에 위치한 하코네 유모토 지역은 신화적인 천국처럼 느껴집니다.<br /><br />
							사계절의 아름다움을 보여주는 온천은 하코네 지역에서 가장 높은 수준의 알칼리성 중 하나를 자랑하며 피부건강에 매우 좋습니다.
						</p>
					</div>
					<div>
						<p className="spaNone onlyPC">온천은 원천에서 흘러 나오는 순수한 온천수로 끊임없이 채워져 넘쳐 흐르는 물이 강으로 흘러 들어갑니다.<br />
							자연 환경을 보존하기 위해 노천탕에는 샤워 시설, 비누 등이 없습니다.<br /><br />
							웅장한 자연 경관으로 둘러싸인 전통적인 온천탕에서 여행의 피로를 푸세요.<br />
						</p>
						<div>
							<img src={require("../../assets/img/spa/spa2.jpg")} alt="온천이미지" />
						</div>
						<p className="spaNone onlySP">온천은 원천에서 흘러 나오는 순수한 온천수로 끊임없이 채워져 넘쳐 흐르는 물이 강으로 흘러 들어갑니다.<br />
							자연 환경을 보존하기 위해 노천탕에는 샤워 시설, 비누 등이 없습니다.<br /><br />
							웅장한 자연 경관으로 둘러싸인 전통적인 온천탕에서 여행의 피로를 푸세요.<br />
						</p>
					</div>
				</div>
				<div className="roomTxt">
					<div className="roomCnt">
						<div>
							<h2>객실개요</h2>
							<div className="cnt1">
								<div>
									<h3>위치</h3>
									<p>main tower</p>
								</div>
								<div>
									<h3>수용인원</h3>
									<p>2~3명</p>
								</div>
								<div>
									<h3>객실크기</h3>
									<p> 26.7m ~30.7m</p>
								</div>
								<div>
									<h3>체크인/체크아웃</h3>
									<p>15:00/12:00</p>
								</div>
								<div>
									<h3>전망</h3>
									<p>시티뷰</p>
								</div>
							</div>
						</div>
						<div className="cnt2">
							<h2>특별서비스</h2>
							<ul>
								<li>산을 감상할 수 있는 객실</li>
								<li>해온(he:on) 베딩 시스템: 최상의 숙면을 위한 침대 및 침구류</li>
								<li>맞춤형 베개 제공</li>
								<li>전 객실 초고속 무료 인터넷 (유선, 와이파이)</li>
								<li>턴다운 서비스 제공</li>
							</ul>
						</div>
						<div>
							<h2>어메니티</h2>
							<div className="cnt3">
								<div>
									<h3>일반</h3>
									<p>37"LCD tv /손전등 /슬리퍼<br /> 전화기 / 티포트 / 금고<br /> 구둣주걱 / 구두클리너 / 미니바</p>
								</div>
								<div>
									<h3>욕실</h3>
									<p>1회용 칫솔 및 치약 / 면도기 / 목욕가운<br /> 비데 / 헤어드라이어 / 욕실용품 </p>
								</div>
								<div>
									<h3>기타</h3>
									<p> 케이블 위성tv 채널 <br />무료 생수 1일 2병 / 보이스 메일서비스<br /> 무료 차(tea) 서비스 </p>
								</div>
							</div>
						</div>
						<div>
							<h2 className="gallery_tlt"><img src={require("../../assets/img/room/title7.png")} alt="갤러리" className="subject_image" /></h2>
							<div className="cnt4" ref={cnt4}>
								<div className="cnt4_1">
									<img src={require("../../assets/img/room/spa1.jpg")} alt="갤러리이미지" />
								</div>
								<div className="cnt4_2">
									<img src={require("../../assets/img/room/spa4.jpg")} alt="갤러리이미지" />
								</div>
								<div className="cnt4_3">
									<img src={require("../../assets/img/room/spa5.jpg")} alt="갤러리이미지" />
								</div>
								<div className="cnt4_4">
									<img src={require("../../assets/img/room/spa6.jpg")} alt="갤러리이미지" />
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
		<section className="popupImg rmPop1" ref={e => (imgRef.current[0] = e)}>
			<img src={require("../../assets/img/room/spa1.jpg")} alt="갤러리이미지" />
		</section>
		<section className="popupImg rmPop2" ref={e => (imgRef.current[1] = e)}>
			<img src={require("../../assets/img/room/spa1.jpg")} alt="갤러리이미지" />
		</section>
		<section className="popupImg rmPop3" ref={e => (imgRef.current[2] = e)}>
			<img src={require("../../assets/img/room/spa1.jpg")} alt="갤러리이미지" />
		</section>
		<section className="popupImg rmPop4" ref={e => (imgRef.current[3] = e)}>
			<img src={require("../../assets/img/room/spa1.jpg")} alt="갤러리이미지" />
		</section>
	</div>
    );
};

export default Spa;