import React from 'react';
import { useDispatch } from 'react-redux';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link
  } from "react-router-dom";
import { onLogout } from "../php/login";
// import {slide2} from '../js/main'
import { home,reserve,room1,room2,room3,onsen,kaiseki,intro,event,inquire,business1,business2,login,join,confirm,mypage,search, restaurant,login2 } from '../../store/module/nav';



export const Menu_top_type1 = ({dispatch}) => {
	return (
		<div id="menu_top" className="">
		<div>
			<div>
				<Link to="/login" onClick={(e) => {dispatch(login());}} className="onLogin">로그인</Link>
				<Link to="/join" className="onJoin" onClick={(e) => {dispatch(join());}}>회원가입</Link>
				<Link to="/login:login2" onClick={(e) => {dispatch(login2());}} className="onLogin2">예약확인</Link>
				<a href="#none" onClick={onLang}><img src={require("../../assets/img/lan.png")} alt="언어" />언어</a>
				<div className="lan">
					<ul>
						<li>
							<a href="/ry/index.html">한국어</a>
							<a href="/ryeng/index.html">English</a>
							<a href="/ryjap/index.html">日本語</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
		</div>
	);
};

export const Menu_top_type2 = ({onSetLogin,setPage,dispatch}) => {

	return (
		<div id="menu_top" className="">
		<div>
			<div>
				<Link to="/mypage" className="onEditInfo">마이페이지</Link>
				<a href="#none" onClick={onLogout} className="onJoin">로그아웃</a>
				<Link to="/login:login2" onClick={(e) => {dispatch(login2());}} className="onLogin2">예약확인</Link>
				<a href="#none" onClick={onLang}><img src={require("../../assets/img/lan.png")} alt="언어" />언어</a>
				<div className="lan">
					<ul>
						<li>
							<a href="/ry/index.html">한국어</a>
							<a href="/ryeng/index.html">English</a>
							<a href="/ryjap/index.html">日本語</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
		</div>
	);
};



function navPcOff() {
	for (let i = 0; i < document.getElementById('nav').children.length; i++) {
		document.getElementById('nav').children[i].children[0].classList.remove("On");
		if (document.getElementById('nav').children[i].children[0].nextElementSibling) document.getElementById('nav').children[i].children[0].nextElementSibling.classList.remove("On");
	}
}

export const navSPOff = () => {
	document.getElementsByClassName('depth0_wrapper')[0].classList.remove('On')
	document.getElementById('nav_button1').classList.remove("On")
	document.getElementById('nav_button2').classList.add("On")
}

window.onload = function () {




	const menu_top = document.getElementById('menu_top');
	// if(window.sessionStorage.getItem('session_id')&&window.sessionStorage.getItem('session_hash')){
	// 	menu_top.classList.add('menu_top_type2');
	// 	menu_top.innerHTML = menu_top_type2;
	// } else {
	// 	menu_top.classList.remove('menu_top_type2');
	// 	menu_top.innerHTML = menu_top_type1;
	// }
	
	// PC버전 네비 서브메뉴 나오게 하기 , 사라지게 하기
	// for (let i = 0; i < document.getElementById('nav').children.length; i++) {
	// 	document.getElementById('nav').children[i].children[0].addEventListener("mouseover", function () {
	// 		for (let ii = 0; ii < document.getElementById('nav').children.length; ii++) {
	// 			document.getElementById('nav').children[ii].children[0].classList.remove("On");
	// 			if (document.getElementById('nav').children[ii].children[0].nextElementSibling) document.getElementById('nav').children[ii].children[0].nextElementSibling.classList.remove("On");
	// 		}
	// 		this.classList.add("On")
	// 		if (this.nextElementSibling) this.nextElementSibling.classList.add("On");
	// 	})
	// }
	// 슬라이드쇼에 들어오면 모든 on 해제
	if(document.getElementsByTagName('div')[0]){
	for(let i=0;i<document.getElementsByTagName('div').length;i++){
		document.getElementsByTagName('div')[i].addEventListener("mouseover", function () {
			navPcOff();
		})
	}
}
	// document.getElementById('slider').addEventListener("mouseover", function () {
	// 	navPcOff();
	// })
	// img에 들어오면 모든 on 해제
	for (let j = 0; j < document.getElementsByClassName('nav_eraser').length; j++) {
		document.getElementsByClassName('nav_eraser')[j].addEventListener("mouseover", function () {
			navPcOff();
		})
	}
	document.getElementById('nav_button2').addEventListener("click", function () {
		this.classList.remove("On");
		document.getElementById('nav_button1').classList.add("On")
		document.getElementsByClassName('depth0_wrapper')[0].classList.add("On");
	})
	document.getElementById('nav_button1').addEventListener("click", function () {
		this.classList.remove("On");
		document.getElementById('nav_button2').classList.add("On")
		document.getElementsByClassName('depth0_wrapper')[0].classList.remove("On");
	})
	console.log('nav')
}

function onLang(e) {
	e.preventDefault();
	if (document.getElementsByClassName('lan')[0].classList.contains("On")) {
		document.getElementsByClassName('lan')[0].classList.remove("On")
	} else document.getElementsByClassName('lan')[0].classList.add("On")
}
export {onLang}
