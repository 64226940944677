import axios from "axios";

export const onContact = (e) => {
	e.preventDefault();
	const form_sub = document.contact.form_sub.value;
	const message = document.contact.message.value;
	const form_name = document.contact.form_name.value;
	const phone = document.contact.phone.value;
	const form_email = document.contact.form_email.value;
	
	const checker1 = document.contact.checker1.checked;
	const checker2 = document.contact.checker2.checked;
	if(!checker1 || !checker2){
		return alert('체크박스에 모두 동의 해주셔야 진행이 가능합니다')
	}
	
	let val = document.getElementById('sources');
	let contact_type = val.options[val.selectedIndex].value;
	
	let formData = new FormData();
	formData.append('form_sub', form_sub);
	formData.append('message', message);
	formData.append('form_name', form_name);
	formData.append('phone', phone);
	formData.append('form_email', form_email);
	formData.append('contact_type', contact_type);

	axios.post(process.env.REACT_APP_URL + '/assets/php/contact_sys.php',
		formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}
	).then((res) => {
		console.log(res);
		if(res.data.split('|||')[0] === 'code0') {
			alert('문의내용이 무사히 전달되었습니다.\n빠른 시일 내에 답변 드리겠습니다.')
		}
		else if(res.data.split('|||')[0] === 'code1'){
			alert('데이터가 부족하여 메일을 전송하지 못했습니다.')
		} else {
			alert('에러발생!')
		}
		
	}).catch(error => {
		console.log(error);
		throw new Error(error);
	});
}
