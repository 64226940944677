import React, { useEffect, useRef, useState } from 'react';
import {onConfirmReserve} from '../../assets/php/reserve'
import {onFindId,onFindPw} from '../../assets/php/find'


const data = new FormData();

const Find = ({setLogin}) => {

	const reservations = useRef(null);
	const zeroCheck = useRef();
	const data = new FormData();
	const [formData,setFormData] = useState(data);
	const onSetFormData = (e) => {
		if(e.target.name === 'form_id') data.append('form_id',e.target.value)
		else if(e.target.name === 'form_pass') data.append('form_pass',e.target.value)
	}

	
	return (


		<div id="find" className="roomContent login nav_eraser">
		<header className="header2 nav_eraser">
			<div>
			</div>
		</header>
		<div className="content_area">
			<aside className="roomMenu">
				<div>
					<div>
						<h2>고객서비스</h2>
					</div>
					<div>
						<h3><a href="#none" className="onIntro">
								로그인
							</a></h3>
						<h3><a href="#none" className="onEvent">
								회원가입
							</a></h3>
						<h3><a href="#none" className="onInquiry">
								예약확인
							</a></h3>
					</div>
				</div>
			</aside>
			<div>
				<h1 className="title1">아이디 찾기</h1>
				<div className="height40"></div>
				<div className="sec1text title">
				</div>
				<form className="find_form" name="find_form" onSubmit={onFindId} method="post">
					<div>
						<div className="id_pass ">
							<input type="text" name="form_birth" placeholder="생년월일 8자리를 입력해주세요" required />
							<input type="text" name="form_mail" placeholder="이메일을 입력해주세요" required />
                            <input type="text" name="form_name" placeholder="한글 이름을 입력해주세요(성 제외)" required />
						</div>

					</div>
					<div>
					<button type="submit" className="">아이디 찾기</button>
					</div>
				</form>
				<div className="alert">
					<p>이메일 연락처 등의 정보가 변경되면 웹사이트에서 회원정보를 수정해주시기 바랍니다.</p>
				</div>
			</div>
            <div>
				<h1 className="title1">비밀번호 찾기</h1>
				<div className="height40"></div>
				<div className="sec1text title">
				</div>
				<form className="find_form" name="find_form2" onSubmit={onFindPw} method="post">
					<div>
                    <div className="id_pass ">
							<input type="text" name="form_id" placeholder="아이디를 입력해주세요" required />
							<input type="text" name="form_mail2" placeholder="이메일을 입력해주세요" required />
                            <input type="text" name="form_birth2" placeholder="생년월일 8자리를 입력해주세요" required />
						</div>
					</div>
					<div>
					<button type="submit" className="">비밀번호 찾기</button>
					</div>
				</form>
				<div className="alert">
					<p>이메일 연락처 등의 정보가 변경되면 웹사이트에서 회원정보를 수정해주시기 바랍니다.</p>
				</div>
			</div>

		</div>
	</div>

	);
};

export default Find;