import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { dateChanger,dateLoader,whenPageLoad  } from "./pages/Calendar";
import { boardView } from "../assets/js/event";
import { onSearch } from "../assets/php/search_room";
import {
  nothing,
  mainTopHeight,
  fadeInOn,
  onlyOneRoom,
  slide2,
  fadeIn
} from "../assets/js/main";
import Calendar from "./pages/Calendar";

/*js*/
const imgHeight = () => {
  document.getElementById("mainImg").offsetHeight();
};


let session = window.sessionStorage;

function fadeOut(target) {
	let onOpacity = Number(target.style.opacity);
	if (!onOpacity) onOpacity = 1;
	let onTimer = 800;
	setInterval(function () {
		if (onOpacity > 0) {
			onOpacity = onOpacity - 0.01;
			if (onOpacity < 0.001) onOpacity = 0;
			target.style.opacity = onOpacity;
		} else return
	}, (onTimer / 100))
}

const Main = () => {

  const [thisMonth,setThisMonth] = useState(1)

const [openCalendar,setOpenCalendar] = useState(null);
const onCalendar = () => {
  setOpenCalendar(!openCalendar)
}

const swiperLeft = useRef(null);
const swiperFade = useRef(null);
const timeout = useRef(null);

/*슬라이드쇼 글자 이동*/
useEffect(() => {
  setInterval(
    () => {
      if(swiperLeft.current){
        swiperLeft.current.style = 'left: -100%; transition: all 0.8s ease 0s;'
        setTimeout(function () {
          if(swiperLeft.current){
          swiperLeft.current.appendChild(swiperLeft.current.children[0]);
        }
      }, 800);
        setTimeout(function () {
          if(swiperLeft.current){
          swiperLeft.current.style.transition = "0s";
          swiperLeft.current.style.left = "0";
          }
        }, 800);
        swiperLeft.current.style.transition = "0.8s";
      }
    },
    2500);
  timeout.current = setTimeout(() => {
    clearInterval(swiperLeft);
  }, 2500);
  return () => {
    clearInterval(swiperLeft);
    clearTimeout(timeout.current);
  }
}, []);

/*슬라이드쇼 이미지 페이드*/
useEffect(() => {
  setInterval(
    () => {
      if(swiperFade.current){
        fadeOut(swiperFade.current.children[0])
        setTimeout(function () {
          if(swiperFade.current){
          swiperFade.current.appendChild(swiperFade.current.children[0]);
          swiperFade.current.children[1].style.opacity = 1;
        }
      }, 800)
      }
    },
    2500);
  timeout.current = setTimeout(() => {
    clearInterval(swiperFade);
  }, 2500);
  return () => {
    clearInterval(swiperFade);
    clearTimeout(timeout.current);
  }
}, []);


const [ii,setii] = useState(0);
const [thisCalendar,setThisCalendar] = useState({});
const [year,setYear] = useState();
const [month,setMonth] = useState();
/*달력 오픈되면 모조리 작동됨*/
useEffect(() => {
		
  const monthNames = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  let $day = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const dates = document.getElementById('dates_left')
  const year_left = document.getElementById('year_left')
  const month_left = document.getElementById('month_left')

  let calendar_month = [];
  let calendar_day = [];
  let calendar_last_date = [];

  let today_origin = new Date();
  /*todayKor = 한국 표준시*/
  let today_hour_origin = today_origin.getTime() + (today_origin.getTimezoneOffset() * 60 * 1000) + (9 * 60 * 60 * 1000);
  let todayKor_origin = new Date(today_hour_origin);
  console.log(todayKor_origin);
  let today_year_origin = Number(todayKor_origin.toString().split(' ')[3]); /*연*/
  let today_month_origin = monthNames.indexOf(todayKor_origin.toString().split(' ')[1]); /*월*/
  let today_date_origin = Number(todayKor_origin.toString().split(' ')[2]); /*일*/
  let today_day_origin = todayKor_origin.toString().split(' ')[0]; /*요일*/

let original_date = today_year_origin+'-'+today_month_origin+'-'+today_date_origin;
window.sessionStorage.setItem('original_date',original_date)
window.sessionStorage.setItem('today_date_origin',today_date_origin)
  console.log(today_month_origin)
  setThisMonth(today_month_origin)
  
  /* calendar_month에 월 채워넣기 */
  for (let i =0;i<12;i++){
    if(i===0){
      calendar_month[i] = today_month_origin;
    } else {
      if(calendar_month[i-1] === 12){
        calendar_month[i] = 1
      } else {
        calendar_month[i] = calendar_month[i-1] + 1;
      }
    }
  }

  /* calendar_month에 X년 1일 채워넣기 - 예) 2022-4-1 */
  let this_year = today_year_origin;
  for (let i =0;i<12;i++){
    if(i===0){
      calendar_month[i] = this_year+'-'+calendar_month[i]+'-1';
    } else {
      if(calendar_month[i] === 1){
        this_year = this_year + 1;
        calendar_month[i] = this_year+'-'+calendar_month[i]+'-1';
      } else {
        calendar_month[i] = this_year+'-'+calendar_month[i]+'-1';
      }
    }
  }
  console.log(calendar_month)
  setYear(calendar_month[ii].split('-')[0])
  setMonth(calendar_month[ii].split('-')[1])

  for (let i =0;i<12;i++){
    calendar_day[i] = new Date(calendar_month[i]).getDay();
  }
  console.log(calendar_day)

  for (let i =0;i<12;i++){
    calendar_last_date[i] = Number(new Date(calendar_month[i].split('-')[0],calendar_month[i].split('-')[1],0).toString().split(' ')[2]);
  }
  console.log(calendar_last_date)

  let calendars = [];
    for (let i =0;i<12;i++){
      if(i===0){
      calendars[0] = [];
    }else {
      calendars[i] = [];
    }
  }
  console.log(calendars)
  for (let i =0;i<12;i++){
    for(let ii=0;ii<calendar_day[i];ii++){
      calendars[i][ii] = 0;
    }
    for(let ii=calendar_day[i]-1;ii<calendar_day[i] + calendar_last_date[i];ii++){
      calendars[i][ii] = ii - calendar_day[i]+1;
    }
  }
  console.log(calendars)

  setThisCalendar(calendars[ii])
  console.log(calendars[ii])
  
  return () => {

  }
}, [setThisMonth,ii,openCalendar]);

useEffect(()=> {
  window.sessionStorage.removeItem('startDate')
  window.sessionStorage.removeItem('endDate')

},[])


  return (
    <main id="main_layout">
      <div id="slide" style={{ height: imgHeight + "px" }} ref={swiperFade}>
        <img src={require("../assets/img/mainimg.jpg")} alt="3" id="mainImg" />
        <img src={require("../assets/img/mainimg1.jpg")} alt="2" />
        <img src={require("../assets/img/mainimg2.jpg")} alt="1" />
      </div>
      <div className="swiper">
        <div className="swiper-wrapper" id="swiper-wrapper" ref={swiperLeft}>
          <div className="swiper-slide" id="1">
            <a href="#none" className="onHome depth1">
              <img
                src={require("../assets/img/slide1.png")}
                alt="슬라이드1"
                className="onHome"
              />
            </a>
          </div>
          <div className="swiper-slide" id="2">
            <Link to="/onsen" className="onSpa depth1">
              <img
                src={require("../assets/img/slide2.png")}
                className="onSpa"
              />
            </Link>
          </div>
          <div className="swiper-slide" id="3">
          <Link to="/Restaurant" className="onKaiseki depth1">
              <img
                src={require("../assets/img/slide3.png")}
                alt="슬라이드3"
                className="onKaiseki"
              />
            </Link>
          </div>
        </div>
      </div>
      <div id="reservation1">
        <div className="calender_wrapper">
          {openCalendar ? <Calendar openCalendar={openCalendar} setOpenCalendar={setOpenCalendar} thisCalendar={thisCalendar} setThisCalendar={setThisCalendar} year={year} month={month} ii={ii} setii={setii} /> : null}
        </div>
        <div id="ca">
          <button id="navList3">&#10005;</button>
          <a href="#none">
            <img src={require("../assets/img/ca.png")} alt="달력" />
          </a>
        </div>
        <div className="form_wrapper">
          <form id="reservation_2" onSubmit={onSearch} name="reserve2">
            <div className="r_title">
              <h6>호에이소 호텔</h6>
            </div>
            <div className="mgt15">
              <div>
                <input
                  className="search-txt"
                  type="text"
                  name="city"
                  placeholder="도시 또는 호텔명"
                  required
                />
                <a className="search-btn" href="#none"></a>
              </div>
              <div>
                <button type="submit">예 약</button>
              </div>
            </div>
            <div>
              <div>
                <div className="mgtMo15">
                  <h6>체크인</h6>
                  <button
                    type="button"
                    id="startDate"
                    className="startDate"
                    // onClick={() => popUpCalender()}
                    onClick={onCalendar}
                  >
                    <span>날짜</span>
                    <br />
                    <span>선택</span>
                    <input
                      type="text"
                      className="input_hide"
                      name="start_date_input"
                      id="start_date_input"
                      defaultValue=""
                      required
                    />
                  </button>
                </div>
                <div className="mgtMo15">
                  <h6>체크아웃</h6>
                  <button
                    type="button"
                    id="endDate"
                    className="endDate"
                    onClick={onCalendar}
                  >
                    <span>날짜</span>
                    <br />
                    <span>선택</span>
                    <input
                      type="text"
                      className="input_hide"
                      name="end_date_input"
                      id="end_date_input"
                      defaultValue=""
                      required
                    />
                  </button>
                </div>
                <div className="mgtMo15">
                  <h6>
                    <label htmlFor="num_rooms">객실</label>
                  </h6>
                  <input
                    type="text"
                    name="num_rooms"
                    id="num_rooms"
                    placeholder="1"
                    defaultValue="1"
                    readOnly
                    onClick={onlyOneRoom}
                    style={{
                      backgroundColor: "rgba(239,239,239,0.8)",
                      borderRadius: 5,
                    }}
                    required
                  />
                </div>
                <div className="mgtMo15">
                  <h6>
                    <label htmlFor="num_adult">성인</label>
                  </h6>
                  <input
                    type="text"
                    id="num_adult"
                    name="num_adult"
                    placeholder="1"
                    defaultValue="1"
                    required
                  />
                </div>
                <div className="mgtMo15">
                  <h6>
                    <label htmlFor="num_kids">어린이</label>
                  </h6>
                  <input
                    type="text"
                    id="num_kids"
                    name="num_kids"
                    placeholder="0"
                    defaultValue="0"
                    required
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div id="main">
        <img
          src={require("../assets/img/promlogo.png")}
          alt="프로모션"
          className="subject_image"
        />
        <div>
          <a href="#none" className="onEvent">
            더보기
          </a>
        </div>
        <div id="promotion1">
          <Link to="#none" onClick={(e) => {window.scrollTo(0,0);boardView(20, e)}}>
            <img
              src={require("../assets/img/prom1.png")}
              alt="프로모션이미지1"
            />
          </Link>
          <Link to="#none" onClick={(e) => {window.scrollTo(0,0);boardView(21, e)}}>
            <img
              src={require("../assets/img/prom2.png")}
              alt="프로모션이미지2"
            />
          </Link>
          <Link to="#none" onClick={(e) => {window.scrollTo(0,0);window.location.href='/onsen'}} className="onSpa">
            <img
              src={require("../assets/img/prom3.png")}
              alt="프로모션이미지3"
              className="onSpa"
            />
          </Link>
          <Link to="#none" onClick={(e) => {window.scrollTo(0,0);boardView(23, e)}}>
            <img
              src={require("../assets/img/prom4.png")}
              alt="프로모션이미지4"
            />
          </Link>
          <Link to="#none" onClick={(e) => {window.scrollTo(0,0);window.location.href='/kaiseki'}} className="onKaiseki">
            <img
              src={require("../assets/img/prom5.png")}
              alt="프로모션이미지5"
              className="onKaiseki"
            />
          </Link>
        </div>
        <div id="banner">
          <a href="#none">
            <img src={require("../assets/img/ban.jpg")} alt="배너" />
          </a>
        </div>
        <div id="photo">
          <a href="https://www.instagram.com/" target="_blank">
            <img src={require("../assets/img/photo.jpg")} alt="포토" />
          </a>
        </div>
        <div id="vi">
          <a href="https://www.youtube.com/watch?v=XqKkk3Euinc" target="_blank">
            <img src={require("../assets/img/vi.jpg")} alt="비디오" />
          </a>
        </div>
        <img
          src={require("../assets/img/bottomlogo.png")}
          alt="바텀글귀"
          className="subject_image"
        />
        <div id="footerban">
          <img src={require("../assets/img/footerp.jpg")} alt="호에이소 자연" />
        </div>
      </div>
    </main>
  );
};

export default Main;
