import React, { useEffect, useRef } from 'react';
import { popupImg,fadeOut,slide1,slideMove } from '../../assets/js/room';
import { home,reserve,room1,room2,room3,onsen,kaiseki,intro,event,inquire,business1,business2,login,join,confirm,mypage,search, restaurant } from '../../store/module/nav';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link
  } from "react-router-dom";
const Room1 = ({page,setPage,dispatch}) => {
	const cnt4 = useRef();
	const imgRef = useRef([]);
	const roomImg = useRef();
	const roomArr = useRef([]);
	useEffect(() => {
		let delay = false;
		/*갤러리 작은 이미지 클릭시 큰 이미지가 떠오름*/
		for (let ii = 0; ii < cnt4.current.children.length; ii++) {
			let imgClassParent = cnt4.current.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;

			let imgClassNum = cnt4.current.children[ii].className.split('cnt4_')[1];
			cnt4.current.children[ii].addEventListener('click', function () {
				popupImg(imgClassNum, imgClassParent);
			})
		}
		/*큰 이미지를 누르면 사라짐*/
		for (let i = 0; i < imgRef.current.length; i++) {
			imgRef.current[i].addEventListener('click', function () {
				let $this = this;
				fadeOut($this)
				setTimeout(function () {
					$this.style.display = 'none';
				}, 800)
			})
		}
		/*자동 슬라이드 쇼!*/
		setInterval(function () {
			if (delay) return
			else {
				slide1(roomImg.current)
			}
		}, 4500)


		/*화살표 누르면 사진 왼쪽 오른쪽 이동*/
		for (let i = 0; i < roomArr.length; i++) {
			for (let ii = 0; ii < roomArr[i].children.length; ii++) {
				roomArr[i].children[ii].addEventListener('click', function () {
					console.log(roomArr[i].children[ii])
					slideMove(roomArr[i].children[ii])
				})
			}

		}
	
	  return () => {
		
	  };
	}, [page]);

	const OnPageMove = (e) => {
		setPage(e)
	}
	const onActive = (e) => {
		e.target.parentElement.classList.add('active')
	}
	const offActive = (e) => {
		e.target.parentElement.classList.remove('active')
	}
	
    return (
        <div id="roomContent" className="roomContent">
		<div className="roomTop nav_eraser">
			<img src={require("../../assets/img/room/room_top.jpg")} alt="메뉴아래 이미지" />
		</div>
		<div className="content_area">
			<aside className="roomMenu">
				<div>
					<div>
						<h2>객실</h2>
					</div>
					<div>
						<h3 onMouseOver={onActive} onMouseLeave={offActive}><Link to="/room1" className="onRoom1 depth2" onClick={(e) => {dispatch(room1());OnPageMove(e.target.classList[0])}}>스탠다드 룸</Link></h3>
						<h3 onMouseOver={onActive} onMouseLeave={offActive}><Link to="/room2" className="onRoom2 depth2" onClick={(e) => {dispatch(room2());OnPageMove(e.target.classList[0])}}>스페셜룸</Link></h3>
						<h3 onMouseOver={onActive} onMouseLeave={offActive}><Link to="/room3" className="onRoom3 depth2" onClick={(e) => {dispatch(room3());OnPageMove(e.target.classList[0])}}>패밀리룸</Link></h3>
					</div>
				</div>
			</aside>
			<div>
				<h1><img src={require("../../assets/img/room/title1.png")} alt="제목이미지" className="subject_image" /></h1>
				<div className="standard">
					<p>
						밀짚으로 짠 다다미 마루로 마감되어 있으며,
						전통 일본식 요를 갖추고 있습니다.<br />
						에어컨, 평면 TV 및 냉장고도 마련되어 있습니다.
					</p>
				</div>
				<div className="roomSlide">
					<div className="roomImg" ref={roomImg}>
						<img src={require("../../assets/img/room/stand1.jpg")} alt="스탠다드1" />
						<img src={require("../../assets/img/room/stand2.jpg")} alt="스탠다드2" />
						<img src={require("../../assets/img/room/stand3.jpg")} alt="스탠다드3" />
						<img src={require("../../assets/img/room/stand4.jpg")} alt="스탠다드4" />
						<img src={require("../../assets/img/room/stand5.jpg")} alt="스탠다드5" />
						<img src={require("../../assets/img/room/stand6.jpg")} alt="스탠다드6" />
						<img src={require("../../assets/img/room/stand7.jpg")} alt="스탠다드7" />
						<img src={require("../../assets/img/room/stand8.jpg")} alt="스탠다드8" />
					</div>
					<div className="roomArr">
						<button type="button" className="moveLeft" ref={e => (roomArr.current[0] = e)} onClick={(e) => slideMove(e.target,roomImg.current)}><img src={require("../../assets/img/room/left_btn.png")} alt="왼쪽 화살표" /></button>
						<button type="button" className="moveRight" ref={e => (roomArr.current[1] = e)} onClick={(e) => slideMove(e.target,roomImg.current)}><img src={require("../../assets/img/room/right_btn.png")} alt="오른쪽 화살표" /></button>
					</div>
				</div>
				<div className="roomTxt">
					<div className="roomCnt">
						<div className="cnt2">
							<h2>특별서비스</h2>
							<ul>
								<li>산을 감상할 수 있는 객실</li>
								<li>해온(he:on) 베딩 시스템: 최상의 숙면을 위한 침대 및 침구류</li>
								<li>맞춤형 베개 제공</li>
								<li>전 객실 초고속 무료 인터넷 (유선, 와이파이)</li>
								<li>턴다운 서비스 제공</li>
							</ul>
						</div>
						<div>
							<h2>어메니티</h2>
							<div className="cnt3">
								<div>
									<h3>일반</h3>
									<p>37"LCD tv /손전등 /슬리퍼<br /> 전화기 / 티포트 / 금고<br /> 구둣주걱 / 구두클리너 / 미니바</p>
								</div>
								<div>
									<h3>욕실</h3>
									<p>1회용 칫솔 및 치약 / 면도기 / 목욕가운<br /> 비데 / 헤어드라이어 / 욕실용품 </p>
								</div>
								<div>
									<h3>기타</h3>
									<p> 케이블 위성tv 채널 <br />무료 생수 1일 2병 / 보이스 메일서비스<br /> 무료 차(tea) 서비스 </p>
								</div>
							</div>
						</div>
						<div>
							<h2 className="gallery_tlt"><img src={require("../../assets/img/room/title7.png")} alt="갤러리" className="subject_image" /></h2>
							<div className="cnt4" ref={cnt4}>
								<div className="cnt4_1">
									<img src={require("../../assets/img/room/stand6.jpg")} alt="갤러리이미지" />
								</div>
								<div className="cnt4_2">
									<img src={require("../../assets/img/room/stand7.jpg")} alt="갤러리이미지" />
								</div>
								<div className="cnt4_3">
									<img src={require("../../assets/img/room/stand3.jpg")} alt="갤러리이미지" />
								</div>
								<div className="cnt4_4">
									<img src={require("../../assets/img/room/stand4.jpg")} alt="갤러리이미지" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="popupImg rmPop1" ref={e => (imgRef.current[0] = e)}>
			<img src={require("../../assets/img/room/stand6.jpg")} alt="갤러리이미지" />
		</div>
		<div className="popupImg rmPop2" ref={e => (imgRef.current[1] = e)}>
			<img src={require("../../assets/img/room/stand7.jpg")} alt="갤러리이미지" />
		</div>
		<div className="popupImg rmPop3" ref={e => (imgRef.current[2] = e)}>
			<img src={require("../../assets/img/room/stand3.jpg")} alt="갤러리이미지" />
		</div>
		<div className="popupImg rmPop4" ref={e => (imgRef.current[3] = e)}>
			<img src={require("../../assets/img/room/stand4.jpg")} alt="갤러리이미지" />
		</div>
	</div>
    );
};

export default Room1;