import React from 'react';

const NotFiles = () => {
    return (
        <div>
             <h1> 페이지를 찾을수 없습니다. </h1>
        </div>
    );
};

export default NotFiles;