
/* 갤러리 화살표 누르면 갤러리 방향 이동*/

export const slideMove = (roomArr,roomImg) => {
	if (delay) return
	else {
		delay = true;
		// let roomImg = roomArr.parentElement.parentElement.children[0]
		console.log(roomArr)
		console.log(roomImg)
		if (roomArr.parentElement.className === 'moveRight') {
			roomImg.style.left = "-200%";
			setTimeout(function () {
				roomImg.appendChild(roomImg.children[0])
				roomImg.style.left = "-100%";
				roomImg.style.transition = '0s';
				delay = false;
			}, 800)
			roomImg.style.transition = '0.79s';
		} else if (roomArr.parentElement.className === 'moveLeft') {
			console.log('left')
			roomImg.style.left = "0%";
			setTimeout(function () {
				roomImg.prepend(roomImg.children[roomImg.children.length - 1])
				roomImg.style.left = "-100%";
				roomImg.style.transition = '0s';
				delay = false;
			}, 800)
			roomImg.style.transition = '0.79s';
		}
	}
}

/*갤러리 작은 이미지 클릭시 큰 이미지가 떠오름*/
export const popupImg = (num, roomContent) => {
	const popupVar = 1;
	let imgNum = popupVar + Number(num);
	roomContent.children[imgNum].style.display = 'block';
	fadeIn(roomContent.children[imgNum]);
}
/*페이드인*/
export const fadeIn = (target) => {
	let onOpacity = Number(target.style.opacity);
	let onTimer = 800;

	setInterval(function () {
		if (onOpacity < 1) {
			onOpacity = onOpacity + 0.025;
			target.style.opacity = onOpacity;
		} else return
	}, (onTimer / 40))
}

/*페이드아웃*/
export const fadeOut = (target) => {
	let onOpacity = Number(target.style.opacity);
	if (!onOpacity) onOpacity = 1;
	let onTimer = 800;
	setInterval(function () {
		if (onOpacity > 0) {
			onOpacity = onOpacity - 0.025;
			if (onOpacity < 0.025) onOpacity = 0;
			target.style.opacity = onOpacity;
		} else return
	}, (onTimer / 40))
}
let delay = false;
export const slide1 = (swiperLeft) => {
	if(swiperLeft){
	swiperLeft.style.left = '-200%';
	setTimeout(function () {
		swiperLeft.appendChild(swiperLeft.children[0])
	}, 800)
	setTimeout(function () {
		swiperLeft.style.transition = '0s';
		swiperLeft.style.left = '-100%';
	}, 800)
	swiperLeft.style.transition = '0.8s';
	}else return
}
