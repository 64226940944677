import axios from 'axios';






const regType1 = /^[a-z0-9_+.-]+@([a-z0-9-]+\.)+[a-z0-9]{2,4}$/; /*이메일 정규표현식 xxx@xxx.xx */
const regType2 = /^[a-z0-9]+$/; /* 아이디 정규표현식 영어소문자,숫자 */
const regType3 = /^[a-zA-Z0-9]+$/; /* 사람이름 정규표현식 영대,영소,숫자*/

/*번호만 허용*/
export const telOnly = (e) => {
	let $this = e.target;
	$this.value = $this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
}

/*영문과 번호 허용*/
export const telEngOnly = (e) => {
	let $this = e.target;
	if (e.keyCode !== 13 && e.keyCode !== 9 && e.keyCode !== 8 && e.keyCode !== 32) {
		if (!regType3.test($this.value)) {
			console.log(e.keyCode)
			console.log($this.value)
			console.log(regType3.test($this.value));
			$this.value = '';
//			return alert('영어만 사용이 가능합니다');
		}
	}
}

/*메일 중복 체크*/
export const dupMail = () => {
	let dup_mail_val = document.getElementById('young_mem_email').value;
	if (!regType1.test(dup_mail_val)) {
		console.log(regType1.test(dup_mail_val) + 'type1');
		return alert('올바른 이메일 형식이 아닙니다.');
	}
	if (!dup_mail_val.split('@')[1] || !dup_mail_val.split('@')[1].split('.')[1] || dup_mail_val.split(' ')[1] || dup_mail_val.split(' ')[1] === '') {
		console.log(regType1.test(dup_mail_val) + 'type2');
		return alert('올바른 이메일 형식이 아닙니다.');
	}
	dup_mail_val = dup_mail_val.trim();
	axios({
		method: "POST",
		url: '/assets/php/dup_mail_check.php',
		data: {
			dup_mail_val: dup_mail_val
		}
	}).then((res) => {
		console.log(res);
		if (res.data.split('|||')[0] === 'code1') {
			alert('이메일이 입력되지 않았습니다.');
			document.getElementById('duplicate_check').value = null;
		} else if (res.data.split('|||')[0] === 'code2') {
			alert('똑같은 이메일이 존재합니다.');
			document.getElementById('duplicate_check').value = null;
		} else if (res.data.split('|||')[0] === 'code0') {
			alert('사용가능한 이메일 입니다.');
			document.getElementById('duplicate_check').value = true;
		} else {
			alert('에러발생, 관리자에게 문의해주세요');
			document.getElementById('duplicate_check').value = null;
		}
	}).catch(error => {
		alert('에러발생, 관리자에게 문의해주세요');
		console.log(error);
		throw new Error(error);
	});
}
/*이메일에 키 입력시 다시 중복체크 하라는 메세지*/
export const dupMailVal = (e) => {
	if (e.keyCode !== 13) {
		if (document.getElementById('duplicate_check').value) {
			alert('이메일 중복체크를 다시 해야합니다.')
		}
		document.getElementById('duplicate_check').value = null;
	} else if (e.keyCode === 13) {
		return;
	} else {
		alert('에러발생, 관리자에게 문의 주십시오');
	}
}


/*아이디에 키 입력시 다시 중복체크 하라는 메세지*/
export const dupIdVal = (e) => {
	if (e.keyCode !== 13 && e.keyCode !== 9 && e.keyCode !== 8 && e.keyCode !== 32) {
		if (document.getElementById('duplicate_check2').value) {
			alert('아이디 중복체크를 다시 해야합니다.')
		}
		let $this = e.target;
		if (!regType2.test($this.value)) {
			console.log(regType2.test($this));
			$this.value = '';
			return alert('영어소문자와 숫자만 사용이 가능합니다');
		}
		document.getElementById('duplicate_check2').value = null;
	} else if (e.keyCode === 13 || e.keyCode === 9) {
		return;
	} else {
		return
	}
}
/*아이디 중복 체크*/
export const dupId = () => {
	let dup_id_val = document.getElementById('young_mem_id').value;
	console.log(dup_id_val)
	if (!regType2.test(dup_id_val)) {
		console.log(regType2.test(dup_id_val) + 'type1');
		return alert('올바른 아이디 형식이 아닙니다.');
	}
	axios({
		method: "POST",
		url: '/assets/php/dup_id_check.php',
		data: {
			dup_id_val: dup_id_val
		}
	}).then((res) => {
		console.log(res);
		if (res.data.split('|||')[0] === 'code1') {
			alert('아이디가 입력되지 않았습니다.');
			document.getElementById('duplicate_check2').value = null;
		} else if (res.data.split('|||')[0] === 'code2') {
			alert('똑같은 아이디가 존재합니다.');
			document.getElementById('duplicate_check2').value = null;
		} else if (res.data.split('|||')[0] === 'code0') {
			alert('사용가능한 아이디 입니다.');
			document.getElementById('duplicate_check2').value = true;
		} else {
			alert('에러발생, 관리자에게 문의해주세요');
			document.getElementById('duplicate_check2').value = null;
		}
	}).catch(error => {
		alert('에러발생, 관리자에게 문의해주세요');
		console.log(error);
		throw new Error(error);
	});
}








export const onJoin = async(e) => {
	e.preventDefault();
	const young_mem_id = document.join_form.young_mem_id.value;
	const young_mem_pass = document.join_form.young_mem_pass.value;
	const young_mem_pass_r = document.join_form.young_mem_pass_r.value;
	const young_mem_name_local_first = document.join_form.young_mem_name_local_first.value;
	const young_mem_name_local_last = document.join_form.young_mem_name_local_last.value;
	const young_mem_name_en_first = document.join_form.young_mem_name_en_first.value;
	const young_mem_name_en_last = document.join_form.young_mem_name_en_last.value;
	const young_mem_nation = document.join_form.young_mem_nation.value;

	const young_mem_email = document.join_form.young_mem_email.value;

	let val = document.getElementById('young_mem_phone_1');
	let young_mem_phone_1 = val.options[val.selectedIndex].value;


	const young_mem_phone_2 = document.join_form.young_mem_phone_2.value;
	const young_mem_phone_3 = document.join_form.young_mem_phone_3.value;
	const young_mem_phone = young_mem_phone_1 + young_mem_phone_2 + young_mem_phone_3;

	const young_mem_tel_1 = document.join_form.young_mem_tel_1.value;
	const young_mem_tel_2 = document.join_form.young_mem_tel_2.value;
	const young_mem_tel_3 = document.join_form.young_mem_tel_3.value;
	let young_mem_tel = young_mem_tel_1 + young_mem_tel_2 + young_mem_tel_3;
	if (young_mem_tel === '') {
		young_mem_tel = 'NULL';
	}
	const young_mem_addr_1 = document.join_form.young_mem_addr_1.value;
	const young_mem_addr_2 = document.join_form.young_mem_addr_2.value;
	const young_mem_addr_3 = document.join_form.young_mem_addr_3.value;
	let young_mem_addr = young_mem_addr_1 + young_mem_addr_2 + young_mem_addr_3;
	if (young_mem_addr === '') {
		young_mem_addr = 'NULL';
	}
	const young_mem_birth = document.join_form.young_mem_birth.value;

	if (young_mem_birth.toString().length !== 8) {
		return alert('생년월일을 8자리로 입력해주세요')
	}
	if (young_mem_pass !== young_mem_pass_r) {
		return alert('비밀번호와 비밀번호 확인이 일치하지 않습니다')
	}

//	let data = {
//		young_mem_id: young_mem_id,
//		young_mem_pass: young_mem_pass,
//		young_mem_name_local_first: young_mem_name_local_first,
//		young_mem_name_local_last: young_mem_name_local_last,
//		young_mem_name_en_first: young_mem_name_en_first,
//		young_mem_name_en_last: young_mem_name_en_last,
//		young_mem_nation: young_mem_nation,
//		young_mem_email: young_mem_email,
//		young_mem_phone: young_mem_phone,
//		young_mem_tel: young_mem_tel,
//		young_mem_addr: young_mem_addr,
//		young_mem_birth: young_mem_birth
//	}
//	let data = [ young_mem_id, young_mem_pass, young_mem_name_local_first,young_mem_name_local_last,young_mem_name_en_first,
//		young_mem_name_en_last,
//		young_mem_email,
//		young_mem_phone,
//		young_mem_tel,
//		young_mem_addr,
//		young_mem_birth
//	]
	
	let formData = new FormData();
	formData.append('image', document.getElementById('young_mem_profile').files[0]);
	formData.append('young_mem_id',young_mem_id);
	formData.append('young_mem_pass',young_mem_pass);
	formData.append('young_mem_name_local_first',young_mem_name_local_first);
	formData.append('young_mem_name_local_last',young_mem_name_local_last);
	formData.append('young_mem_name_en_first',young_mem_name_en_first);
	formData.append('young_mem_name_en_last',young_mem_name_en_last);
	formData.append('young_mem_nation',young_mem_nation);
	formData.append('young_mem_email',young_mem_email);
	formData.append('young_mem_phone',young_mem_phone);
	formData.append('young_mem_tel',young_mem_tel);
	formData.append('young_mem_addr',young_mem_addr);
	formData.append('young_mem_birth',young_mem_birth);

	await axios.post(process.env.REACT_APP_URL + '/assets/php/join.php',
		formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}
	).then((res) => {
		console.log(res);
		if (res.data.split('|||')[0] === 'code1') {
			alert('사진 업로드를 실패했습니다.');
			window.sessionStorage.setItem('joinGo', null)
		} else if (res.data.split('|||')[0] === 'code2') {
			window.sessionStorage.setItem('joinGo', null);
			alert('회원가입 성공!');
		} else if (res.data.split('|||')[0] === 'code3') {
			window.sessionStorage.setItem('joinGo', null);
			alert('이미지 파일이 좀 이상합니다');
		} else if (res.data.split('|||')[0] === 'code3_2') {
			window.sessionStorage.setItem('joinGo', null);
			alert('이미지가 업로드 되지 않습니다.(아마도 mkdir 권한문제)');
		} else if (res.data.split('|||')[0] === 'code3_3') {
			window.sessionStorage.setItem('joinGo', null);
			alert('이미지 파일이 올라오지 않았습니다.');
		} else if (res.data.split('|||')[0] === 'code4') {
			window.sessionStorage.setItem('joinGo', null);
			alert('jpg,png 파일의 형식만 올려주세요!');
		} else if (res.data.split('|||')[0] === 'code5') {
			window.sessionStorage.setItem('joinGo', null);
			alert('파일 사이즈를 700KB 이하로 줄여주세요');
		} else if (res.data.split('|||')[0] === 'code6') {
			window.sessionStorage.setItem('joinGo', null);
			alert('이미지 업로드 실패');
		} else if (res.data.split('|||')[0] === 'code7') {
			window.sessionStorage.setItem('joinGo', null);
			alert('회원가입 데이터가 부족합니다. 비정상적인 접근');
		} else if (res.data.split('|||')[0] === 'code0') {
			window.sessionStorage.setItem('joinGo', true);
			alert('회원가입 성공!');
			onLoginAfterJoin(young_mem_id,young_mem_pass,e);
		} else {
			alert('에러발생, 관리자에게 문의해주세요');
			window.sessionStorage.setItem('joinGo', null)
			console.log(res.data)
		}
		if (window.sessionStorage.getItem('joinGo')) {
			
		}
	}).catch(error => {
		console.log(error);
		throw new Error(error);
	});
}

function onLoginAfterJoin(form_id,form_pass,e){
	e.preventDefault();
	axios({
		method: "POST",
		url: '/assets/php/login.php',
		data: {
			form_id: form_id,
			form_pass: form_pass
		}
	}).then((res) => {
		console.log(res);
		if (res.data.split('|||')[0] === 'code0') {
			alert('로그인을 성공했습니다.');
			window.sessionStorage.setItem('session_hash',res.data.split('|||')[1])
			window.sessionStorage.setItem('session_id',form_id.value);
			window.localStorage.setItem('location','onHome');
			window.location.href = '/';
		} else {
			alert('에러발생, 관리자에게 문의해주세요');
			window.localStorage.setItem('location','onHome');
			window.location.reload()
		}
	}).catch(error => {
		alert('에러발생, 관리자에게 문의해주세요');
		console.log(error);
		throw new Error(error);
	});
}








function onJoinPhoto(id) {
	let young_mem_profile = document.join_form.young_mem_profile;
	let filename = id + '@' + young_mem_profile.value.split('C:\\fakepath\\')[1];
	if (young_mem_profile === '') {
		young_mem_profile = 'NULL';
	}

	let formData = new FormData();
	formData.append('image', young_mem_profile.files[0]);
	formData.append('young_mem_id', id);
	formData.append('filename', filename);
	this.axios.post(process.env.REACT_APP_URL + '/assets/php/join_photo.php',
		formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}
	).then((res) => {
		console.log(res);
		if (res.data.split('|||')[0] === 'code4') {
			alert('파일 업로드 실패');
		} else if (res.data.split('|||')[0] === 'code2') {
			alert('jpg,png 파일의 형식만 올려주세요!');

		}else if (res.data.split('|||')[0] === 'code3') {
			 alert('파일 사이즈를 700KB 이하로 줄여주세요');

		}else if (res.data.split('|||')[0] === 'code0') {
			 alert('회원가입이 완료되었습니다');

		} else {
			console.log(res.data);
			document.write(res.data)

		}
		if (window.sessionStorage.getItem('joinGo')) {

		}

	}).catch(error => {
		console.log(error);
		throw new Error(error);
	});


}

export const fileName = (e) => {
	let $this = e.target.value;
	let $thislabel = document.getElementById('label_file');
	$thislabel.children[0].innerHTML = $this;
}
export const fileName2 = (e) => {
	let $this = e.target.value;
	let $thislabel = document.getElementById('onEditInfo_file');
	$thislabel.children[0].innerHTML = $this;
}



/*모든 패스워드에 적용, maxlength에 도달할 경우 경고*/
window.addEventListener('load', function () {


	let documentInput = document.querySelectorAll("input[type='password']")
	for (let i = 0; i < documentInput.length; i++) {
		documentInput[i].addEventListener('keyup', function () {
			if (this.getAttribute('maxlength') && Number(this.getAttribute('maxlength')) === this.value.length) {
				alert('최대 문자 수에 도달했습니다!')
			}
		})
	}
})
export {regType1,regType2,regType3};