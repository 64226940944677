import axios from 'axios';
import {regType1} from './join';
import {onLogout} from './login';


function myInfoLoader(e) {
e.preventDefault();
	

}
/*메일 중복 체크*/
export const editDupMail = () => {
	let dup_mail_val = document.getElementById('onEditInfo_email').value;
	let young_mem_id = document.edit_form.onEditInfo_id.value;
	if (!regType1.test(dup_mail_val)) {
		console.log(regType1.test(dup_mail_val) + 'type1');
		return alert('올바른 이메일 형식이 아닙니다.');
	}
	if (!dup_mail_val.split('@')[1] || !dup_mail_val.split('@')[1].split('.')[1] || dup_mail_val.split(' ')[1] || dup_mail_val.split(' ')[1] === '') {
		console.log(regType1.test(dup_mail_val) + 'type2');
		return alert('올바른 이메일 형식이 아닙니다.');
	}
	dup_mail_val = dup_mail_val.trim();
	axios({
		method: "POST",
		url: '/assets/php/dup_mail_check2.php',
		data: {
			dup_mail_val: dup_mail_val,
			young_mem_id:young_mem_id
		}
	}).then((res) => {
		console.log(res);
		if (res.data.split('|||')[0] === 'code1') {
			alert('이메일이 입력되지 않았습니다.');
			document.getElementById('duplicate_check3').value = null;
		} else if (res.data.split('|||')[0] === 'code2') {
			alert('똑같은 이메일이 존재합니다.');
			document.getElementById('duplicate_check3').value = null;
		} else if (res.data.split('|||')[0] === 'code0') {
			alert('사용가능한 이메일 입니다.');
			document.getElementById('duplicate_check3').value = 'T';
			console.log(document.getElementById('duplicate_check3').value)
		} else {
			alert('에러발생, 관리자에게 문의해주세요');
			document.getElementById('duplicate_check3').value = null;
		}
	}).catch(error => {
		alert('에러발생, 관리자에게 문의해주세요');
		console.log(error);
		throw new Error(error);
	});
}
/*이메일에 키 입력시 다시 중복체크 하라는 메세지*/
export const editDupMailVal = (e) => {
	if (e.keyCode !== 13) {
		if (document.getElementById('duplicate_check3').value === 'T') {
			alert('이메일 중복체크를 다시 해야합니다.')
			document.getElementById('duplicate_check3').value = null;
		}
		
	} else if (e.keyCode === 13) {
		return;
	} else {
		// alert('에러발생, 관리자에게 문의 주십시오');
		return;
	}
}



export const completeEditInfo =async(e) => {
	e.preventDefault();
	let $local_first = document.edit_form.onEditInfo_name_local_first.value;
	let $local_last = document.edit_form.onEditInfo_name_local_last.value;
	let $en_first = document.edit_form.onEditInfo_name_en_first.value;
	let $en_last = document.edit_form.onEditInfo_name_en_last.value;
	let $email = document.edit_form.onEditInfo_email.value;
	let $phone = document.edit_form.onEditInfo_phone.value;
	let $birth = document.edit_form.onEditInfo_birth.value;
	let $nation = document.edit_form.onEditInfo_nation.value;
	let $tel = document.edit_form.onEditInfo_tel.value;
	let $addr = document.edit_form.onEditInfo_addr.value;
	let $image = document.edit_form.onEditInfo_profile;

	let $id = document.edit_form.onEditInfo_id.value;
	let $pass_origin = document.edit_form.onEditInfo_pass_origin.value;
	let $pass = document.edit_form.onEditInfo_pass.value;
	let $pass_r = document.edit_form.onEditInfo_pass_r.value;

	if (!$local_first) $local_first = document.edit_form.onEditInfo_name_local_first.getAttribute('placeholder');
	if (!$local_last) $local_last = document.edit_form.onEditInfo_name_local_last.getAttribute('placeholder');
	if (!$en_first) $en_first = document.edit_form.onEditInfo_name_en_first.getAttribute('placeholder');
	if (!$en_last) $en_last = document.edit_form.onEditInfo_name_en_last.getAttribute('placeholder');
	if (!$email) $email = document.edit_form.onEditInfo_email.getAttribute('placeholder');
	if (!$phone) $phone = document.edit_form.onEditInfo_phone.getAttribute('placeholder');
	if (!$birth) $birth = document.edit_form.onEditInfo_birth.getAttribute('placeholder');
	if (!$nation) $nation = document.edit_form.onEditInfo_nation.getAttribute('placeholder');
	if (!$tel) $tel = document.edit_form.onEditInfo_tel.getAttribute('placeholder');
	if (!$addr) $addr = document.edit_form.onEditInfo_addr.getAttribute('placeholder');

	if (!$pass_origin) {
		alert('원래 비밀번호를 입력해주세요');
		return;
	}
	if ($pass !== $pass_r) {
		alert('새 비밀번호와 비밀번호 확인이 다릅니다');
		return
	}
	console.log($birth)
	let formData = new FormData();
	formData.append('young_mem_id', $id)
	formData.append('young_mem_pass', $pass_origin)
	formData.append('young_mem_name_local_first', $local_first)
	formData.append('young_mem_name_local_last', $local_last)
	formData.append('young_mem_name_en_first', $en_first)
	formData.append('young_mem_name_en_last', $en_last)
	formData.append('young_mem_email', $email)
	formData.append('young_mem_phone', $phone)
	formData.append('young_mem_tel', $tel)
	formData.append('young_mem_addr', $addr)
	formData.append('young_mem_birth', $birth)
	formData.append('young_mem_nation', $nation)

	console.log($phone);
	if($image.files[0]){
		formData.append('image', $image.files[0])
	}
	if ($pass) {
		formData.append('young_mem_pass_new', $pass)
		await axios.post(process.env.REACT_APP_URL + '/assets/php/edit_info.php',
			formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		).then((res) => {
			if (res.data.split('|||')[0] === 'code1') {
				alert('로그인 해주세요');
			} else if (res.data.split('|||')[0] === 'code11'){
				alert('비밀번호가 틀립니다');
			} else if (res.data.split('|||')[0] === 'code0'){
				alert('수정이 완료되었습니다.');
				window.location.href = '/'
			}  else if (res.data.split('|||')[0] === 'code5'){
				alert('이미지의 사이즈는 700kb 이하로 줄여주세요');
			} else {
				alert('이미지에 문제가 있습니다.')
			}
		}).catch(error => {
			alert('에러발생, 관리자에게 문의해주세요');
			console.log(error);
			throw new Error(error);
		})
	} else {

		await axios.post(process.env.REACT_APP_URL + '/assets/php/edit_info2.php',
			formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		).then((res) => {
			if (res.data.split('|||')[0] === 'code1') {
				if(window.sessionStorage.getItem('session_id')) onLogout();
				alert('로그인 해주세요');
			} else if (res.data.split('|||')[0] === 'code11'){
				alert('비밀번호가 틀립니다');
			} else if (res.data.split('|||')[0] === 'code0'){
				alert('수정이 완료되었습니다.');
				window.location.href = '/'
			} else {
				console.log(res);
			}
		}).catch(error => {
			alert('에러발생, 관리자에게 문의해주세요');
			console.log(error);
			throw new Error(error);
		})

	}

}









async function onComplete(x) {
	if (x === 'x' || !x) {
		window.localStorage.setItem('location', 'onCompleteList');
		window.location.reload();
		return;
	}

	let formData = new FormData();
	formData.append('reserve_num', x);
	await axios.post(process.env.REACT_APP_URL + '/assets/php/search_my_reservation.php',
		formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}
	).then((res) => {
		console.log(res.data);

		// 모든 내용물 display none, 그다음 localstorage읽고 한개만 block
		for (let i = 1; i < document.body.children.length - 1; i++) {
			document.body.children[i].style.display = "none";
		}
		document.getElementById('complete').style.display = 'block';

		let grade = '';
		if (res.data.reserve_grade === '1') {
			grade = 'STANDARD';
		} else if (res.data.reserve_grade === '2') {
			grade = 'GREAT';
		} else if (res.data.reserve_grade === '3') {
			grade = 'SPECIAL';
		}

		document.join_form3.complement_young_mem_name_en.value = res.data.reserve_name;
		document.join_form3.complement_young_mem_birth.value = res.data.reserve_birth;
		document.join_form3.complement_young_mem_email.value = res.data.reserve_mail;
		document.join_form3.complement_young_mem_phone.value = res.data.reserve_num;

		document.join_form3.complement_young_mem_nation.value = res.data.reserve_nation;
		document.join_form3.complement_breakfast.value = res.data.reserve_breakfast;
		document.join_form3.complement_dinner.value = res.data.reserve_dinner;
		document.join_form3.complement_message.value = res.data.reserve_massage;

		document.getElementById('complement_checkin').innerHTML = res.data.reserve_start;
		document.getElementById('complement_checkout').innerHTML = res.data.reserve_end;
		document.getElementById('complement_totalman').innerHTML = res.data.reserve_howmany;
		document.getElementById('complement_grade').innerHTML = grade;
		document.getElementById('complement_money').innerHTML = res.data.reserve_price;
		document.getElementById('complete_date').innerHTML = res.data.reserve_start;


		window.scrollTo(0, 0);



	}).catch(error => {
		alert('에러발생, 관리자에게 문의해주세요');
		console.log(error);
		throw new Error(error);
		return
	})

}



function onConfirmReserve() {
	let $reserve_num = document.login_form.form_id2.value;
	let $reserve_mail = document.login_form.form_pass2.value;

	let formData = new FormData();
	formData.append('reserve_num', $reserve_num);
	formData.append('reserve_mail', $reserve_mail);

	axios.post(process.env.REACT_APP_URL + '/assets/php/search_my_reservation2.php',
		formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}
	).then((res) => {
		console.log(res);
		if (res.data.split('|||')[0] === 'code0') {
			onComplete($reserve_num);
		} else if (res.data.split('|||')[0] === 'code1') {
			alert('예약번호 혹은 메일이 다릅니다.');
			return;
		} else {
			alert('예약번호 혹은 메일이 다릅니다.');
			return;
		}
	}).catch(error => {
		alert('에러발생, 관리자에게 문의해주세요');
		console.log(error);
		throw new Error(error);
		return
	})

}



window.addEventListener('load', function () {

})
export {myInfoLoader,onComplete};