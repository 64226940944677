import React, { useEffect, useRef } from 'react';
import { popupImg,fadeOut,slide1,slideMove } from '../../assets/js/room';
import { home,reserve,room1,room2,room3,onsen,kaiseki,intro,event,inquire,business1,business2,login,join,confirm,mypage,search, restaurant } from '../../store/module/nav';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link
  } from "react-router-dom";

const Business1 = ({page,setPage,dispatch}) => {
	const cnt4 = useRef();
	const imgRef = useRef([]);
	const roomImg = useRef();
	const roomArr = useRef([]);
	useEffect(() => {
		let delay = false;
		/*갤러리 작은 이미지 클릭시 큰 이미지가 떠오름*/
		console.log(cnt4)
		for (let ii = 0; ii < cnt4.current.children.length; ii++) {
			let imgClassParent = cnt4.current.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;

			let imgClassNum = cnt4.current.children[ii].className.split('cnt4_')[1];
			cnt4.current.children[ii].addEventListener('click', function () {
				popupImg(imgClassNum, imgClassParent);
			})
		}
		/*큰 이미지를 누르면 사라짐*/
		for (let i = 0; i < imgRef.current.length; i++) {
			imgRef.current[i].addEventListener('click', function () {
				let $this = this;
				fadeOut($this)
				setTimeout(function () {
					$this.style.display = 'none';
				}, 800)
			})
		}
		/*자동 슬라이드 쇼!*/
		setInterval(function () {
			if (delay) return
			else {
				slide1(roomImg.current)
			}
		}, 4500)


		/*화살표 누르면 사진 왼쪽 오른쪽 이동*/
		for (let i = 0; i < roomArr.length; i++) {
			for (let ii = 0; ii < roomArr[i].children.length; ii++) {
				roomArr[i].children[ii].addEventListener('click', function () {
					console.log(roomArr[i].children[ii])
					slideMove(roomArr[i].children[ii])
				})
			}

		}
	
	  return () => {
		
	  };
	}, [page]);

	const OnPageMove = (e) => {
		setPage(e)
	}
	const onActive = (e) => {
		e.target.parentElement.classList.add('active')
	}
	const offActive = (e) => {
		e.target.parentElement.classList.remove('active')
	}

    return (
        <div id="business" className="roomContent">
		<div className="roomTop nav_eraser">
			<img src={require("../../assets/img/business/facility_top.jpg")} alt="메뉴아래 이미지" />
		</div>
		<div className="content_area">
			<aside className="roomMenu">
				<div>
					<div>
						<h2>부대시설</h2>
					</div>
					<div>
						<h3 onMouseOver={onActive} onMouseLeave={offActive}><Link to="/business1" className="onBusiness depth2" onClick={(e) => {dispatch(business1());OnPageMove(e.target.classList[0])}}>비즈니스 센터</Link></h3>
						<h3 onMouseOver={onActive} onMouseLeave={offActive}><Link to="/business2" className="onBusiness2 depth2" onClick={(e) => {dispatch(business2());OnPageMove(e.target.classList[0])}}>미팅룸</Link></h3>
					</div>
				</div>
			</aside>
			<div>
				<h1><img src={require("../../assets/img/business/title5.png")} alt="제목이미지" className="subject_image" /></h1>
				<div className="standard">
					<p>당신을 위한 프리미엄 비즈니스 서비스<br /><br />
						3층에 위치한 비즈니스 센터는 업무에 필요한 각종 편의시설을 제공합니다.<br /> PC, 컬러 레이저 프린터, 스캐너, 노트북 대여가 가능합니다.<br />
						또한, 복사, 팩스, 번역, 우편물 발송부터 명함 제작까지 비즈니스 긴급 상황에 대비한 다양한 서비스도 이용하실 수 있습니다.</p>
				</div>
				<div className="roomSlide">
					<div className="roomImg" ref={roomImg}>
						<img src={require("../../assets/img/room/stand1.jpg")} alt="스탠다드1" />
						<img src={require("../../assets/img/room/stand2.jpg")} alt="스탠다드2" />
						<img src={require("../../assets/img/room/stand3.jpg")} alt="스탠다드3" />
						<img src={require("../../assets/img/room/stand4.jpg")} alt="스탠다드4" />
						<img src={require("../../assets/img/room/stand5.jpg")} alt="스탠다드5" />
						<img src={require("../../assets/img/room/stand6.jpg")} alt="스탠다드6" />
						<img src={require("../../assets/img/room/stand7.jpg")} alt="스탠다드7" />
						<img src={require("../../assets/img/room/stand8.jpg")} alt="스탠다드8" />
					</div>
					<div className="roomArr">
						<button type="button" className="moveLeft" ref={e => (roomArr.current[0] = e)} onClick={(e) => slideMove(e.target,roomImg.current)}><img src={require("../../assets/img/room/left_btn.png")} alt="왼쪽 화살표" /></button>
						<button type="button" className="moveRight" ref={e => (roomArr.current[1] = e)} onClick={(e) => slideMove(e.target,roomImg.current)}><img src={require("../../assets/img/room/right_btn.png")} alt="오른쪽 화살표" /></button>
					</div>
				</div>
				<div className="roomTxt">
					<div className="roomCnt">
						<div>
							<h2 className="gallery_tlt"><img src={require("../../assets/img/room/title7.png")} alt="갤러리" className="subject_image" /></h2>
							<div className="cnt4" ref={cnt4}>
								<div className="cnt4_1">
									<img src={require("../../assets/img/business/meeting3.jpg")} alt="갤러리이미지" />
								</div>
								<div className="cnt4_2">
									<img src={require("../../assets/img/business/meeting4.jpg")} alt="갤러리이미지" />
								</div>
								<div className="cnt4_3">
									<img src={require("../../assets/img/business/meeting1.jpg")} alt="갤러리이미지" />
								</div>
								<div className="cnt4_4">
									<img src={require("../../assets/img/business/meeting2.jpg")} alt="갤러리이미지" />
								</div>
							</div>
						</div>
						<div className="cnt2">
							<h2>이용시간</h2>
							<ul>
								<li>평일: 08:00~22:00</li>
								<li>주말 및 공휴일: 09:00~18:00</li>
							</ul>
						</div>
						<div className="cnt2">
							<h2>서비스</h2>
							<ul>
								<li>회의실: 4인 회의실, 노트북 대여 등</li>
								<li>최신 설비: 레이저 프린터, 고속 복사기, 노트북 대여, 초고속 인터넷, 스피커폰,이동식 하드디스크</li>
								<li>우편 업무: 택배 서비스, 포장 서비스, 퀵 서비스</li>
								<li>비서 업무: 복사&amp;팩스 서비스, 타이핑&amp;인쇄 서비스, 명함 제작 서비스</li>
								<li>다양한 소프트 웨어: Adobe Reader 프로그램 등</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="popupImg rmPop1" ref={e => (imgRef.current[0] = e)}>
			<img src={require("../../assets/img/business/meeting3.jpg")} alt="갤러리이미지" />
		</div>
		<div className="popupImg rmPop2" ref={e => (imgRef.current[1] = e)}>
			<img src={require("../../assets/img/business/meeting4.jpg")} alt="갤러리이미지" />
		</div>
		<div className="popupImg rmPop3" ref={e => (imgRef.current[2] = e)}>
			<img src={require("../../assets/img/business/meeting1.jpg")} alt="갤러리이미지" />
		</div>
		<div className="popupImg rmPop4" ref={e => (imgRef.current[3] = e)}>
			<img src={require("../../assets/img/business/meeting2.jpg")} alt="갤러리이미지" />
		</div>
	</div>
    );
};

export default Business1;