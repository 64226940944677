import React from 'react';
import DaumPostcode from "react-daum-postcode";
 
const PopupPostCode = ({setZipcode,setAdd1,setAdd2,onClose}) => {
	// 우편번호 검색 후 주소 클릭 시 실행될 함수, data callback 용
    const handlePostCode = (data) => {
        let fullAddress = data.address;
        let extraAddress = ''; 
        
        if (data.addressType === 'R') {
          if (data.bname !== '') {
            extraAddress += data.bname;
          }
          if (data.buildingName !== '') {
            extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
          }
          fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
        }
        setZipcode(data.zonecode)
        setAdd1(fullAddress)
        setAdd2('(상세주소)')
        console.log(data)
        console.log(fullAddress)
        console.log(data.zonecode)
        onClose()
    }
 
    const postCodeStyle = {

      width: "100%",
      height: "100%",
      };
    const postWrapper = {
      display: "block",
        position: "fixed",
        zIndex:"50",
        top: "50%",
        left: "50%",
        width: "100%",
        height: "100%",
        maxWidth:600,
        maxHeight:465,
        padding: "7px",
        transform:'translate(-50%,-50%)'
    }
    const postBtn = {
      position:'absolute',
      bottom:15,
      right:15,
      color:'black',
      padding:'5px 7px'
    }
    return(
        <div style={postWrapper}>
            <DaumPostcode style={postCodeStyle} onComplete={handlePostCode} />
  
            <button type='button' style={postBtn}  onClick={onClose} className='postCode_btn'>닫기</button>
        </div>
    )
}
 
export default PopupPostCode;