import {myRLoader} from '../php/reserve';
import {myInfoLoader,onComplete} from '../php/edit_info';
import {onLogout} from '../php/login';
import { useDispatch, useSelector } from "react-redux";

function nothing(e) {
	e.preventDefault();
}


//메인 화면 제일 윗부분 높이 부여하기\
// function mainTopHeight() {
// 	document.getElementById('slide').style.height = document.getElementById('slide').children[0].offsetHeight + "px";
// }




// function navOff() {
// 	document.getElementById('nav_button1').classList.remove("on");
// 	document.getElementById('nav_button2').classList.add("on")
// 	document.getElementsByClassName('depth0_wrapper')[0].classList.remove("on");
// 	popupOff();
// }

function popupOff() {
	for (let i = 0; i < document.getElementsByClassName('popupImg').length; i++) {
		document.getElementsByClassName('popupImg')[i].style.display = "none";
	}
}

function fadeInOn() {
	let fadeInOn = document.getElementsByClassName('fadeInOn');
	for (let i = 0; i < fadeInOn.length; i++) {
		//		console.log("i = " + fadeInOn[i].classList + " and "+ fadeInOn[i].getBoundingClientRect().top)
		if (fadeInOn[i].getBoundingClientRect().top < 700 && fadeInOn[i].getBoundingClientRect().top > -700) fadeInOn[i].classList.add('on')
		else fadeInOn[i].classList.remove('on')
	}
}


function scrollTop() {
	setTimeout(function () {
		window.scrollTo(0, 0);
	}, 0)

}

// 식당, 가이세키 제일 아랫부분 section5 > div에 높이 부여하기 (안하면 0임) 
const restHeight = () => {
	let sec5 = document.getElementsByClassName('section5')
	let sec5Height = [];
	for (let ii = 0; ii < sec5.length; ii++) {
		sec5Height[ii] = 0;
		for (let i = 0; i < sec5[ii].children[0].children.length; i++) {
			sec5Height[ii] = sec5Height[ii] + sec5[ii].children[0].children[i].offsetHeight;
		}
		document.getElementsByClassName('section5')[ii].children[0].style.height = sec5Height[ii] + 'px';
	}
	document.getElementsByClassName('section5')[0].children[0].style.height = sec5Height + 'px';
}


function exx() {
	console.log(document.getElementsByClassName('swiper-wrapper')[0].children[0])
	document.getElementsByClassName('swiper-wrapper')[0].appendChild(document.getElementsByClassName('swiper-wrapper')[0].children[0])
}

function onlyOneRoom(e) {
	e.preventDefault();
	alert("현재 성수기로, 하나의 방만 예약 됩니다.")
}

function onSearch(e) {
	console.log(e)

}



function slide1(swiperLeft) {
	swiperLeft.style.left = '-200%';
	setTimeout(function () {
		swiperLeft.appendChild(swiperLeft.children[0])
	}, 800)
	setTimeout(function () {
		swiperLeft.style.transition = '0s';
		swiperLeft.style.left = '-100%';
	}, 800)
	swiperLeft.style.transition = '0.8s';
}


/*페이드인*/
function fadeIn(target) {
	let onOpacity = Number(target.style.opacity);
	let onTimer = 800;

	setInterval(function () {
		if (onOpacity < 1) {
			onOpacity = onOpacity + 0.025;
			target.style.opacity = onOpacity;
		} else return
	}, (onTimer / 40))
}

/*페이드아웃*/
function fadeOut(target) {
	let onOpacity = Number(target.style.opacity);
	if (!onOpacity) onOpacity = 1;
	let onTimer = 800;
	setInterval(function () {
		if (onOpacity > 0) {
			onOpacity = onOpacity - 0.025;
			if (onOpacity < 0.025) onOpacity = 0;
			target.style.opacity = onOpacity;
		} else return
	}, (onTimer / 40))
}



/* 갤러리 화살표 누르면 갤러리 방향 이동*/
let delay = false;

function slideMove(roomArr) {
	if (delay) return
	else {
		delay = true;
		let roomImg = roomArr.parentElement.parentElement.children[0]
		if (roomArr.className === 'moveRight') {
			roomImg.style.left = "-200%";
			setTimeout(function () {
				roomImg.appendChild(roomImg.children[0])
				roomImg.style.transition = '0s';
				roomImg.style.left = "-100%";
				delay = false;
			}, 800)
			roomImg.style.transition = '0.8s';
		} else if (roomArr.className === 'moveLeft') {
			roomImg.style.left = "0%";
			setTimeout(function () {
				roomImg.prepend(roomImg.children[roomImg.children.length - 1])
				roomImg.style.transition = '0s';
				roomImg.style.left = "-100%";
				delay = false;
			}, 800)
			roomImg.style.transition = '0.8s';
		}
	}
}

/*갤러리 작은 이미지 클릭시 큰 이미지가 떠오름*/
function popupImg(num, roomContent) {
	const popupVar = 1;
	let imgNum = popupVar + Number(num);
	roomContent.children[imgNum].style.display = 'block';
	fadeIn(roomContent.children[imgNum]);
}


/*방 자세히 보기*/
export const detailRoomOpen = (e) => {
	e.preventDefault();
	if (e.target.id === 'room1') {
		window.open("https://hoeiso.gloomy-store.com/ry/room/index.php", "a", "width=1200, height=1000");
	} else if (e.target.id === 'room2') {
		window.open("https://hoeiso.gloomy-store.com/ry/room/index2.php", "a", "width=1200, height=1000");
	} else if (e.target.id === 'room3') {
		window.open("https://hoeiso.gloomy-store.com/ry/room/index3.php", "a", "width=1200, height=1000");
	}
}






window.addEventListener('load', function () {

	
	/*사이드 메뉴에 마우스오버시 클래스 붙이기*/
	let aside = document.getElementsByTagName('aside');
	for (let i = 0; i < aside.length; i++) {
		let aside_h3 = aside[i].children[0].children[1].children;
		for (let ii = 0; ii < aside_h3.length; ii++) {
			aside_h3[ii].addEventListener('mouseover', function () {
				this.classList.add('active');
			})
			aside_h3[ii].addEventListener('mouseout', function () {
				this.classList.remove('active');
			})
		}
	}

	/*시간이 지나면 로그아웃하기*/
	/*20분 설정*/
	let logoutTimer = 1500;
	setInterval(function () {
		if (logoutTimer > 0) {
			logoutTimer = logoutTimer - 1;
			if (logoutTimer < 2 && window.sessionStorage.getItem('session_id')) {
				onLogout();
			}
		}
	}, 1000); 
	window.onmousemove = function () {
		logoutTimer = 1500;
	}

});


window.addEventListener('resize', function () {
	// mainTopHeight();
	
})

window.addEventListener('scroll', function () {
	fadeInOn()
})
export {nothing,onlyOneRoom,fadeIn,fadeOut}