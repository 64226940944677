import React, { useEffect, useRef } from 'react';
import { popupImg,fadeOut,slide1,slideMove } from '../../assets/js/room';
import { home,reserve,room1,room2,room3,onsen,kaiseki,intro,event,inquire,business1,business2,login,join,confirm,mypage,search, restaurant } from '../../store/module/nav';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link
  } from "react-router-dom";
const Business2 = ({page,setPage,dispatch}) => {
	const cnt4 = useRef();
	const imgRef = useRef([]);
	const roomImg = useRef();
	const roomArr = useRef([]);
	useEffect(() => {
		let delay = false;
		/*갤러리 작은 이미지 클릭시 큰 이미지가 떠오름*/
		for (let ii = 0; ii < cnt4.current.children.length; ii++) {
			let imgClassParent = cnt4.current.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;

			let imgClassNum = cnt4.current.children[ii].className.split('cnt4_')[1];
			cnt4.current.children[ii].addEventListener('click', function () {
				popupImg(imgClassNum, imgClassParent);
			})
		}
		/*큰 이미지를 누르면 사라짐*/
		for (let i = 0; i < imgRef.current.length; i++) {
			imgRef.current[i].addEventListener('click', function () {
				let $this = this;
				fadeOut($this)
				setTimeout(function () {
					$this.style.display = 'none';
				}, 800)
			})
		}
		/*자동 슬라이드 쇼!*/
		setInterval(function () {
			if (delay) return
			else {
				slide1(roomImg.current)
			}
		}, 4500)


		/*화살표 누르면 사진 왼쪽 오른쪽 이동*/
		for (let i = 0; i < roomArr.length; i++) {
			for (let ii = 0; ii < roomArr[i].children.length; ii++) {
				roomArr[i].children[ii].addEventListener('click', function () {
					console.log(roomArr[i].children[ii])
					slideMove(roomArr[i].children[ii])
				})
			}

		}
	
	  return () => {
		
	  };
	}, [page]);

	const OnPageMove = (e) => {
		setPage(e)
	}
	const onActive = (e) => {
		e.target.parentElement.classList.add('active')
	}
	const offActive = (e) => {
		e.target.parentElement.classList.remove('active')
	}

    return (
        <div id="business2" className="roomContent">
		<div className="roomTop nav_eraser">
			<img src={require("../../assets/img/business/facility_top.jpg")} alt="메뉴아래 이미지" />
		</div>
		<div className="content_area">
			<aside className="roomMenu">
				<div>
					<div>
						<h2>부대시설</h2>
					</div>
					<div>
						<h3 onMouseOver={onActive} onMouseLeave={offActive}><Link to="/business1" className="onBusiness depth2" onClick={(e) => {dispatch(business1());OnPageMove(e.target.classList[0])}}>비즈니스 센터</Link></h3>
						<h3 onMouseOver={onActive} onMouseLeave={offActive}><Link to="/business2" className="onBusiness2 depth2" onClick={(e) => {dispatch(business2());OnPageMove(e.target.classList[0])}}>미팅룸</Link></h3>
					</div>
				</div>
			</aside>
			<div>
				<h1><img src={require("../../assets/img/business/title6.png")} alt="제목이미지" className="subject_image" /></h1>
				<div className="standard">
					<p>미팅 룸은 프리젠테이션, 세미나, 기획 세션 등에 최적입니다.<br /> 넓이는 148 평방미터, 최대 수용인원은 45명입니다.<br />무료 고속 인터넷(Wi-Fi), 화이트 보드, 영상 및 음향 시설 등 회의에 필요한 설비 일체를 갖추고 있습니다.</p>
				</div>
				<div className="roomSlide">
					<div className="roomImg" ref={roomImg}>
						<img src={require("../../assets/img/room/stand1.jpg")} alt="스탠다드1" />
						<img src={require("../../assets/img/room/stand2.jpg")} alt="스탠다드2" />
						<img src={require("../../assets/img/room/stand3.jpg")} alt="스탠다드3" />
						<img src={require("../../assets/img/room/stand4.jpg")} alt="스탠다드4" />
						<img src={require("../../assets/img/room/stand5.jpg")} alt="스탠다드5" />
						<img src={require("../../assets/img/room/stand6.jpg")} alt="스탠다드6" />
						<img src={require("../../assets/img/room/stand7.jpg")} alt="스탠다드7" />
						<img src={require("../../assets/img/room/stand8.jpg")} alt="스탠다드8" />
					</div>
					<div className="roomArr">
						<button type="button" className="moveLeft" ref={e => (roomArr.current[0] = e)} onClick={(e) => slideMove(e.target,roomImg.current)}><img src={require("../../assets/img/room/left_btn.png")} alt="왼쪽 화살표" /></button>
						<button type="button" className="moveRight" ref={e => (roomArr.current[1] = e)} onClick={(e) => slideMove(e.target,roomImg.current)}><img src={require("../../assets/img/room/right_btn.png")} alt="오른쪽 화살표" /></button>
					</div>
				</div>
				<div className="roomTxt">
					<div className="roomCnt">
						<div>
							<h2 className="gallery_tlt"><img src={require("../../assets/img/room/title7.png")} alt="갤러리" className="subject_image" /></h2>
							<div className="cnt4" ref={cnt4}>
								<div className="cnt4_1">
									<img src={require("../../assets/img/business/meeting3.jpg")} alt="갤러리이미지" />
								</div>
								<div className="cnt4_2">
									<img src={require("../../assets/img/business/meeting4.jpg")} alt="갤러리이미지" />
								</div>
								<div className="cnt4_3">
									<img src={require("../../assets/img/business/meeting1.jpg")} alt="갤러리이미지" />
								</div>
								<div className="cnt4_4">
									<img src={require("../../assets/img/business/meeting2.jpg")} alt="갤러리이미지" />
								</div>
							</div>
						</div>
						<div>
							<h2>공간 소개</h2>
							<div className="cnt3">
								<p>소규모 미팅, 각종 과외나 스터디룸이 필요할 땐<br />
									로프 [ROPE]의 소회의실을 이용해 보세요!<br />
									회의에 필요한 모든 것이 준비되어 있으며<br />
									시간제로 운영되는 공간입니다.<br /><br />
									카페테리아의 다양한 디저트와 프리미엄 커피는 무료로 제공되며,<br />
									당신의 에너지를 보충하기에 충분합니다.<br />
									41인치의 모니터가 준비되어 있어 노트북과 연결하여 사용할 수 있습니다.</p>
							</div>
						</div>
						<div>
							<h2>공간 소개</h2>
							<div className="cnt3">
								<p>영업시간 : 9~18시<br />휴무일 : 매주 토요일, 매주 일요일, 공휴일<br /><br />
									※예약시 주의사항<br />
									1.노트북이 필요하신 분은 사전에 요청부탁드립니다.<br />
									2.운영시간 외에 예약을 원하시는 분은 전화 상담 부탁드립니다.<br />
									3.이용시 주차는 어려우니 양지바랍니다.
								</p>
							</div>
						</div>
						<div>
							<h2 className="fadeInOn">공간 소개</h2>
							<div className="cnt3">
								<p>1. 코워킹 스페이스의 소회의실로 41"모니터가 있는 소회의실은 HDMI 케이블 이용이 가능합니다.<br />
									2. 최고 사양의 복합기와 최고급 사무용 가구가 구비되어 있습니다<br />
									3. 카페테리아의 다양한 디저트와 프리미엄 커피는 당신의 에너지를 보충하기에 충분하며,무제한 무료로 이용하실 수 있습니다.</p>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
		<div className="popupImg rmPop1" ref={e => (imgRef.current[0] = e)}>
			<img src={require("../../assets/img/business/meeting3.jpg")} alt="갤러리이미지" />
		</div>
		<div className="popupImg rmPop2" ref={e => (imgRef.current[1] = e)}>
			<img src={require("../../assets/img/business/meeting4.jpg")} alt="갤러리이미지" />
		</div>
		<div className="popupImg rmPop3" ref={e => (imgRef.current[2] = e)}>
			<img src={require("../../assets/img/business/meeting1.jpg")} alt="갤러리이미지" />
		</div>
		<div className="popupImg rmPop4" ref={e => (imgRef.current[3] = e)}>
			<img src={require("../../assets/img/business/meeting2.jpg")} alt="갤러리이미지" />
		</div>
	</div>
    );
};

export default Business2;