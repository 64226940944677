import React, { useEffect, useRef, useState } from 'react';
import { onSearch } from "../../assets/php/search_room";
import Calendar from './Calendar';

import "../../assets/php/reserve";
const onlyOneRoom = () =>{
	alert('현재 성수기로, 하나의 방만 예약됩니다')
  }
const Reserve = () => {

  const [todayInner,setTodayInner] = useState(new Date(new Date().getTime() + new Date().getTimezoneOffset() * 60 * 1000 + 9 * 60 * 60 * 1000   ).toString());
  const timer = function () {
    setTodayInner(new Date(new Date().getTime() + new Date().getTimezoneOffset() * 60 * 1000 + 9 * 60 * 60 * 1000   ).toString())
}
  useEffect(()=>{
    setInterval(timer, 1000);
  },[])

  const [openCalendar,setOpenCalendar] = useState(null);
  const onCalendar = () => {
    setOpenCalendar(!openCalendar)
  }

  const [ii,setii] = useState(0);
  const [thisCalendar,setThisCalendar] = useState({});
  const [year,setYear] = useState();
  const [month,setMonth] = useState();
  const [thisMonth,setThisMonth] = useState(1)
  /*달력 오픈되면 모조리 작동됨*/
  useEffect(() => {
      
    const monthNames = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let $day = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const dates = document.getElementById('dates_left')
    const year_left = document.getElementById('year_left')
    const month_left = document.getElementById('month_left')
  
    let calendar_month = [];
    let calendar_day = [];
    let calendar_last_date = [];
  
    let today_origin = new Date();
    /*todayKor = 한국 표준시*/
    let today_hour_origin = today_origin.getTime() + (today_origin.getTimezoneOffset() * 60 * 1000) + (9 * 60 * 60 * 1000);
    let todayKor_origin = new Date(today_hour_origin);
    console.log(todayKor_origin);
    let today_year_origin = Number(todayKor_origin.toString().split(' ')[3]); /*연*/
    let today_month_origin = monthNames.indexOf(todayKor_origin.toString().split(' ')[1]); /*월*/
    let today_date_origin = Number(todayKor_origin.toString().split(' ')[2]); /*일*/
    let today_day_origin = todayKor_origin.toString().split(' ')[0]; /*요일*/
  
  let original_date = today_year_origin+'-'+today_month_origin+'-'+today_date_origin;
  window.sessionStorage.setItem('original_date',original_date)
  window.sessionStorage.setItem('today_date_origin',today_date_origin)
    console.log(today_month_origin)
    setThisMonth(today_month_origin)
    
    /* calendar_month에 월 채워넣기 */
    for (let i =0;i<12;i++){
      if(i===0){
        calendar_month[i] = today_month_origin;
      } else {
        if(calendar_month[i-1] === 12){
          calendar_month[i] = 1
        } else {
          calendar_month[i] = calendar_month[i-1] + 1;
        }
      }
    }
  
    /* calendar_month에 X년 1일 채워넣기 - 예) 2022-4-1 */
    let this_year = today_year_origin;
    for (let i =0;i<12;i++){
      if(i===0){
        calendar_month[i] = this_year+'-'+calendar_month[i]+'-1';
      } else {
        if(calendar_month[i] === 1){
          this_year = this_year + 1;
          calendar_month[i] = this_year+'-'+calendar_month[i]+'-1';
        } else {
          calendar_month[i] = this_year+'-'+calendar_month[i]+'-1';
        }
      }
    }
    console.log(calendar_month)
    setYear(calendar_month[ii].split('-')[0])
    setMonth(calendar_month[ii].split('-')[1])
  
    for (let i =0;i<12;i++){
      calendar_day[i] = new Date(calendar_month[i]).getDay();
    }
    console.log(calendar_day)
  
    for (let i =0;i<12;i++){
      calendar_last_date[i] = Number(new Date(calendar_month[i].split('-')[0],calendar_month[i].split('-')[1],0).toString().split(' ')[2]);
    }
    console.log(calendar_last_date)
  
    let calendars = [];
      for (let i =0;i<12;i++){
        if(i===0){
        calendars[0] = [];
      }else {
        calendars[i] = [];
      }
    }
    console.log(calendars)
    for (let i =0;i<12;i++){
      for(let ii=0;ii<calendar_day[i];ii++){
        calendars[i][ii] = 0;
      }
      for(let ii=calendar_day[i]-1;ii<calendar_day[i] + calendar_last_date[i];ii++){
        calendars[i][ii] = ii - calendar_day[i]+1;
      }
    }
    console.log(calendars)
  
    setThisCalendar(calendars[ii])
    console.log(calendars[ii])
    
    return () => {
      clearInterval(timer)
      setTodayInner(false)
    }
  }, [setThisMonth,ii,openCalendar]);
  
  useEffect(()=> {
    window.sessionStorage.removeItem('startDate')
    window.sessionStorage.removeItem('endDate')
  
  },[])

    return (
    <div id="reserve_wrapper" className="nav_eraser" style={{paddingTop:250}}>
		<div id="today">{todayInner ? todayInner : '.'}</div>
		<div className="calender_wrapper">

		</div>

		<div id="reservation2">
			<img src={require("../../assets/img/reserve/reserlogo.png")} alt="예약제목" className="subject_image" />

      <div className="calender_wrapper">
          {openCalendar ? <Calendar openCalendar={openCalendar} setOpenCalendar={setOpenCalendar} thisCalendar={thisCalendar} setThisCalendar={setThisCalendar} year={year} month={month} ii={ii} setii={setii} /> : null}
        </div>

        <div className="form_wrapper">
          <form id="reservation_2" onSubmit={onSearch} name="reserve2">
            <div className="r_title">
              <h6>호에이소 호텔</h6>
            </div>
            <div className="mgt15">
              <div>
                <input
                  className="search-txt"
                  type="text"
                  name="city"
                  placeholder="도시 또는 호텔명"
                  required
                />
                <a className="search-btn" href="#none"></a>
              </div>
              <div>
                <button type="submit">예 약</button>
              </div>
            </div>
            <div>
              <div>
                <div className="mgtMo15">
                  <h6>체크인</h6>
                  <button
                    type="button"
                    id="startDate"
                    className="startDate"
                    onClick={onCalendar}
                  >
                    <span>날짜</span>
                    <br />
                    <span>선택</span>
                    <input
                      type="text"
                      className="input_hide"
                      name="start_date_input"
                      id="start_date_input"
                      defaultValue=""
                      required
                    />
                  </button>
                </div>
                <div className="mgtMo15">
                  <h6>체크아웃</h6>
                  <button
                    type="button"
                    id="endDate"
                    className="endDate"
                    onClick={onCalendar}
                  >
                    <span>날짜</span>
                    <br />
                    <span>선택</span>
                    <input
                      type="text"
                      className="input_hide"
                      name="end_date_input"
                      id="end_date_input"
                      defaultValue=""
                      required
                    />
                  </button>
                </div>
                <div className="mgtMo15">
                  <h6>
                    <label htmlFor="num_rooms">객실</label>
                  </h6>
                  <input
                    type="text"
                    name="num_rooms"
                    id="num_rooms"
                    placeholder="1"
                    defaultValue="1"
                    readOnly
                    onClick={onlyOneRoom}
                    style={{
                      backgroundColor: "rgba(239,239,239,0.8)",
                      borderRadius: 5,
                    }}
                    required
                  />
                </div>
                <div className="mgtMo15">
                  <h6>
                    <label htmlFor="num_adult">성인</label>
                  </h6>
                  <input
                    type="text"
                    id="num_adult"
                    name="num_adult"
                    placeholder="1"
                    defaultValue="1"
                    required
                  />
                </div>
                <div className="mgtMo15">
                  <h6>
                    <label htmlFor="num_kids">어린이</label>
                  </h6>
                  <input
                    type="text"
                    id="num_kids"
                    name="num_kids"
                    placeholder="0"
                    defaultValue="0"
                    required
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>













			<div id="ment">
				원하시는 호텔, 날짜, 인원을 선택해주세요.
			</div>
		</div>

    );
};

export default Reserve;