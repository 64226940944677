//액션생성
//const PINK = '리듀서파일명/액션명'
const HOME = 'nav/HOME'
const RESERVE = 'nav/RESERVE'
const ROOM1 = 'nav/ROOM1'
const ROOM2 = 'nav/ROOM2'
const ROOM3 = 'nav/ROOM3'
const RESTAURANT = 'nav/RESTAURANT'
const KAISEKI = 'nav/KAISEKI'
const ONSEN = 'nav/ONSEN'
const INTRO = 'nav/INTRO'
const EVENT = 'nav/EVENT'
const INQUIRE = 'nav/INQUIRE'
const BUSINESS1 = 'nav/BUSINESS1'
const BUSINESS2 = 'nav/BUSINESS2'
const LOGIN = 'nav/LOGIN'
const LOGIN2 = 'nav/LOGIN2'
const JOIN = 'nav/JOIN'
const CONFIRM = 'nav/CONFIRM'
const MYPAGE = 'nav/MYPAGE'
const SEARCH = 'nav/SEARCH'

//액션내보내기
//dispatch({type: AAA}) -> dispatch( aaa() )
export const home = () => ({type: HOME})
export const reserve = () => ({type: RESERVE})
export const room1 = () => ({type: ROOM1})
export const room2 = () => ({type: ROOM2})
export const room3 = () => ({type: ROOM3})
export const restaurant = () => ({type: RESTAURANT})
export const kaiseki = () => ({type: KAISEKI})
export const onsen = () => ({type: ONSEN})
export const intro = () => ({type: INTRO})
export const event = () => ({type: EVENT})
export const inquire = () => ({type: INQUIRE})
export const business1 = () => ({type: BUSINESS1})
export const business2 = () => ({type: BUSINESS2})
export const login = () => ({type: LOGIN})
export const login2 = () => ({type: LOGIN2})
export const join = () => ({type: JOIN})
export const confirm = () => ({type: CONFIRM})
export const mypage = () => ({type: MYPAGE})
export const search = () => ({type: SEARCH})

// 리듀서 만들기
const initialState = {link: 'onHome'}

// 리듀서 
const reducer = (state = initialState, action) => {
switch(action.type) {
    case HOME:
    return {
        link: 'onHome'
    }
    case RESERVE:
    return {
        link: 'onReserve'
    }
    case ROOM1:
    return {
        link: 'onRoom1'
    }
    case ROOM2:
    return {
        link: 'onRoom2'
    }
    case ROOM3:
    return {
        link: 'onRoom3'
    }
    case RESTAURANT:
    return {
        link: 'onRestaurant'
    }
    case KAISEKI:
    return {
        link: 'onKaiseki'
    }
    case ONSEN:
    return {
        link: 'onSpa'
    }
    case INTRO:
    return {
        link: 'onIntro'
    }
    case EVENT:
    return {
        link: 'onEvent'
    }
    case INQUIRE:
    return {
        link: 'onInquire'
    }
    case BUSINESS1:
    return {
        link: 'onBusiness1'
    }
    case BUSINESS2:
    return {
        link: 'onBusiness2'
    }
    case LOGIN:
    return {
        link: 'onLogin'
    }
    case LOGIN2:
    return {
        link: 'onLogin2'
    }
    case JOIN:
    return {
        link: 'onJoin'
    }
    case CONFIRM:
    return {
        link: 'onConfirm'
    }
    case SEARCH:
    return {
        link: 'onSearch'
    }
    case MYPAGE:
    return {
        link: 'onMypage'
    }
    default:
    return state
}
}

export default reducer