import React, { useEffect, useRef, useState } from 'react';
import {dupMail,dupMailVal,dupId,dupIdVal,onJoin,telOnly,telEngOnly,fileName,fileName2} from '../../assets/php/join';
import { home,reserve,room1,room2,room3,onsen,kaiseki,intro,event,inquire,business1,business2,login,join,confirm,mypage,search, restaurant,login2 } from '../../store/module/nav';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link
  } from "react-router-dom";
import DaumPostcode from 'react-daum-postcode';
import PopupDom from './PopupDom';
import PopupPostCode from './PopupPostCode';



const Join = ({setPage,dispatch}) => {
	const [isPopupOpen, setIsPopupOpen] = useState(false)
	const [zipcode, setZipcode] = useState('')
	const [add1, setAdd1] = useState('')
	const [add2, setAdd2] = useState('')
	const zipref = useRef()
	const addr1ref = useRef()
	const addr2ref = useRef()

	useEffect(() => {
		zipref.current.value = zipcode;
		addr1ref.current.value = add1;
		addr2ref.current.value = add2;
	
	  return () => {
		return
	  };
	}, [zipcode,add1,add2]);
	


	// 팝업창 열기
    const openPostCode = () => {
        setIsPopupOpen(true)
    }
 
	// 팝업창 닫기
    const closePostCode = () => {
        setIsPopupOpen(false)
    }
	
	const OnPageMove = (e) => {
		setPage(e)
	}
	const onActive = (e) => {
		e.target.parentElement.classList.add('active')
	}
	const offActive = (e) => {
		e.target.parentElement.classList.remove('active')
	}

	return (
		<div id="join" className="roomContent nav_eraser">

			<div id='popupDom'>
                {isPopupOpen && (
                    <PopupDom>
                        <PopupPostCode setZipcode={setZipcode} setAdd1={setAdd1} setAdd2={setAdd2} onClose={closePostCode} />
                    </PopupDom>
                )}
            </div>

		<header className="header2 nav_eraser">
			<div>
			</div>
		</header>
		<div className="content_area">
			<aside className="roomMenu">
				<div>
					<div>
						<h2>고객서비스</h2>
					</div>
					<div>
						<h3 onMouseOver={onActive} onMouseLeave={offActive}><Link to="/login" className="onLogin depth2" onClick={(e) => {dispatch(login());OnPageMove(e.target.classList[0])}}>로그인</Link></h3>
						<h3 onMouseOver={onActive} onMouseLeave={offActive}><Link to="/join" className="onJoin depth2" onClick={(e) => {dispatch(join());OnPageMove(e.target.classList[0])}}>회원가입</Link></h3>
						<h3 onMouseOver={onActive} onMouseLeave={offActive}><Link to="/login:login2" className="onLogin2 depth2" onClick={(e) => {dispatch(login2());OnPageMove(e.target.classList[0])}}>예약확인</Link></h3>
					</div>
				</div>
			</aside>
			<div>
				<h1 className="title1">회원 가입</h1>

				<div className="height40"></div>
				<div className="sec1text title">
					<h2 className="title2" style={{fontFamily:'바탕',fontWeight:100}}>호에이소에 오신 것을 환영합니다.</h2>
					<p>회원가입을 하고 다양한 혜택을 누려보세요.</p>
				</div>
				<form className="join_form" name="join_form" id="join_form" method="post" onSubmit={onJoin} encType="multipart/form-data">
					<div className="title_sub">
						<h3>기본 정보</h3>
						<div className="form_wrap">
							<div className="tr">
								<div className="form_wrap_title">
									<h4>* 성명(한글)</h4>
								</div>
								<div className="form_wrap_input">
									<h5>First name(이름)</h5>
									<input type="text" className="input2" maxLength="20" name="young_mem_name_local_first" id="young_mem_name_local_first" required />
									<br className="onlySP" />
									<h5>Last name(성)</h5>
									<input type="text" className="input2" maxLength="20" name="young_mem_name_local_last" id="young_mem_name_local_last" required />
								</div>
							</div>
							<div className="tr">
								<div className="form_wrap_title">
									<h4>* 성명(영문)</h4>
								</div>
								<div className="form_wrap_input">
									<h5>First name(이름)</h5>
									<input type="text" className="input2" maxLength="20" name="young_mem_name_en_first" id="young_mem_name_en_first" onKeyUp={telEngOnly} required />
									<br className="onlySP" />
									<h5>Last name(성)</h5>
									<input type="text" className="input2" maxLength="20" name="young_mem_name_en_last" id="young_mem_name_en_last" onKeyUp={telEngOnly} required />
								</div>
							</div>
							<div className="tr">
								<div className="form_wrap_title">
									<h4>* 생년월일</h4>
								</div>
								<div className="form_wrap_input">
									<input type="text" onKeyUp={telOnly} className="input2" maxLength="8" name="young_mem_birth" id="young_mem_birth" placeholder="8자리를 입력해주세요" required />
								</div>
							</div>
							<div className="tr">
								<div className="form_wrap_title">
									<h4>* 이메일</h4>
								</div>
								<div className="form_wrap_input">
									<input type="email" className="input3" name="young_mem_email" id="young_mem_email" onKeyUp={dupMailVal} required />
									<button type="button" onClick={dupMail}>중복 확인</button>
									<input type="text" required className="duplicate_check" id="duplicate_check" />
								</div>
							</div>
							<div className="tr">
								<div className="form_wrap_title">
									<h4>* 휴대전화</h4>
								</div>
								<div className="form_wrap_input">
									<select name="young_mem_phone_1" id="young_mem_phone_1">
										<option value="010" onChange={(e) => void(e)}>010</option>
										<option value="011" onChange={(e) => void(e)}>011</option>
										<option value="017" onChange={(e) => void(e)}>017</option>
									</select>
									<p>-</p>
									<input type="tel" className="input1" maxLength="4" onKeyUp={telOnly} name="young_mem_phone_2" id="young_mem_phone_2" required />
									<p>-</p>
									<input type="tel" className="input1" maxLength="4" onKeyUp={telOnly} name="young_mem_phone_3" id="young_mem_phone_3" required />
								</div>
							</div>
							<div className="tr">
								<div className="form_wrap_title">
									<h4>자택전화</h4>
								</div>
								<div className="form_wrap_input">
									<input type="tel" className="input1" maxLength="4" onKeyUp={telOnly} name="young_mem_tel_1" id="young_mem_tel_1" />
									<p>-</p>
									<input type="tel" className="input1" maxLength="4" onKeyUp={telOnly} name="young_mem_tel_2" id="young_mem_tel_2" />
									<p>-</p>
									<input type="tel" className="input1" maxLength="4" onKeyUp={telOnly} name="young_mem_tel_3" id="young_mem_tel_3" />
								</div>
							</div>
							<div className="tr address_wrap">
								<div className="form_wrap_title">
									<h4>자택주소</h4>
								</div>
								<div className="form_wrap_input">
									<input type="text" className="input2" readOnly name="young_mem_addr_1" id="young_mem_addr_1" required placeholder="클릭하여 검색하세요" ref={zipref} />
									<button type="button" onClick={openPostCode}>주소 찾기</button>
									<br />
									<input type="text" className="input6" readOnly name="young_mem_addr_2" id="young_mem_addr_2" required placeholder="클릭하여 검색하세요" ref={addr1ref} />
									<br />
									<input type="text" className="input6" maxLength="90" name="young_mem_addr_3" id="young_mem_addr_3" placeholder="나머지 주소를 입력하세요" ref={addr2ref} />
								</div>
							</div>
							<div className="tr">
								<div className="form_wrap_title">
									<h4>* 국적</h4>
								</div>
								<div className="form_wrap_input">
									<input type="text" className="input2" name="young_mem_nation" id="young_mem_nation" placeholder="국적을 입력하세요" required />
								</div>
							</div>
							<div className="tr">
								<div className="form_wrap_title">
									<h4>* 프로필 사진</h4>
								</div>
								<div className="form_wrap_input">
									<input type="file" className="file" name="young_mem_profile" id="young_mem_profile" onChange={fileName} accept="image/*" encType="multipart/form-data" />
									<label htmlFor="young_mem_profile" className="label_file" id="label_file">파일 첨부<span></span></label>
								</div>
							</div>
						</div>
					</div>
					<div className="title_sub">
						<h3>아이디, 비밀번호</h3>
						<div className="form_wrap">
							<div className="tr">
								<div className="form_wrap_title">
									<h4>* 아이디</h4>
								</div>
								<div className="form_wrap_input">
									<input type="text" className="input2" maxLength="15" name="young_mem_id" id="young_mem_id" onKeyUp={dupIdVal} required />
									<button type="button" onClick={dupId}>중복 확인</button>
									<input type="text" required className="duplicate_check" id="duplicate_check2" />
								</div>
							</div>
							<div className="tr">
								<div className="form_wrap_title">
									<h4>* 비밀번호</h4>
								</div>
								<div className="form_wrap_input">
									<input type="password" maxLength="20" className="input2" name="young_mem_pass" id="young_mem_pass" required />
								</div>
							</div>
							<div className="tr">
								<div className="form_wrap_title">
									<h4>* 비밀번호
										<br />&nbsp;&nbsp;&nbsp;확인
									</h4>
								</div>
								<div className="form_wrap_input">
									<input type="password" maxLength="20" className="input2" name="young_mem_pass_r" id="young_mem_pass_r" />
								</div>
							</div>
						</div>
					</div>
					<div id="btn_submit"> <button type="submit"><img src={require("../../assets/img/join.png")} /></button></div>
				</form>
			</div>
		</div>
	</div>
	);
};

export default Join;