import axios from 'axios';
import React, { useEffect } from 'react';
import {completeEditInfo,editDupMailVal,editDupMail } from '../../assets/php/edit_info'
import { fileName2,telEngOnly,telOnly} from '../../assets/php/join';
import { onLogout } from '../../assets/php/login';
import { home,reserve,room1,room2,room3,onsen,kaiseki,intro,event,inquire,business1,business2,login,join,confirm,mypage,search, restaurant,login2 } from '../../store/module/nav';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link
  } from "react-router-dom";
const Mypage = ({setPage,dispatch}) => {

	useEffect(()=>{
		mypageLoad();
		async function mypageLoad() {
			await axios.post(process.env.REACT_APP_URL + '/assets/php/get_member.php').then((res) => {
			if (typeof (res.data) === 'string' && res.data.split('|||')[0] === 'code1') {
				alert('로그인 해주세요');
				onLogout();
				} else {
					console.log(res);
					document.edit_form.onEditInfo_name_local_first.value = res.data.con_young_mem_name_local_first;
					document.edit_form.onEditInfo_name_local_last.value = res.data.con_young_mem_name_local_last;
					document.edit_form.onEditInfo_name_en_first.value = res.data.con_young_mem_name_en_first;
					document.edit_form.onEditInfo_name_en_last.value = res.data.con_young_mem_name_en_last;
					document.edit_form.onEditInfo_email.value = res.data.con_young_mem_email;
					document.edit_form.onEditInfo_phone.value = res.data.con_young_mem_phone;
					document.edit_form.onEditInfo_birth.value = res.data.con_young_mem_birth;
					document.edit_form.onEditInfo_nation.value = res.data.con_young_mem_nation;
					document.edit_form.onEditInfo_id.value = res.data.con_young_mem_id;
					document.edit_form.onEditInfo_tel.value = res.data.con_young_mem_tel;
					document.edit_form.onEditInfo_addr.value = res.data.con_young_mem_addr;
		
		
					document.edit_form.onEditInfo_name_local_first.setAttribute('placeholder', res.data.con_young_mem_name_local_first);
					document.edit_form.onEditInfo_name_local_last.setAttribute('placeholder', res.data.con_young_mem_name_local_last);
					document.edit_form.onEditInfo_name_en_first.setAttribute('placeholder', res.data.con_young_mem_name_en_first);
					document.edit_form.onEditInfo_name_en_last.setAttribute('placeholder', res.data.con_young_mem_name_en_last);
					document.edit_form.onEditInfo_email.setAttribute('placeholder', res.data.con_young_mem_email);
					document.edit_form.onEditInfo_phone.setAttribute('placeholder', res.data.con_young_mem_phone);
					document.edit_form.onEditInfo_birth.setAttribute('placeholder', res.data.con_young_mem_birth);
					document.edit_form.onEditInfo_nation.setAttribute('placeholder', res.data.con_young_mem_nation);
					document.edit_form.onEditInfo_id.setAttribute('placeholder', res.data.con_young_mem_id);
					document.edit_form.onEditInfo_tel.setAttribute('placeholder', res.data.con_young_mem_tel);
					document.edit_form.onEditInfo_addr.setAttribute('placeholder', res.data.con_young_mem_addr);
		
		
					//			document.edit_form.onEditInfo_nation.value = res.data.con_young_mem_idx;
		
				}
			}).catch(error => {
				alert('에러발생, 관리자에게 문의해주세요');
				console.log(error);
				throw new Error(error);
			})
		}
	},[])


	const OnPageMove = (e) => {
		setPage(e)
	}
	const onActive = (e) => {
		e.target.parentElement.classList.add('active')
	}
	const offActive = (e) => {
		e.target.parentElement.classList.remove('active')
	}


    return (
        <div id="edit_info" className="roomContent nav_eraser"> 
		<header className="header2 nav_eraser">
			<div>
			</div>
		</header>
		<div className="content_area">
			<aside className="roomMenu">
				<div>
					<div>
						<h2>고객서비스</h2>
					</div>
					<div>
						<h3 onMouseOver={onActive} onMouseLeave={offActive}><Link to="/login" className="onLogin depth2" onClick={(e) => {dispatch(login());OnPageMove(e.target.classList[0])}}>로그인</Link></h3>
						<h3 onMouseOver={onActive} onMouseLeave={offActive}><Link to="/join" className="onJoin depth2" onClick={(e) => {dispatch(join());OnPageMove(e.target.classList[0])}}>회원가입</Link></h3>
						<h3 onMouseOver={onActive} onMouseLeave={offActive}><Link to="/login:login2" className="onLogin2 depth2" onClick={(e) => {dispatch(login2());OnPageMove(e.target.classList[0])}}>예약확인</Link></h3>
					</div>
				</div>
			</aside>
			<div>
				<h1 className="title1">내 정보 수정</h1>

				<div className="height40"></div>
				<div className="sec1text title">
					<h2 className="title2" style={{fontFamily:'바탕',fontWeight:100}}>호에이소에 오신 것을 환영합니다.</h2>
					<p>내 정보를 최신으로 유지해주세요.</p>
				</div>
				<form className="join_form" name="edit_form" id="edit_form" method="post" onSubmit={completeEditInfo}>
					<div className="title_sub">
						<h3>기본 정보</h3>
						<div className="form_wrap">
							<div className="tr">
								<div className="form_wrap_title">
									<h4>* 성명(한글)</h4>
								</div>
								<div className="form_wrap_input">
									<h5>First name(이름)</h5>
									<input type="text" className="input2" maxLength="20" name="onEditInfo_name_local_first" id="onEditInfo_name_local_first" required />
									<br className="onlySP" />
									<h5>Last name(성)</h5>
									<input type="text" className="input2" maxLength="20" name="onEditInfo_name_local_last" id="onEditInfo_name_local_last" required />
								</div>
							</div>
							<div className="tr">
								<div className="form_wrap_title">
									<h4>* 성명(영문)</h4>
								</div>
								<div className="form_wrap_input">
									<h5>First name(이름)</h5>
									<input type="text" className="input2" maxLength="20" name="onEditInfo_name_en_first" id="onEditInfo_name_en_first" onKeyUp={telEngOnly} required />
									<br className="onlySP" />
									<h5>Last name(성)</h5>
									<input type="text" className="input2" maxLength="20" name="onEditInfo_name_en_last" id="onEditInfo_name_en_last" onKeyUp={telEngOnly} required />
								</div>
							</div>
							<div className="tr">
								<div className="form_wrap_title">
									<h4>* 생년월일</h4>
								</div>
								<div className="form_wrap_input">
									<input type="text" onKeyUp={telOnly} className="input2" maxLength="8" name="onEditInfo_birth" id="onEditInfo_birth" placeholder="8자리를 입력해주세요" required />
								</div>
							</div>
							<div className="tr">
								<div className="form_wrap_title">
									<h4>* 이메일</h4>
								</div>
								<div className="form_wrap_input">
									<input type="email" className="input3" name="onEditInfo_email" id="onEditInfo_email" onKeyUp={editDupMailVal} required />
									<button type="button" onClick={editDupMail}>중복 확인</button>
									<input type="text" required className="duplicate_check" id="duplicate_check3" defaultValue={null} />
								</div>
							</div>
							<div className="tr">
								<div className="form_wrap_title">
									<h4>* 휴대전화</h4>
								</div>
								<div className="form_wrap_input">
									<input type="tel" className="input2" maxLength="15" onKeyUp={telOnly} name="onEditInfo_phone" id="onEditInfo_phone" required />
								</div>
							</div>
							<div className="tr">
								<div className="form_wrap_title">
									<h4>자택전화</h4>
								</div>
								<div className="form_wrap_input">
									<input type="tel" className="input2" maxLength="20" onKeyUp={telOnly} name="onEditInfo_tel" id="onEditInfo_tel" />
								</div>
							</div>
							<div className="tr address_wrap">
								<div className="form_wrap_title">
									<h4>자택주소</h4>
								</div>
								<div className="form_wrap_input">
									<input type="text" className="input6" maxLength="90" name="onEditInfo_addr" id="onEditInfo_addr" placeholder="나머지 주소를 입력하세요" />
								</div>
							</div>
							<div className="tr">
								<div className="form_wrap_title">
									<h4>* 국적</h4>
								</div>
								<div className="form_wrap_input">
									<input type="text" className="input2" name="onEditInfo_nation" id="onEditInfo_nation" placeholder="국적을 입력하세요" required />
								</div>
							</div>
							<div className="tr">
								<div className="form_wrap_title">
									<h4>* 프로필 사진</h4>
								</div>
								<div className="form_wrap_input">
									<input type="file" className="file" name="onEditInfo_profile" id="onEditInfo_profile" onChange={fileName2} />
									<label htmlFor="onEditInfo_profile" className="label_file" id="onEditInfo_file">파일 첨부<span></span></label>
								</div>
							</div>
						</div>
					</div>
					<div className="title_sub">
						<h3>아이디, 비밀번호</h3>
						<div className="form_wrap">
							<div className="tr">
								<div className="form_wrap_title">
									<h4>* 아이디</h4>
								</div>
								<div className="form_wrap_input">
									<input type="text" className="input2" maxLength="15" name="onEditInfo_id" id="onEditInfo_id" disabled />
								</div>
							</div>
							<div className="tr">
								<div className="form_wrap_title">
									<h4>* 원래 비밀번호</h4>
								</div>
								<div className="form_wrap_input">
									<input type="password" maxLength="20" className="input2" name="onEditInfo_pass_origin" id="onEditInfo_pass_origin" required />
								</div>
							</div>
							<div className="tr">
								<div className="form_wrap_title">
									<h4>* 새 비밀번호</h4>
								</div>
								<div className="form_wrap_input">
									<input type="password" maxLength="20" className="input2" name="onEditInfo_pass" id="onEditInfo_pass" />
								</div>
							</div>
							<div className="tr">
								<div className="form_wrap_title">
									<h4>* 새 비밀번호
										<br />&nbsp;&nbsp;&nbsp;확인
									</h4>
								</div>
								<div className="form_wrap_input">
									<input type="password" maxLength="20" className="input2" name="onEditInfo_pass_r" id="onEditInfo_pass_r" />
								</div>
							</div>
						</div>
					</div>
					<div id="btn_submit"> <button type="submit"><img src={require('../../assets/img/edit.png')} /></button></div>
				</form>
			</div>
		</div>
	</div>
    );
};

export default Mypage;