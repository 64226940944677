import axios from 'axios';
export const onFindId = (e) => {
	e.preventDefault();
	let form_birth = document.find_form.form_birth.value;
	let form_mail = document.find_form.form_mail.value;
	let form_name = document.find_form.form_name.value;
	
	let formData = new FormData()
	formData.append('form_birth',form_birth)
	formData.append('form_mail',form_mail)
	formData.append('form_name',form_name)
	axios.post(process.env.REACT_APP_URL + "/assets/php/findid.php", formData, {
		headers: {
		  "Content-Type": "multipart/form-data",
		},
	  })
	  
	  .then((res) => {
		// console.log(res);
		if (res.data.split('|||')[0] === 'code1') {
			alert('정보를 찾을 수 없습니다.');
		} else if (res.data.split('|||')[0] === 'code0') {
			alert('아이디를 찾았습니다.\n'+res.data.split('|||')[1]);
            console.log(res.data)

		} else {
			return alert(res.data)
		}
	}).catch(error => {
		alert('에러발생, 관리자에게 문의해주세요');
		console.log(error);
		throw new Error(error);
	});
}


export const onFindPw = (e) => {
	e.preventDefault();
	let form_id = document.find_form2.form_id.value;
	let form_mail2 = document.find_form2.form_mail2.value;
	let form_birth2 = document.find_form2.form_birth2.value;
	
	let formData2 = new FormData()
	formData2.append('form_id',form_id)
	formData2.append('form_mail2',form_mail2)
	formData2.append('form_birth2',form_birth2)
	axios.post(process.env.REACT_APP_URL + "/assets/php/findpw.php", formData2, {
		headers: {
		  "Content-Type": "multipart/form-data",
		},
	  })
	  
	  .then((res) => {
		// console.log(res);
		if (res.data.split('|||')[0] === 'code1') {
			alert('해당 정보를 찾을 수 없습니다.');
		} else if (res.data.split('|||')[0] === 'code0') {
			window.sessionStorage.setItem('newPassIdx',res.data.split('|||')[1])
            alert('비밀번호를 변경해주세요');
            window.location.href = '/changepw';
		} else {
			return alert(res.data)
		}
	}).catch(error => {
		alert('에러발생, 관리자에게 문의해주세요');
		console.log(error);
		throw new Error(error);
	});
}



export const onChangePw = (e) => {
	e.preventDefault();
	let pass1 = document.change_form.pass1.value;
	let pass2 = document.change_form.pass2.value;
	let newPassIdx = window.sessionStorage.getItem('newPassIdx')

    if(pass1 !== pass2){
        return alert('비밀번호와 비밀번호 확인이 일치하지 않습니다');
    }

	let formData = new FormData()
	formData.append('pass1',pass1)
	formData.append('newPassIdx',newPassIdx)
	axios.post(process.env.REACT_APP_URL + "/assets/php/changepw.php", formData, {
		headers: {
		  "Content-Type": "multipart/form-data",
		},
	  })
	  
	  .then((res) => {
		// console.log(res);
		if (res.data.split('|||')[0] === 'code1') {
			alert('에러발생, 관리자에게 문의해주세요');
		} else if (res.data.split('|||')[0] === 'code0') {
			window.sessionStorage.removeItem('newPassIdx')
            alert('비밀번호가 변경되었습니다.');
            window.location.href = '/';
		} else {
			return alert('에러발생, 관리자에게 문의해주세요');
		}
	}).catch(error => {
		alert('에러발생, 관리자에게 문의해주세요');
		console.log(error);
		throw new Error(error);
	});
}


